import React, { useCallback, useEffect, useMemo, useState } from "react";
import { op } from "@chromia/ft4";

import { getChrPools, getGtxClient, updateChromiaBalances } from "../../utils/Chromia/chromiaHelpers";
import { useStoreDispatch, useStoreSelector } from "../../store/store";
import { iChrPool } from "../../interfaces/chromiaInterfaces";
import { sqrtPriceToPrice, tickToPrice } from "../../utils/Chromia/v3";
import useTokenBalance from "../../hooks/useTokenBalance";
import { logError, toFixed } from "../../utils/helpers";
import { useTabIndex } from "../../hooks/useTabIndex";
import { BigNumber } from "../../entities/BigNumber";
import { Token } from "../../entities/Token";

import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";
import ConnectionBlock from "../ConnectionBlock/ConnectionBlock";
import StarBackground from "../StarBackground/StarBackground";
import TokenSelector from "../TokenSelector/TokenSelector";
import WalletAddress from "../WalletAddress/WalletAddress";
import SettingsIcon from "../UI/SettingsIcon/SettingsIcon";
import arrowsIcon from "../../assets/images/Arrows.svg";
import PixelBorder from "../UI/PixelBorder/PixelBorder";
import MenuPages from "../MenuPages/MenuPages";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";

import './SwapPage.scss'


const SwapPage: React.FC = () => {
	const { clientAddr, chrPools, session, slippagePercent, chrClientAddr } = useStoreSelector(store => store.app);
	const { openNewModal, setTransactionStatus } = useStoreDispatch();

	const [exactField, setExactField] = useState<'sell' | 'buy'>('sell');
	const [isFetchingPrice, setIsFetchingPrice] = useState<boolean>(false);
	const [sellAmount, setSellAmount] = useState<string>('');
	const [buyAmount, setBuyAmount] = useState<string>('');
	const [sellToken, setSellToken] = useState<Token>();
	const [buyToken, setBuyToken] = useState<Token>();
	const [price, setPrice] = useState<string>();	
	const [currentPool, setCurrentPool] = useState<iChrPool>();
	const [candidatePools, setCandidatePools] = useState<iChrPool[] | null>(null);
	const sellTokenBalance = useTokenBalance(sellToken);
	const buyTokenBalance = useTokenBalance(buyToken);
	const [insufficientLiqError, setInsufficientLiqError] = useState<boolean>(false);
	const [newTick, setNewTick] = useState<number>();
	const [isReplaceTkns, setIsReplaceTkns] = useState<boolean>();

	const tabIndex = useTabIndex({position: 'first'});

	const minimumReceived = useMemo(() => {
		return new BigNumber(buyAmount).mul(new BigNumber(1).sub(new BigNumber(+slippagePercent / 100)))
	}, [slippagePercent, buyAmount])

	const priceImpact = useMemo(() => {
		if ((+sellAmount === 0 && +buyAmount ===0) || 
			!currentPool || 
			!price
		) {
			return null
		}

		try {
			const currentPrice = sqrtPriceToPrice(currentPool?.sqrt_price);
			const newPrice = newTick ? tickToPrice(+newTick, false).toFixed() : '0';
			let pricet1 = new BigNumber(newPrice);
			
			const tickDiff = newTick ? Math.abs(+newTick - currentPool.tick) : 0
			const diff = Math.abs(+currentPrice - +pricet1.valueStr);
			const priceImpact = diff*100/+currentPrice
	
			return { price: priceImpact, tickDiff }
		}
		catch(err: any) {
			throw new Error(err)
		}
	}, 
	[price, buyAmount, currentPool, sellAmount, newTick]
	)

	const inputError: string | null = useMemo(() => {

		if (!sellToken || !buyToken) {
			return 'Select tokens'
		}

		if (
			sellTokenBalance === undefined ||
			buyTokenBalance === undefined 
		) {
			return 'Loading'
		}
		
		if (insufficientLiqError) {
			return 'Insufficient pool liquidity'
		}

		if (
			sellTokenBalance === undefined ||
			buyTokenBalance === undefined ||
			isFetchingPrice
		) {
			return 'Searching for the best price...'
		}
			
		if (!candidatePools) {
			return `Pool doesn't exist`
		}

		const sellAmountBN = new BigNumber(sellAmount);

		if (
			Number.isNaN(+sellAmount) ||
			!Number.isFinite(+sellAmount) ||
			+sellAmount <= 0 ||
			Number.isNaN(+buyAmount) ||
			!Number.isFinite(+buyAmount) ||
			+buyAmount <= 0
		){
			return 'Type amount'
		}

		if (sellAmountBN.valueBN18.gt(sellTokenBalance.valueBN18)){
			return `Insufficient ${sellToken.name} balance`
		}

		if (priceImpact && +priceImpact?.tickDiff > 100) {
			return `Price impact is too high`
		}

		return null
	}, 
	[buyAmount, buyToken, buyTokenBalance, sellAmount, sellToken, sellTokenBalance, insufficientLiqError, isFetchingPrice, candidatePools, priceImpact]
	)

	useEffect(() => {
		const tickInterval = setInterval(() => {
			getChrPools();
		}, 60000)
	
		return () => {
			clearInterval(tickInterval);
		}
	}, [])

	useEffect(() => {
		if (!sellToken && !buyToken) return


		//find pools with selected tokens
		const pools = chrPools.filter(pool => 
			(pool.token0 === sellToken?.address && pool.token1 === buyToken?.address) ||
			(pool.token0 === buyToken?.address && pool.token1 === sellToken?.address)
		);

		if (pools.length > 0) {
			setCandidatePools(pools);
		}
		else {
			setCandidatePools(null)
			setCurrentPool(undefined);
			setPrice(undefined);
			setBuyAmount('');
			setInsufficientLiqError(false);
		}
		//eslint-disable-next-line
	}, [chrPools, sellToken, buyToken])

	useEffect(() => {
		if (candidatePools && sellAmount) {
			if (isReplaceTkns) {
				if (exactField === 'buy') {
					calcSwapV3('sell', buyAmount);
					setSellAmount(buyAmount);
					setExactField('sell');
				}
				else {
					calcSwapV3('buy', sellAmount);
					setBuyAmount(sellAmount);
					setExactField('buy');
				}
				setIsReplaceTkns(false);
			}
			else {
				if (exactField === 'sell') {
					changeSellAmount(sellAmount);
				}
				else {
					changeBuyAmount(buyAmount);
				}
			}
		}
		// else {
		// 	setIsReplaceTkns(false)
		// }
		//eslint-disable-next-line
	}, [candidatePools])

	const useDebounce = 
			(callback: (...args: any[]) => void,
			delay: number) => {
		
		const callbackRef = React.useRef(callback);

		React.useLayoutEffect(() => {
			callbackRef.current = callback;
		});

		let timer: any;

		const naiveDebounce = (
			func: (...args: any[]) => void,
			delayMs: number,
			...args: any[]
			) => {
				clearTimeout(timer);
				timer = setTimeout(() => { 
					func(...args);
					}, delayMs);
		};

		return React.useMemo(() => (...args: any) =>
			naiveDebounce(callbackRef.current, delay, 
			...args), [delay]);
	};
	
	const calcSwapV3 = async (localExactField?: 'sell' | 'buy', inAmount?: string) => {

		if (!inAmount) 
			return
		
		const finalExactField = localExactField || exactField;
		
		if (inAmount.endsWith('.')) {
			inAmount = inAmount.slice(0, -1)
		}

		const finalInAmount = inAmount !== undefined? inAmount : (finalExactField === 'buy'? buyAmount : sellAmount);

		if (+finalInAmount === 0) {
			(finalExactField === 'buy'? setSellAmount : setBuyAmount)('0');
			return;
		}

		
		if (!sellToken || !buyToken || !candidatePools || candidatePools.length === 0) return;
		
		setIsFetchingPrice(true);

		let bestAmt = new BigNumber(0);
		let res;

		if (finalExactField === 'buy') {
			try {
				bestAmt = new BigNumber((10**18).toString());
				const gtxClient = await getGtxClient();

				const swapResults = await Promise.allSettled(candidatePools?.map(async (pool) => {
					res = gtxClient.query("slurpyswap.CalcSwapIn", {
						pool_id: pool.id,
						tok_out: buyToken.address,
						amt_out: finalInAmount
					});


					return res.then((res) => {
						return {...res, pool}
					})
				}))
				let selectedPool: iChrPool | null = null;
				let newTick;


				swapResults.forEach((swapRes) => {
					if (swapRes.status === 'fulfilled' && (new BigNumber(swapRes.value.amt)).valueBN18.lt(bestAmt.valueBN18)) {
						bestAmt = new BigNumber(swapRes.value.amt);
						selectedPool = swapRes.value.pool;
						newTick = swapRes.value.tick_new;
					}
				})

				if (bestAmt && selectedPool) {
					setCurrentPool(selectedPool);
					setSellAmount(toFixed(bestAmt.valueStr, 18));
					setNewTick(newTick);
		
					const tokOut = sellToken.address;

					if (tokOut.toLocaleLowerCase() === (selectedPool as iChrPool).token1.toLocaleLowerCase()) {
						const currPrice = new BigNumber(1).div(bestAmt.div(new BigNumber(inAmount)));
						setPrice(currPrice.valueStr)
					}
					else {
						const currPrice = bestAmt.div(new BigNumber(inAmount));
						setPrice(currPrice.valueStr);

						if (insufficientLiqError) {
							setInsufficientLiqError(false);
						}
					}
				}
				else {
					setInsufficientLiqError(true);
				}
			}
			catch(err: any) {
				setSellAmount('');
				throw new Error(err);
			}
		}
		else {
			try {
				let selectedPool: any = undefined;
				let newTick;

				const swapResults = await Promise.allSettled(candidatePools?.map(async (pool) => {

					bestAmt = new BigNumber(0)
					res = await (await getGtxClient()).query("slurpyswap.CalcSwap", {
						pool_id: pool.id,
						tok_in: sellToken.address,
						amt_in: finalInAmount
					});

					return {...res, pool}
				}))
				
				swapResults.forEach((swapRes) => {
					if (swapRes.status === 'fulfilled' && (new BigNumber(swapRes.value.amt)).valueBN18.gt(bestAmt.valueBN18)) {
						bestAmt = new BigNumber(swapRes.value.amt);
						selectedPool = swapRes.value.pool;
						newTick = swapRes.value.tick_new;
					}
				})
				
				if (bestAmt && selectedPool) {
					setCurrentPool(selectedPool);
					setBuyAmount(toFixed(bestAmt.valueStr, 18));
					setNewTick(newTick);
					
					const tokOut = sellToken.address;

					if (tokOut.toLocaleLowerCase() === selectedPool.token1.toLocaleLowerCase()) {
						const currPrice = bestAmt.div(new BigNumber(inAmount));
						setPrice(currPrice.valueStr)
					}
					else {
						const currPrice = new BigNumber(1).div(bestAmt.div(new BigNumber(inAmount)));
						setPrice(currPrice.valueStr);

						if (insufficientLiqError) {
							setInsufficientLiqError(false);
						}
					}
				}
				else {
					setInsufficientLiqError(true);
				}
			}
			catch(err) {
				console.log(err)
				setSellAmount('');
			}
		}

		setIsFetchingPrice(false);
	}

	const calcSellDebounced = useDebounce((ev) => {
		calcSwapV3('sell', ev)
	}, 200)

	const calcBuyDebounced = useDebounce((ev) => {
		calcSwapV3('buy', ev)
	}, 200)

	async function setBuyBalances(amount: string) {
		if (!+amount) {
			setSellAmount('0');
			return;
		}

		calcBuyDebounced(amount);
		// calcSwapV3('buy', amount)
	};

	async function setSellBalances(amount: string) {
		if (!+amount) {
			setBuyAmount('0');
			return;
		}
		calcSellDebounced(amount);
		// calcSwapV3('sell', amount)
	};

	const swap = useCallback(async () => {
		if (!session || !currentPool || !sellToken || !buyToken || !sellTokenBalance || !buyTokenBalance){
			throw new Error();
		}

		try {
			const tx = session.call(op(
				"slurpyswap.Swap",
				Buffer.from(currentPool.id, 'hex'),
				Buffer.from(sellToken.address, 'hex'),
				sellAmount,
				minimumReceived.valueStr
			))

			setTransactionStatus('progress');
			
			openNewModal({
				name: 'transaction'
			})

			await tx;
			setTransactionStatus('succes');
			await updateChromiaBalances()
		}
		catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
			else {
				setTransactionStatus(null)
			}
		}

		setSellAmount('0');
		setBuyAmount('0');
		
		if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
			const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
			await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
		}

		await getChrPools();
	},[buyAmount, buyToken, buyTokenBalance, currentPool, openNewModal, sellAmount, sellToken, sellTokenBalance, session, minimumReceived])


	const replaceTokens = () => {
		if (!sellToken || !buyToken) return;

		setSellToken(buyToken);
		setBuyToken(sellToken);
		setIsReplaceTkns(true);
		// setSellBalances('0');
		// setBuyAmount('0');
	}

	const changeToken = async (newToken: Token, isSellToken: boolean) => {
		if (isSellToken){
			setSellToken(newToken);
		} else {
			setBuyToken(newToken);
		}
	}

	const changeSellAmount = (amount: string) => {
		setSellBalances(amount);
		setSellAmount(amount);
		setExactField('sell');
	}

	const changeBuyAmount = (amount: string) => {
		setBuyBalances(amount);
		setBuyAmount(amount);
		setExactField('buy');
	}


	return (
		<StarBackground>
			<MenuPages/>
			<div className='SwapPage'>
				<div className="swapBlock">
						<PixelBorder>
							<div className="swapBlockInner">
								<div className="swapBlock_header">
									<div className=" title">
										Swap
									</div>
									<div className="settingsButtons">
										<img 
											src={arrowsIcon} 
											alt="arrows" 
											className="replaceTokIcon" 
											onClick={!isReplaceTkns 
												? () => replaceTokens() 
												: () => {}
											}
										/>
										<div className="settingsIcon_wrapper">
											<SettingsIcon/>
										</div>
									</div>
								</div>
								<WalletAddress/>

								<div className="inputsBlock">
									<div className="input_wrapper">
										<div className="subinput">
											<div className="subtitle">From</div>
											<div className="balance">
												{sellTokenBalance && toFixed(sellTokenBalance?.valueStr, 4)} {sellToken?.name}
											</div>
										</div>
										<BlockPixelBorder>
											<div className='inputArea'> 
												<Input
													className='inputArea_input'
													type='number'
													flatRight
													setValue={changeSellAmount}
													value={sellAmount}
													digitsAfterDot={18}
													placeholder='0.0'
													tabIndex={tabIndex}
													blocked={sellAmount.length > 0 && !candidatePools?.length && sellToken!==undefined && buyToken !== undefined}
													maxButton={true}
													maxValue={sellTokenBalance?.valueStr || '0'}
												/>
												<TokenSelector
													className='inputArea_tokenSelector'
													setCurrency={curr => changeToken(curr, true)}
													token={sellToken}
													blockedTokens={[sellToken, buyToken]}
													tabIndex={tabIndex}
													flatLeft
													/>
											</div>
										</BlockPixelBorder>
									</div>
									<div className="input_wrapper">
										<div className="subinput">
											<div className="subtitle">To</div>
											<div className="balance">
												{buyTokenBalance && toFixed(buyTokenBalance?.valueStr, 4)} {buyToken?.name}
											</div>
										</div>
										<BlockPixelBorder>
											<div className='inputArea'> 
												<Input
													className='inputArea_input'
													type='number'
													flatRight
													setValue={changeBuyAmount}
													value={buyAmount}
													digitsAfterDot={18}
													placeholder='0.0'
													tabIndex={tabIndex}
													blocked={buyAmount.length > 0 && !candidatePools?.length && sellToken!==undefined && buyToken !== undefined}
												/>
												<TokenSelector
													className='inputArea_tokenSelector'
													setCurrency={curr => changeToken(curr, false)}
													token={buyToken}
													blockedTokens={[sellToken, buyToken]}
													tabIndex={tabIndex}
													flatLeft
												/>
											</div>
										</BlockPixelBorder>
									</div>
								</div>

								<div className="swapButton_wrapper">
								{!clientAddr ?
									<ConnectionBlock
										className='swapButton_wrapper_button'
										altText='Connect your wallet'
										tabIndex={tabIndex}
									/>
								:
									<Button
										className='swapButton_wrapper_button'
										action={() => swap()}
										blocked={
											(inputError && inputError.length !==0) || 
											isFetchingPrice 
										}

										tabIndex={tabIndex}
									>
										{inputError ?? 'Swap'}
									</Button>
								}
							</div>

								{price && currentPool && +sellAmount > 0 && 
									<div className="priceBlock">
										<div className="title">Price</div>
										{+price > 0 &&
											<div className="value">1 {sellToken?.name} = {sellToken?.address === currentPool?.token1 ? toFixed(price, 6) : toFixed(new BigNumber(1).div(new BigNumber(price)).valueStr, 6)} {buyToken?.name}</div>
										}
									</div>
								}

								<div className="infoBlock">

									{currentPool && +sellAmount > 0 && 
										<div className="info">
											<div className="title">Minimum received</div>
											<div className="value">{toFixed(minimumReceived.valueStr, 6)} {buyToken?.name}</div>
										</div>
									}

									{currentPool && priceImpact && +priceImpact.price > 0 &&
										<div className="info">
											<div className="title">Price impact</div>
											<div className="value">{toFixed(priceImpact.price)}%</div>
										</div>
									}

									{sellAmount && currentPool &&  +sellAmount > 0 && 
										<div className="info">
											<div className="title">Trading fee</div>
											<div className="value">
												{new BigNumber(currentPool?.trading_fees).mul(new BigNumber(sellAmount)).valueBN18.gte(new BigNumber(0.00001).valueBN18) 
												? toFixed(new BigNumber(currentPool?.trading_fees).mul(new BigNumber(sellAmount)).valueStr, 5)
												: new BigNumber(sellAmount).valueBN18.gt(new BigNumber(0).valueBN18) 
													? '<0.00001'
													: '0'
												} {sellToken?.name}
											</div>
										</div>
									}
								
							</div>

							</div>
						</PixelBorder>
				</div>
			</div>
		</StarBackground>
	)
}

export default SwapPage;