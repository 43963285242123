import React, { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
// import Web3 from "web3";

import { useStoreDispatch, useStoreSelector } from "../../store/store";
import { disconnectWallet } from "../../utils/helpers";

import Button from "../UI/Button/Button";

import './ConnectionBlock.scss';
import { useWeb3Modal } from "@web3modal/wagmi/react";

interface iProps {
	className?: string,
	altText?: string,
	tabIndex?: boolean | number,
}


const ConnectionBlock: React.FC<iProps> = ({className, altText, tabIndex}) => {

	const { address } = useAccount();
	const { error } = useConnect();
	const { disconnect } = useDisconnect();
	const { open } = useWeb3Modal();

	
	const { clientAddr } = useStoreSelector(store => store.app);
	const { setClientAddr } = useStoreDispatch();

	useEffect(() => {
		if (!error) {
			return;
		}
		
		if (error.message === "Connector not found") {
			window.open("https://metamask.io/download", "_blank");
		}
	}, [error]);
	
	useEffect(() => {
		if (address && !clientAddr){
			setClientAddr(address);
		}
		if (!address && clientAddr){
			disconnectWallet();
		}
		// eslint-disable-next-line
	}, [address, clientAddr])

	const [isHovered, setIsHovered] = useState(false);


	useEffect(()=>{
		if (!clientAddr){
			setIsHovered(false);
		}
	}, [clientAddr])


	return (
		<div
			className={'ConnectionBlock ' + (className ?? '')}
		>
			{address ?
				<Button
					action={disconnect}
					className="ConnectionButton"
					tabIndex={tabIndex}
					onMouseOver={()=>setIsHovered(true)}
					onMouseOut={()=>setIsHovered(false)}
					onFocus={()=>setIsHovered(true)}
					onBlur={()=>setIsHovered(false)}
				>
					{isHovered? 'DISCONNECT' : address.slice(0,6) + '...' + address.slice(-4)}
				</Button>
			:
				<Button
					action={() => {
						open();
					}}
					className="ConnectionButton"
					tabIndex={tabIndex}
				>
					{altText? altText : 'CONNECT'}
				</Button>
			}
		</div>
	)
}

export default ConnectionBlock;