import React, { useState, useEffect, useCallback } from 'react';

import { useStoreSelector } from '../../store/store';
import { iPosition, tStatus } from '../../interfaces/interfaces';
import { Token } from '../../entities/Token';
import { iChrPool } from '../../interfaces/chromiaInterfaces';
import { getGtxClient } from '../../utils/Chromia/chromiaHelpers';

import BigNumber from 'bignumber.js';
import Loader from '../UI/Loader/Loader';

import './MyLiqPage.scss';
import PositionCard from '../PositionCard/PositionCard';
import { iFarm } from '../FarmingPage/FarmingPage';

export interface iAccPosition extends iPosition {
	token0: Token,
	token1: Token,
	minPrice: BigNumber,
	maxPrice: BigNumber,
	selectedPool: iChrPool,
	status: tStatus,
	id: string,
	farm?: iFarm,
	reward?: string,
	positionFarm?: iFarm,
	farmTok?: Token 
}

const MyLiqPage: React.FC = () => {
	const {chrClientAddr, chrPools, tokens } = useStoreSelector(store => store.app);

	const [positions, setPositions] = useState<iAccPosition[]>();

	const getPositions = useCallback(async () => {
		if (!chrClientAddr) {
			return 
		}

		let positionsWithPools: any = [];

		const chrPositions = await (await getGtxClient()).query('slurpyswap.GetPositionsByAcc', {acc: chrClientAddr});
		chrPositions.forEach((position) => {
			let token0;
			let token1;

			const selectedPool = chrPools.find(pool => { 
				return pool.id === position.pool.toString('hex')
			});

			if (!selectedPool) return null

			tokens.forEach((token) => {
				if (selectedPool.token0 === token.address) {
					token0 = token
				}
	
				if (selectedPool.token1 === token.address) {
					token1 = token
				}
			})

			const minPrice = new BigNumber(position.sqrt_price_lower).div((new BigNumber(2)).pow(96)).pow(2);
			const maxPrice = new BigNumber(position.sqrt_price_upper).div((new BigNumber(2)).pow(96)).pow(2);

			positionsWithPools.push({...position, token0, token1, minPrice, maxPrice, selectedPool})
		})

		setPositions(positionsWithPools);
	},
	[chrClientAddr, chrPools, tokens]
	)

	useEffect(() => {
		getPositions();
	}, [chrClientAddr, getPositions])

	
	if (!positions) return <div></div>
	return (
		<div
			className={
				'MyLiqPageV2' 
			}
		>	
			<div className='LiquidityPageV2_flex'>
				{!positions &&
					<Loader/>
				}

				{positions?.map((position => {
					return <PositionCard position={position} getPositions={getPositions} isFarming={false}/>
				}))}
			</div>
		</div>
	)
}

export default MyLiqPage;