import React from "react";

import { getSelectedToken } from "../../utils/helpers";
// import { Currency } from "../../entities/Currency";
import { Token } from "../../entities/Token";

import arrowDown from '../../assets/images/arrow_down.png';

import './TokenSelector.scss';

interface iProps {
	// setCurrency: (currency: Currency) => void;
	setCurrency: (currency: Token) => void;
	// token?: Currency,
	token?: Token,
	// blockedTokens?: (Currency | undefined)[],	// temp
	blockedTokens?: (Token | undefined)[],	// temp
	className?: string,
	tabIndex?: boolean | number,
	flatTop?: boolean,
	flatRight?: boolean,
	flatBottom?: boolean,
	flatLeft?: boolean,
}


const TokenSelector: React.FC <iProps> = ({
	setCurrency,
	token,
	blockedTokens,
	className,
	tabIndex,
	flatTop,
	flatRight,
	flatBottom,
	flatLeft,
}) => {

	const getToken = async () => {
		try {
			let tokens: Token[] = [];
			blockedTokens?.forEach(token => token && tokens.push(token));
			const newToken = await getSelectedToken(tokens) as Token;
			setCurrency && setCurrency(newToken);
		} catch {
			
		}
	}


	return (
		<div
			className={
				'_TokenSelector' +
				(flatTop? ' _TokenSelector_flatTop ' : ' ') +
				(flatRight? ' _TokenSelector_flatRight ' : ' ') +
				(flatBottom? ' _TokenSelector_flatBottom ' : ' ') +
				(flatLeft? ' _TokenSelector_flatLeft ' : ' ') +
				(className ?? '') + 
				(!token?.name ? '_TokenSelector_empty' : '')
			}
			onClick={getToken}
			tabIndex={ tabIndex? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
			onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ) && getToken()}
		>
			<div className='tokenSelectorInner'>
				<div className='tokenSelectorText'>
					{token && <img className="tokenIcon" src={process.env.REACT_APP_CHROMIA_BLOCKCHAIN_IMG_BACKADDR + `/info/get_token_image?token=${token?.name}`} alt="tokenIcon"/>}
					{token?.name || 'Select Token'}
				</div>
				<div className='tokenSelectorArrow'>
					<img
						src={arrowDown}  
						alt=''
					/>
				</div>
			</div>
		</div>
	)
}

export default TokenSelector;