import React from "react";

import './Checkbox.scss';


const Checkbox: React.FC = () => {

	return (
		<div>

		</div>
	)
}

export default Checkbox;