import React, { useCallback, useEffect, useMemo, useState } from "react";

import './PoolPage.scss'
import { useStoreSelector } from "../../store/store";
import { iAccPosition } from "../MyLiqPage/MyLiqPage";
import { getChrPools, getGtxClient } from "../../utils/Chromia/chromiaHelpers";
import BigNumber from "bignumber.js";
import PositionCard from "../PositionCard/PositionCard";
import { tStatus } from "../../interfaces/interfaces";
import noLiqIcon from '../../assets/images/noLiq.svg';
import PositionPage from "../PositionPage/PositionPage";
import LiquidityPageV2 from "../LiquidityPageV2/LiquidityPageV2";
import WalletAddress from "../WalletAddress/WalletAddress";
import StarBackground from "../StarBackground/StarBackground";
import MenuPages from "../MenuPages/MenuPages";
import PixelBorder from "../UI/PixelBorder/PixelBorder";
import Button from "../UI/Button/Button";
import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";



const PoolPage: React.FC = () => {
	const {chrClientAddr, chrPools, tokens } = useStoreSelector(store => store.app);

	const [positions, setPositions] = useState<iAccPosition[]>();
  const [selectedPosition, setSelectedPosition] = useState<iAccPosition>();
  const [isNewPosition, setIsNewPosition] = useState<boolean>();

	const getPositions = useCallback(async () => {
		if (!chrClientAddr) {
      setPositions([]);
			return 
		}

		let positionsWithPools: any = [];

		const chrPositions = await (await getGtxClient()).query('slurpyswap.GetPositionsByAcc', {acc: chrClientAddr});

    chrPositions.forEach((position, index) => {
			let token0;
			let token1;

			const selectedPool = chrPools.find(pool => { 
				return pool.id === position.pool.toString('hex')
			});

			// if (!selectedPool || +position.amount_locked_in_farm > 0) return null
      if (!selectedPool) return null

			tokens.forEach((token) => {
				if (selectedPool.token0 === token.address) {
					token0 = token
				}
	
				if (selectedPool.token1 === token.address) {
					token1 = token
				}
			})

			const minPrice = new BigNumber(position.sqrt_price_lower).div((new BigNumber(2)).pow(96)).pow(2);
			const maxPrice = new BigNumber(position.sqrt_price_upper).div((new BigNumber(2)).pow(96)).pow(2);
      const currentPrice = new BigNumber(selectedPool.sqrt_price).div((new BigNumber(2)).pow(96)).pow(2);
      const status: tStatus = minPrice.gt(currentPrice) || maxPrice.lt(currentPrice) 
          ? 'Out of range'
        : 'In range'

			positionsWithPools.push({...position, id: index, token0, token1, minPrice, maxPrice, selectedPool, status})
		})

		setPositions(positionsWithPools);

    // if (selectedPosition) {
    //   const curPos = positionsWithPools.find((pos: iAccPosition) => {
    //     return pos.tick_lower === selectedPosition.tick_lower && pos.tick_upper === selectedPosition.tick_upper 
    //     && pos.token0 === selectedPosition.token0 && pos.token1 === selectedPosition.token1
    //   })
    //   setSelectedPosition(curPos ? curPos : null)
    // }
	},
	[chrClientAddr, chrPools, tokens]
	)

  useEffect(() => {
    if (selectedPosition && positions) {
      const curPos = positions?.find((pos: iAccPosition) => {
        return pos.tick_lower === selectedPosition.tick_lower && pos.tick_upper === selectedPosition.tick_upper 
        && pos.token0 === selectedPosition.token0 && pos.token1 === selectedPosition.token1
      })
      setSelectedPosition(curPos ? curPos : undefined)
    }
  }, [positions])

  useEffect(() => {
    getPositions();
  }, [chrClientAddr, getPositions])

	useEffect(() => {
    const tickInterval = setInterval(() => {
      getChrPools();
		}, 60000)

		return () => {
			clearInterval(tickInterval);
		}
	}, [chrClientAddr, getPositions])

  const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])

  if (isNewPosition) return <LiquidityPageV2 setIsNewPosition={setIsNewPosition} getPositions={getPositions}/>
  if (selectedPosition) return <PositionPage position={selectedPosition} getPositions={getPositions} setSelectedPosition={setSelectedPosition} isFarming={false}/>
	return (
    <StarBackground>
      <MenuPages/>
      <div className='PoolPage'>
        <div className="poolBlock">
          <PixelBorder>
            <div className="poolPageInner">
              <div className="poolBlock_header">
                <div className="title">
                  Positions
                </div>
                {/* <select className="customSelect">
                  <option>v2</option>
                  <option selected>v3</option>
                </select> */}
                <div className="addPosButton_wrapper">
                  <Button
                    action={() => setIsNewPosition(true)}
                  >
                    + New position
                  </Button>
                </div>
              </div>

              <WalletAddress/>

              {isMobile 
              ? <div className="positionsBlockInner">
                {positions && positions?.length > 0 ?
                  <>
                    <div className="positionsAmount">
                      <div className="title">
                        Your positions ({positions.length})
                      </div>
                    </div>
                    <div className="positionsList">
                      {positions?.map((position => {
                        return <PositionCard 
                          key={position.id + position.sqrt_price_lower + position.sqrt_price_upper} 
                          setSelectedPosition={setSelectedPosition} 
                          position={position} 
                          getPositions={getPositions} 
                          isFarming={false}
                        />
                      }))}
                    </div>
                  </>
                :
                  <div className="noLiq">
                    <img src={noLiqIcon} alt="noLiq"/>
                    <div className="noLiq_title">
                      Your active liquidity positions will appear here.
                    </div>
                  </div>
                }
                </div>
              : <BlockPixelBorder
                className="positionsBlock"
                topBorderHeight="8px"
                bottomBorderHeight="8px"
              >
                <div className="positionsBlockInner">
                {positions && positions?.length > 0 ?
                  <>
                    <div className="positionsAmount">
                      <div className="title">
                        Your positions ({positions.length})
                      </div>
                    </div>
                    <div className="positionsList">
                      {positions?.map((position => {
                        return <PositionCard 
                          key={position.id + position.sqrt_price_lower + position.sqrt_price_upper} 
                          setSelectedPosition={setSelectedPosition} 
                          position={position} 
                          getPositions={getPositions} 
                          isFarming={false}
                        />
                      }))}
                    </div>
                  </>
                :
                  <div className="noLiq">
                    <img src={noLiqIcon} alt="noLiq"/>
                    <div className="noLiq_title">
                      Your active liquidity positions will appear here.
                    </div>
                  </div>
                }
                </div>
              </BlockPixelBorder>
              }


              {/* <div className="poolPage_footer">
                <div className="card">
                  <div className="card_title">
                    Learn about providing liquidity 
                    <img src={arrowLink} alt="arrowLink"/>
                  </div>
                  <div className="card_description">
                    Check out our v3 LP walkthrough and migration guides.
                  </div>
                </div>
                <div className="card">
                  <div className="card_title">
                    Top pools
                    <img src={arrowLink} alt="arrowLink"/>
                  </div>
                  <div className="card_description">
                    Explore Analytics
                  </div>
                </div>
              </div> */}
            </div>
          </PixelBorder>
        </div>
      </div>
    </StarBackground>
	)
}

export default PoolPage;