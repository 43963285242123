import React from "react";

import './Loader.scss';


const Loader: React.FC = () => {

	return (
		<div className='_Loader'>
			<div className='innerLoader'>
				Loading...
			</div>
		</div>
	)
}

export default Loader;