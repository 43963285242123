import { useEffect, useState } from "react";

import { tModalName } from "../interfaces/interfaces";
import { useStoreSelector } from "../store/store";

interface iFirstTabindex {
	position: 'first'
}
interface iLastTabindex {
	position: 'last',
	modalName: tModalName
}
 
/**
 * Returns boolean for use in components to turn keyboard navigation.
 * 
 * @param props defines conditions: `position 'first'` returns true if there is no opened modals and `'last'` if current modal are the last element in list.
 */
export function useTabIndex(props: iFirstTabindex | iLastTabindex): boolean {
	const [isTabinex, setIsTabindex] = useState(false);
	const { openedModals } = useStoreSelector(store => store.modals);

	useEffect(()=>{
		if (props.position === 'first'){
			setIsTabindex( openedModals.length === 0 );
		}
		if (props.position === 'last'){
			setIsTabindex( openedModals[openedModals.length -1].name === props.modalName );
		}
		// eslint-disable-next-line
	}, [openedModals]);

	return isTabinex;
}