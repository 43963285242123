import React, { useCallback, useMemo, useState } from "react";

import { useStoreDispatch, useStoreSelector } from "../../store/store";
// import { connectWallet } from "../../utils/helpers";

import './RegisterPage.scss';
import Button from "../UI/Button/Button";
import { useAccount } from "wagmi";
import { whiteList } from "../../settings";
import StarBackground from "../StarBackground/StarBackground";
import WalletAddress from "../WalletAddress/WalletAddress";
import MenuPages from "../MenuPages/MenuPages";


const RegisterPage: React.FC = () => {

	const { isAccount } = useStoreSelector(store => store.app);
	const { setIsRegistered } = useStoreDispatch();
	
	const [isRegisterActive, setIsRegisterActive] = useState<boolean>();
	
	const { address } = useAccount();

	async function registerAcc()  {
		if (!address) throw new Error('no client addr')

		const data = {
			evm_address: address
		}
		setIsRegisterActive(true);

    return fetch(process.env.REACT_APP_CHROMIA_BLOCKCHAIN_BACKADDR + `/ft4/register_account_and_mint_assets?evm_address=${address}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then(res => {
        if (res.ok) {
					setIsRegistered(true);
          return 'OK'
        }
        return res.text().then(text => text)
    })
	}

  const checkWhiteList = useCallback(() => {
    if (whiteList.find((whiteAddr) => whiteAddr.toLocaleLowerCase() === address?.toLocaleLowerCase()))
      return true
      else return false
  },[address])

  const isInWhiteList = useMemo( () => {
    return checkWhiteList()
  },[checkWhiteList])

	return(
    <StarBackground>
      <WalletAddress/>
      <MenuPages/>
      <div className="RegisterPage">

        {!isAccount &&
            <div className="buttonWrapper">
              {isRegisterActive
                ? 'Registering your account...Please, wait'
                // : isInWhiteList ?
                : <div>
                    <Button action={registerAcc}>
                      Register Account
                    </Button>
                  </div> 
                    // : isInWhiteList === false && 'Currently only the accounts in the whitelist can use the product'
              }
            </div>
        }
      </div>
      </StarBackground>
	)
}

export default RegisterPage;