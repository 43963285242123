import { iModalsState, tModalName } from "../../interfaces/interfaces";
import { eModalsActionTypes, tModalsAction } from "./modalsTypes";

const initialState: iModalsState = {
    modalsIds: {
        info: 0,
        tokenSelect: 0,
        LPTReturn: 0,
        transactionSettings: 0,
        confirm: 0,
        mobileSwapChart: 0,
		LSTPro: 0,
		mobileMenu: 0,
        transaction: 0,
        farmingStake: 0,
        farmingUnstake: 0,
        AddLiqModal: 0,
        claim: 0,
        farmModal: 0,
        connectors: 0
    },
    openedModals: [],
}

export const modalsReducer = ( state = initialState, action: tModalsAction) : iModalsState => {
    switch (action.type){
    
        case eModalsActionTypes.DISCONNECT:
            return initialState;

        case eModalsActionTypes.OPEN_NEW_MODAL:
            // let modalsIdsCopy = structuredClone(state.modalsIds);    // JS should be updated
            let modalsIds: {[K in tModalName]: number} = Object.assign(state.modalsIds);
            modalsIds[action.payload.name] = state.modalsIds[action.payload.name] + 1;
            return {
                ...state,
                openedModals: state.openedModals.concat({...action.payload, modalId: state.modalsIds[action.payload.name]}),
                modalsIds,
            };

        case eModalsActionTypes.CLOSE_MODAL:
            return {
                ...state,
                openedModals: state.openedModals.filter(modal =>
                    !(modal.name === action.payload.name &&
                    modal.modalId === action.payload.id)
                )
            };
        
        default: return state;
    }
}