export enum eUIActionTypes {
	TOGGLE_DARK_MODE = 'UI/TOGGLE_DARK_MODE',
	TOGGLE_BG_ANIMATION = 'UI/TOGGLE_BG_ANIMATION',
	SET_IS_INITING = 'UI/SET_IS_INITING',
	SET_IS_GRABBING_CURSOR = 'UI/SET_IS_GRABBING_CURSOR',
}

export type tUIAction = 
	iToggleDarkMode |
	iToggleBgAnimation |
	iSetIsIniting |
	iSetIsGrabbingCursor
;

interface iToggleDarkMode {
	type: eUIActionTypes.TOGGLE_DARK_MODE
}

interface iToggleBgAnimation {
	type: eUIActionTypes.TOGGLE_BG_ANIMATION
}

interface iSetIsIniting {
	type: eUIActionTypes.SET_IS_INITING,
	payload: boolean
}

interface iSetIsGrabbingCursor {
	type: eUIActionTypes.SET_IS_GRABBING_CURSOR,
	payload: boolean
}