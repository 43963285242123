import { compose, applyMiddleware, bindActionCreators, legacy_createStore } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';

import { root_reducer } from './rootReducer';
import { iReduxState } from '../interfaces/interfaces';
import { showRedux } from '../settings';

import * as appActionCreators from './app/appActions';
import * as modalsActionCreators from './modals/modalsActions';
import * as UIActionCreators from './UI/UIActions';

declare global{
	interface Window{
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : any;
	}
}

const composeEnhancers = (showRedux && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose ; 
 
export const store = legacy_createStore( root_reducer, composeEnhancers(
	applyMiddleware(
        thunk
    )
));

export type Dispatch = typeof store.dispatch;

export const useStoreDispatch = () => {		// combine all actions and wrapps it in dispatch 
	const dispatch = useDispatch();
	let allActionCreators = { 
		...appActionCreators,
		...modalsActionCreators,
		...UIActionCreators,
	};
	return bindActionCreators(allActionCreators, dispatch);
}

export const useStoreSelector: TypedUseSelectorHook<iReduxState> = useSelector;	// gives access to redux store
