import Web3 from "web3";

import { BigNumber } from "../entities/BigNumber";
import { Currency } from "../entities/Currency";
import { NativeCurrency } from "../entities/NativeCurrency";
import { Token } from "../entities/Token";
import { configTokens, factoryAbi, iConfigCustomToken, iConfigNativeToken, iConfigToken, iFactoryContract, iMasterChefContract, iPair, iPairContract, iPancakeContract, iPoolContract, iPoolPrice, iTokenContract, iV3FactoryContract, iV3PoolNFTRouterContract, masterChefAbi, masterChefAddr, maxAmount, pairAbi, pancakeV3FactoryAddr, poolAbi, PSAbi, PSAddr, tokenAbi, tTokenFeatures, V3FactoryAbi, v3PoolNFTRouterAbi, v3PoolNFTRouterAddresses, zeroAddr } from "../interfaces/interfaces";
import { eAppActionTypes } from "../store/app/appTypes";
import { store } from "../store/store";
import { iChrPool } from "../interfaces/chromiaInterfaces";
import { BN } from "bn.js";
import { q96, tickBase } from "../constants";

interface iGetTokenParams {
	type?: 'token',
	address: string,
}
interface iGetNativeTokenParams {
	type: 'nativeToken',
}

export function getConfigToken(params: iGetTokenParams): iConfigCustomToken;
export function getConfigToken(params: iGetNativeTokenParams): iConfigNativeToken;
export function getConfigToken(params: iGetTokenParams | iGetNativeTokenParams): iConfigToken {
	const foundToken = params.type === 'nativeToken'
		? configTokens.find(token => token.type === 'native')
		: configTokens.find(token => token.type === 'custom' && token.address.toLocaleLowerCase() === params.address.toLocaleLowerCase())
	;
	if (foundToken) return foundToken;
	throw new Error();
}

export function getToken(params: iGetNativeTokenParams): NativeCurrency;
export function getToken(params: iGetTokenParams): Token;
export function getToken(params: iGetNativeTokenParams | iGetTokenParams): Currency {
	const { tokens } = store.getState().app;

	if (params.type === 'nativeToken') {
		const foundToken = tokens.find(token => token.isNative);
		if (foundToken) return foundToken;
		const configToken = getConfigToken({type: 'nativeToken'});
		const newToken = new NativeCurrency(configToken.chainId, configToken.wrappedTokenAddress ,configToken.decimals, configToken.name);
		store.dispatch({
			type: eAppActionTypes.ADD_TOKEN,
			payload: newToken as unknown as Token	// chromia workaround
		})
		return newToken;
	}
	const foundToken = tokens.find(token => !token.isNative && token.address.toLocaleLowerCase() === params.address.toLocaleLowerCase());
	if (foundToken) return foundToken;
	const configToken = getConfigToken({type: 'token', address: params.address});
	const newToken = new Token(configToken.chainId, configToken.address, configToken.decimals, configToken.name, undefined ,configToken.features);
	store.dispatch({
		type: eAppActionTypes.ADD_TOKEN,
		payload: newToken
	})
	return newToken;
}

export function getTokenByFeature(feature: Extract<tTokenFeatures, 'stable'>): Token[];
export function getTokenByFeature(feature: Extract<tTokenFeatures, 'LSTlong' | 'LSTshort' | 'wrappedNative'>): Token;
export function getTokenByFeature(feature: Extract<tTokenFeatures, 'LSTSecond'>): Currency;
export function getTokenByFeature(feature: tTokenFeatures): Currency | Currency[]{
	/* const res = feature === 'stable'?	
		configTokens.filter(configToken =>
			configToken.type === 'custom' &&
			configToken.features?.includes(feature)
		) as iConfigCustomToken[] | undefined
	:
		configTokens.find(configToken =>
			configToken.type === 'custom' &&
			configToken.features?.includes(feature)
		) as iConfigCustomToken | undefined
	;
	if (!res) throw new Error();
	return getToken({type: 'token', address: res.address}); */
	if (feature === 'stable'){	
		return configTokens.filter(configToken =>
			configToken.type === 'custom' &&
			configToken.features?.includes(feature)
		// ).map(filtredToken => getToken(filtredToken.type === 'native'? {type: 'nativeToken'} : {address: filtredToken.address}))
		).map(filtredToken => filtredToken.type === 'native'? getToken({type: 'nativeToken'}) : getToken({address: filtredToken.address}))
	}

	const token = configTokens.find(configToken =>
		configToken.type === 'custom' &&
		configToken.features?.includes(feature)
	);
	if (!token) throw new Error();
	return token.type === 'native'? getToken({type: 'nativeToken'}) : getToken({address: token.address})
}

/**
 * Returns pair data.
 */
export const getPair = async (tokenA: Currency, tokenB: Currency): Promise<iPair> => {

	const foundPair = store.getState().app.pairs.find(pair =>
		(pair.currencyA.equals(tokenA) && pair.currencyB.equals(tokenB)) ||
		(pair.currencyA.equals(tokenB) && pair.currencyB.equals(tokenA))
	);
	if (foundPair && foundPair.LPToken){
		return foundPair;
	}

	// if address not exists - checks again
	const pairAddr = foundPair?.LPToken?.address || await (await getPancakeFactory()).methods.getPair(
		// tokenAAddress,
		tokenA.isNative? tokenA.wrappedTokenAddress : tokenA.address,
		// tokenBAddress
		tokenB.isNative? tokenB.wrappedTokenAddress : tokenB.address,
	).call(); // null if pool not exists

	if (!pairAddr || pairAddr === zeroAddr){	// is first rule correct
		return {
			// currencyA: tokenAAddress,
			currencyA: tokenA,
			// currencyB: tokenBAddress,
			currencyB: tokenB,
			LPToken: null
		};
	}

	const res: iPair = {
		// currencyA: tokenAAddress,
		currencyA: tokenA,
		// currencyB: tokenBAddress,
		currencyB: tokenB,

		LPToken: new Token(
			56,	// -?
			pairAddr,
			+await getPairContract(pairAddr).methods.decimals().call(),
			tokenA.name + '-' + tokenB.name + ' LP token',	// ???
		)
	}

	store.dispatch({
		type: eAppActionTypes.SET_PAIR,
		payload: res
	})

	return res;
}

// export const getPool = async (tokenA: Currency, tokenB: Currency, fee: BigNumber): Promise<iPool> => {
export const getPool = async (currencyA: Token, currencyB: Token, fee: number): Promise<iChrPool | undefined> => {
	const foundPool = store.getState().app.chrPools.find(pool =>
		+pool.trading_fees === fee && (
			((pool.token0 === currencyA.address) && (pool.token1 === currencyB.address)) ||
			((pool.token0 === currencyB.address) && (pool.token1 === currencyA.address))
		)
	);

	if (foundPool && foundPool.id) {
		return foundPool;
	}
	// const V3Factory = getPancakeV3Factory();

	// const addrA = currencyA.address;
	// const addrB = currencyB.address;
	// const poolFee = +fee * 10000;


	// const poolAddress = await V3Factory.methods.getPool(
	// 	addrA,
	// 	addrB,
	// 	poolFee
	// ).call();


	// return {
	// 	currencyA,
	// 	currencyB,
	// 	fee,
	// 	address: poolAddress === zeroAddr
	// 		? undefined
	// 		: poolAddress
	// }
}

/* interface igetV3PoolContractParams1 {
	currencyA: Currency,
	currencyB: Currency,
	fee: tPoolFee
}
interface igetV3PoolContractParams2 {
	pool: string
} */

export const getV3PoolContract = (poolAddress: string): iPoolContract => {
	const {poolContracts} = store.getState().app;
	const foundContract = poolContracts[poolAddress];
	if (foundContract){
		return foundContract;
	}

	const newContract: iPoolContract = new (getWeb3().eth.Contract)(JSON.parse(poolAbi), poolAddress);
	store.dispatch({
		type: eAppActionTypes.SET_POOL_CONTRACT,
		payload: {
			address: poolAddress,
			contract: newContract
		}
	})
	return newContract;
}
interface iGetV3PoolPriceOptions {
	update: boolean;
}
export const getV3PoolPrice = async (pool: iChrPool, options?: iGetV3PoolPriceOptions): Promise<iPoolPrice | undefined> => {
	if (!pool.id) return;
	
	const {poolPrices, tokens} = store.getState().app;

	if (!options?.update){
		const foundPrice = poolPrices[pool.id];
		if (foundPrice){
			return foundPrice;
		}
	};
	
	const tick = pool.tick;

	let token0;
	let token1;

	tokens.forEach((token) => {
		if (token.address === pool.token0) {
			token0 = pool
		}

		if (token.address === pool.token1) {
			token1 = pool
		}
	})
	
	if (!token0 || !token1) {
		throw new Error('no tokens for pool')
	}
	
	const price = '0'
	
	store.dispatch({
		type: eAppActionTypes.SET_POOL_PRICE,
		payload: {tick, price}
	});

	return {tick, price};
}

export const getWeb3 = (): Web3 => {
	const { web3 } = store.getState().app;
	if (web3) return web3;

	if (!Web3 || !Web3.givenProvider){
		throw new Error();
	}
	const newWeb3 = new Web3(Web3.givenProvider);

	store.dispatch({
		type: eAppActionTypes.SET_WEB3,
		payload: newWeb3
	})

	return newWeb3;
}

export const getPancakeContract = (): iPancakeContract => {
	const { PScontract } = store.getState().app;
	if (PScontract) return PScontract;

	const newContract: iPancakeContract = new (getWeb3().eth.Contract)(JSON.parse(PSAbi), PSAddr);

	store.dispatch({
		type: eAppActionTypes.SET_PS_CONTRACT,
		payload: newContract
	})

	return newContract;
}

export const getMasterChefContract = async (): Promise<iMasterChefContract> => {
	const { masterChefContract } = store.getState().app;
	if (masterChefContract) return masterChefContract;

	const newContract: iMasterChefContract = new (getWeb3().eth.Contract)(
		JSON.parse(masterChefAbi),
		masterChefAddr
	);

	store.dispatch({
		type: eAppActionTypes.SET_MASTERCHEF_CONTRACT,
		payload: newContract
	});

	return newContract;
}

export const getPancakeFactory = async (): Promise<iFactoryContract> => {
	const { factory } = store.getState().app;
	if (factory) return factory;

	const newFactory: iFactoryContract = new (getWeb3().eth.Contract)(
		JSON.parse(factoryAbi),
		await getPancakeContract().methods.factory().call()
	);

	store.dispatch({
		type: eAppActionTypes.SET_FACTORY,
		payload: newFactory
	});

	return newFactory;
}

export const getPancakeV3Factory = (): iV3FactoryContract => {
	const { V3Factory } = store.getState().app;
	if (V3Factory) return V3Factory;

	const newFactory: iV3FactoryContract = new (getWeb3().eth.Contract)(
		JSON.parse(V3FactoryAbi),
		pancakeV3FactoryAddr,
	);

	store.dispatch({
		type: eAppActionTypes.SET_V3_FACTORY,
		payload: newFactory
	});

	return newFactory;
}

export const getTokenContract = (address: string): iTokenContract => {
	const {tokenContracts} = store.getState().app;
	const foundContract = tokenContracts[address];
	if (foundContract) return foundContract;
	const newContract = new (getWeb3().eth.Contract)(JSON.parse(tokenAbi), address);
	store.dispatch({
		type: eAppActionTypes.SET_TOKEN_CONTRACT,
		payload: {
			address: address,
			contract: newContract
		}
	})
	return newContract;
}

export const getPairContract = (address: string): iPairContract => {
	const {pairContracts} = store.getState().app;
	const foundContract = pairContracts[address];
	if (foundContract) return foundContract;
	const newContract = new (getWeb3().eth.Contract)(JSON.parse(pairAbi), address);
	store.dispatch({
		type: eAppActionTypes.SET_PAIR_CONTRACT,
		payload: {
			address: address,
			contract: newContract
		}
	})
	return newContract;
}

interface iGetAllowanceBalanceTokenParams {
	type?: 'token',
	token: Currency,
}
interface iGetAllowanceBalancePairParams {
	type: 'pair',
	token: Token
}
interface iGetBalanceOptions {
	tokenDecimals?: number,
	/**
	 * Returns balance of passed address.
	 * 
	 * Higher priority than `update` prop.
	 * 
	 * Default: client address.
	*/
	of?: string,
	/**
	 * Returns updated value and store it.
	 * 
	 * Ignored if used with `of` prop.
	*/
	update?: boolean,
}
/**
 * Wrap it in trycatch.
 * 
 * Default type: 'token'
 */
export const getBalance = async (params: iGetAllowanceBalanceTokenParams | iGetAllowanceBalancePairParams, options?: iGetBalanceOptions): Promise<BigNumber> => {
	const { tokenBalances } = store.getState().app;

	if (params.token.isNative){
		if (!options?.update && !options?.of){
			const foundBalance = tokenBalances['native'];
			if (foundBalance) return foundBalance;
		}
		// const balance = new BigNumber(
			// await getWeb3().eth.getBalance(ofAddress),
			// {
				// currentDecimals: 18,
				// stringDecimals: options?.stringDecimals,
			// }
		// );

		// if (!options?.of){
		// 	const foundBalance = tokenBalances['native'];
		// 	if (!foundBalance?.valueBN18.eq(balance.valueBN18)){
		// 		store.dispatch({
		// 			type: eAppActionTypes.SET_TOKEN_BALANCE,
		// 			payload: {
		// 				address: 'native',
		// 				balance: balance
		// 			}
		// 		});
		// 	}
		// }
		return new BigNumber(0);
	}

	if (!options?.update && !options?.of){
		const foundBalance = tokenBalances[params.token.address];
		if (foundBalance) return foundBalance;
	}

	// const tokenContract = getTokenContract(params.token.address);

	// const decimals: number =
	// 	options?.tokenDecimals ||
	// 	params.token.decimals
	// ;
	const balance = new BigNumber(0
		// await tokenContract.methods.balanceOf(ofAddress).call(),
		// {
		// 	currentDecimals: decimals,
		// 	decimals: decimals,
		// 	// stringDecimals: options?.stringDecimals,
		// }
	);

	if (!options?.of){
		const foundBalance = tokenBalances[params.token.address];
		if (!foundBalance?.valueBN18.eq(balance.valueBN18)){
			store.dispatch({
				type: eAppActionTypes.SET_TOKEN_BALANCE,
				payload: {
					address: params.token.address,
					balance: balance
				}
			})
		}
	}

	return balance;
}

export interface iGetAllowanceParams {
	type: 'token' | 'pair',
	token: Currency,
}
interface iGetAllowanceOptions {
	tokenDecimals?: number,
	/**
	 * Returns allowance of passed address.
	*/
	// of?: string,
	/**
	  * Returns allowance to passed address.
	  * 
	  * Default: client address.
	*/
	to?: string,
	/**
	  * Returns updated value and store it.
	  * 
	  * Default: pancakeswap address.
	*/
	update?: boolean,
}
/**
 * Wrap it in trycatch.
 * 
 * Default type: 'token'
 */
// export const getAllowance = async (params: iGetAllowanceTokenParams | iGetAllowancePairParams, options?: iGetAllowanceOptions): Promise<BigNumber> => {
export const getAllowance = async (params: iGetAllowanceParams, options?: iGetAllowanceOptions): Promise<BigNumber> => {
	const { clientAddr, allowances } = store.getState().app;

	const ofAddress = clientAddr;
	const toAddress = options?.to || PSAddr;
	if (!ofAddress){
		throw new Error();
	}
	const storeId = params.token.wrapped.address + '-' + toAddress;

	if (params.type === 'pair') {
		if (!options?.update){
			const foundAllowance = allowances[storeId];
			if (foundAllowance) return foundAllowance;
		}
		const pairContract = getPairContract(params.token.wrapped.address);
		const decimals: number =
			options?.tokenDecimals ||
			params.token.decimals
		;
		const allowance = new BigNumber(
			await pairContract.methods.allowance(ofAddress, toAddress).call(),
			{
				currentDecimals: decimals,
				decimals: decimals,
			}
		);

		if (!allowances[storeId]?.valueBN18.eq(allowance.valueBN18)){
			store.dispatch({
				type: eAppActionTypes.SET_ALLOWANCE,
				payload: {
					address: storeId,
					allowance: allowance,
				}
			});
		}

		return allowance;
	}

	if (params.type === 'token'){
		if (params.token.isNative){
			return new BigNumber(maxAmount);
		}

		if (!options?.update){
			const foundAllowance = allowances[storeId];
			if (foundAllowance) return foundAllowance;
		}
		const tokenContract: iTokenContract = getTokenContract(params.token.address);
		const decimals: number =
			options?.tokenDecimals ||
			params.token.decimals
		;
		const allowance = new BigNumber(
			await tokenContract.methods.allowance(ofAddress, toAddress).call(),
			{
				currentDecimals: decimals,
				decimals: decimals,
			}
		);

		if (!allowances[storeId]?.valueBN18.eq(allowance.valueBN18)){
			store.dispatch({
				type: eAppActionTypes.SET_ALLOWANCE,
				payload: {
					address: storeId,
					allowance: allowance
				}
			});
		}

		return allowance;
	}

	throw new Error('getAllowance unknown type');
	// throw new Error('getAllowance unknown type: `' + (params.type) + '`');
}
/* export const getAllowanceOld = async (params: iGetAllowanceBalanceTokenParams | iGetAllowanceBalancePairParams, options?: iGetAllowanceOptions): Promise<BigNumber> => {
	const { clientAddr, tokenAllowances, pairAllowances } = store.getState().app;

	// options?.of && checkValidAddress(options.of);
	options?.to && checkValidAddress(options.to);

	// const ofAddress = options?.of || clientAddr;
	const ofAddress = clientAddr;
	const toAddress = options?.to || PSAddr;
	if (!ofAddress){
		throw new Error();
	}

	if (params.type === 'pair'){
		// if (!options?.update && !options?.of && !options?.to){
		if (!options?.update && !options?.to){
			const foundAllowance = pairAllowances[params.token.address];
			if (foundAllowance) return foundAllowance;
		}
		const pairContract = getPairContract(params.token.address);
		const decimals: number =
			options?.tokenDecimals ||
			params.token.decimals
		;
		const allowance = new BigNumber(
			await pairContract.methods.allowance(ofAddress, toAddress).call(),
			{
				currentDecimals: decimals,
				decimals: decimals,
			}
		);
		
		// if (!options?.of && !options?.to){
		if (!options?.to){
			const foundAllowance = pairAllowances[params.token.address];
			if (!foundAllowance?.valueBN18.eq(allowance.valueBN18)){
				store.dispatch({
					type: eAppActionTypes.SET_PAIR_ALLOWANCE,
					payload: {
						address: params.token.address,
						allowance: allowance,
					}
				});
			}
		}

		return allowance;
	}

	if (params.token.isNative){
		return new BigNumber(maxAmount);
	}

	// if (!options?.update && !options?.of && !options?.to){
	if (!options?.update && !options?.to){
		const foundAllowance = tokenAllowances[params.token.address];
		if (foundAllowance) return foundAllowance;
	}
	const tokenContract: iTokenContract = getTokenContract(params.token.address);
	const decimals: number =
		options?.tokenDecimals ||
		params.token.decimals
	;
	const allowance = new BigNumber(
		await tokenContract.methods.allowance(ofAddress, toAddress).call(),
		{
			currentDecimals: decimals,
			decimals: decimals,
		}
	);

	// if (!options?.of && !options?.to){
	if (!options?.to){
		const foundAllowance = pairAllowances[params.token.address];
		if (!foundAllowance?.valueBN18.eq(allowance.valueBN18)){
			store.dispatch({
				type: eAppActionTypes.SET_TOKEN_ALLOWANCE,
				payload: {
					address: params.token.address,
					allowance: allowance
				}
			});
		}
	}

	return allowance;
} */

export const getV3PoolNFTRouter = (): iV3PoolNFTRouterContract => {
	// const { chainId } = useActiveChainId()
	// const chainId = 56;
	const chainId = 97;
	const {v3PoolNFTRouters} = store.getState().app;
	const foundContract = v3PoolNFTRouters[chainId];
	if (foundContract) return foundContract;

	const newContract: iV3PoolNFTRouterContract = new (getWeb3().eth.Contract)(JSON.parse(v3PoolNFTRouterAbi), v3PoolNFTRouterAddresses[chainId]) as any;
	store.dispatch({
		type: eAppActionTypes.SET_V3_POOL_NFT_ROUTER,
		payload: {
			chainId: chainId,
			contract: newContract
		}
	})
	return newContract;
}

export function sqrtPriceToTick(sqrtPrice: string): string {
	return new BigNumber((new BigNumber(sqrtPrice)).valueBN18.div((new BN(2)).pow(new BN(96))), {currentDecimals: 18}).valueStr;
}

export function tick2Price(tick: number):string {
	return ((new BN(tickBase)).pow(new BN(tick)).mul(new BN(q96))).toString();
}