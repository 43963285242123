import { FC, PropsWithChildren, useState } from "react";

import catIcon from "../../assets/images/Cat_coin.svg";
import rocketIcon from "../../assets/images/Rocket.svg";
import moonIcon from "../../assets/images/Moon.svg";
import dogIcon from "../../assets/images/Dog.png";
import dogGlassesUp from "../../assets/images/DogGlassesUp.png";
import coinsIcon from "../../assets/images/Coins.svg";
import cometIcon from "../../assets/images/Comet.svg";
import logo from "../../assets/images/Logo_box.svg"
import Footer from "../Footer/Footer";
import starBig4 from '../../assets/images/StarBig4.svg';
import starBig5 from '../../assets/images/StarBig5.svg';
import starSmall4 from '../../assets/images/StarSmall4.svg';

import './StarBackground.scss';
import { Link } from "react-router-dom";


const StarBackground:FC <PropsWithChildren<any>> = ({children}) => {
	const [isDogMouseOn, setIsDogMouseOn] = useState<boolean>(false);
	return (
		<div className="_StarBackground">
			<img className="catIcon" src={catIcon} alt="cat-icon"/>
			<img src={starBig4} className="starBig41" alt="star"/>
			<img src={starSmall4} className="starSmall1" alt="star"/>
			<img src={starSmall4} className="starSmall2" alt="star"/>
		
			<div className="logo_wrapper">
				{/* <Link to="landing"> */}
					<img className="logo" src={logo} alt="logo"/>
				{/* </Link> */}
			</div>
			<div className="rocketCometIcons">
				<div className="cometIcon_wrapper">
					<img className="cometIcon" src={cometIcon} alt="comet-icon"/>
					<img src={starBig5} className="starBig52" alt="star"/> 
					<img src={starSmall4} className="starSmall3" alt="star"/>
				</div>
				<div className="rocketIcon_wrapper">
					<img className="rocketIcon" src={rocketIcon} alt="rocket-icon"/>
					<img src={starBig5} className="starBig51" alt="star"/>
					<img src={starSmall4} className="starSmall3" alt="star"/>
				</div>
			</div>
			<div className="moonIcon_wrapper">
				<img src={starSmall4} className="starSmall1" alt="star"/>
				<img src={starSmall4} className="starSmall2" alt="star"/>
				<img src={starSmall4} className="starSmall3" alt="star"/>
				<img src={starBig5} className="starBig51" alt="star"/>
				<img src={starBig4} className="starBig41" alt="star"/>
				<img src={starBig5} className="starBig52" alt="star"/> 
				<img src={starBig5} className="starBig53" alt="star"/> 
				<img className="moonIcon" src={moonIcon} alt="moon-icon"/>
			</div>
			<div className="dogCoinsIcons">
				<img className="dogIcon" onMouseEnter={() => setIsDogMouseOn(true)} onMouseLeave={() => setIsDogMouseOn(false)} src={isDogMouseOn ? dogGlassesUp : dogIcon} alt="dog-icon"/>
				<img className="coinsIcon" src={coinsIcon} alt="coins-icon"/>
			</div>
			<Footer/>
			{children}
		</div>
	)
}

export default StarBackground;