import React, { FC, PropsWithChildren } from "react";

import './PixelBorder.scss';

interface iPixelBorder {
  modal?: boolean
}
const PixelBorder:FC <PropsWithChildren<iPixelBorder>> = ({children, modal}) => {



	return (
    <div className="wrapper" style={{backgroundColor: modal ? 'transparent' : '#00346A'}}>
      <div className="wrapper1">
        <div className="wrapper1_transparent"/>
        <div className="wrapper1_black"/>
        <div className="wrapper1_transparent2"/>
      </div>
      <div className="wrapper2">
        <div className="wrapper2_transparent"/>
        <div className="wrapper2_black"/>
        <div className="wrapper2_white"/>
        <div className="wrapper2_black2"/>
        <div className="wrapper2_transparent2"/>
      </div>
      <div className="wrapper3">
        <div className="wrapper3_gray"/>
        <div className="wrapper3_black"/>
        <div className="wrapper3_white"/>
        <div className="wrapper3_gray2"/>
        <div className="wrapper3_blue" style={{backgroundColor: modal ? '#ffff' : '#00346A'}}/>
        <div className="wrapper3_gray"/>
        <div className="wrapper3_black"/>
        <div className="wrapper3_blue" style={{backgroundColor: modal ? 'black' : '#00346A'}}/>
      </div>
      <div className="wrapper4">
        <div className="wrapper4_black"/>
        <div className="wrapper4_white"/>
        <div className="wrapper4_gray"/>
        <div className="wrapper4_blue" style={{backgroundColor: modal ? '#ffff' : '#00346A'}}/>
        <div className="wrapper4_dark_gray"/>
        <div className="wrapper4_black"/>
      </div>
      <div className="wrapper5">
        <div className="wrapper5_black"/>
        <div className="wrapper5_gray"/>
        <div className="wrapper5_blue" style={{backgroundColor: modal ? '#ffff' : '#00346A'}}/>
        <div className="wrapper5_dark_gray"/>
        <div className="wrapper5_black"/>
      </div>
      <div className="flexInner">
        <div className="leftBorder">
          <div className="leftBorder_black"/>
          <div className="leftBorder_white"/>
        </div>
        {children}
        <div className="rightBorder">
          <div className="rightBorder_blue" style={{backgroundColor: modal ? '#ffff' : '#00346A'}}/>
          <div className="rightBorder_dark_gray"/>
          <div className="rightBorder_black"/>
        </div>
      </div>
      <div className="wrapper6">
        <div className="wrapper6_gray"/>
        <div className="wrapper6_black"/>
        <div className="wrapper6_dark_gray"/>
        <div className="wrapper6_gray2"/>
        <div className="wrapper6_blue" style={{backgroundColor: modal ? '#ffff' : '#00346A'}}/>
        <div className="wrapper6_dark_gray"/>
        <div className="wrapper6_black"/>
      </div>
      <div className="wrapper7">
        <div className="wrapper7_blue" style={{backgroundColor: modal ? 'transparent' : '#00346A'}}/>
        <div className="wrapper7_black"/>
        <div className="wrapper7_dark_gray"/>
        <div className="wrapper7_black2"/>
        <div className="wrapper7_blue2" style={{backgroundColor: modal ? 'transparent' : '#00346A'}}/>
      </div>
      <div className="wrapper8">
        <div className="wrapper8_blue"style={{backgroundColor: modal ? 'transparent' : '#00346A'}}/>
        <div className="wrapper8_black"/>
        <div className="wrapper8_blue2" style={{backgroundColor: modal ? 'transparent' : '#00346A'}}/>
      </div>
    </div>
	)
}

export default PixelBorder;