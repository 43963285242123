import React, { lazy, Suspense } from "react";

import { useStoreSelector } from "../../../store/store";

import Loader from "../../UI/Loader/Loader";
import AddLiqModal from "../AddLiqModal/AddLiqModal";
import ClaimFeeModal from "../ClaimFeeModal/ClaimFeeModal";
import FarmModal from "../FarmModal/FarmModal";
import ConnectorsModal from "../ConnectorsModal/ConnectorsModal";

const InfoModal = lazy(() => import('../InfoModal/InfoModal'));
const LPTReturnModal = lazy(() => import('../LPTReturnModal/LPTReturnModal'));
const TokenSelectModal = lazy(() => import('../TokenSelectModal/TokenSelectModal'));
const TransactionSettingsModal = lazy(() => import('../TransactionSettingsModal/TransactionSettingsModal'));
const ConfirmModal = lazy(() => import('../ConfirmModal/ConfirmModal'));
const TransactionModal = lazy(() => import('../TransactionModal/TransactionModal'));
const FarmingHarvestModal = lazy(() => import('../FarmingUnstakeModal/FarmingUnstakeModal'))
const FarmingStakeModal = lazy(() => import('../FarmingStakeModal/FarmingStakeModal'))


const ModalsController: React.FC = () => {

	const { openedModals } = useStoreSelector(store => store.modals);

	
	return (
		<>
			{openedModals.map(modal => {
				switch (modal.name) {
					case 'info':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<InfoModal
									name={modal.name}
									modalId={modal.modalId}
									title={modal.title}
									message={modal.message}
									type={modal.type}
								/>
							</Suspense>
						)
					;

					case 'LPTReturn':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<LPTReturnModal
									name={modal.name}
									modalId={modal.modalId}
									sellToken={modal.sellToken}
									buyToken={modal.buyToken}
									position={modal.position}
									setPositions={modal.setPositions}
									receivedAmounts={modal.receivedAmounts}
									isFarming={modal.isFarming}
								/>
							</Suspense>
						)
					;

					case 'AddLiqModal':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<AddLiqModal
									name={modal.name}
									modalId={modal.modalId}
									sellToken={modal.sellToken}
									buyToken={modal.buyToken}
									position={modal.position}
									setPositions={modal.setPositions}
									isFarming={modal.isFarming}
								/>
							</Suspense>
						)
					;

					case 'tokenSelect':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<TokenSelectModal
									name={modal.name}
									modalId={modal.modalId}
									promiseFork={modal.promiseFork}
									blockedTokens={modal.blockedTokens}
								/>
							</Suspense>
						)
					;

					case 'transactionSettings':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<TransactionSettingsModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;

					case 'confirm':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<ConfirmModal
									name={modal.name}
									modalId={modal.modalId}
									promiseFork={modal.promiseFork}
									title={modal.title}
									message={modal.message}
									cancelButton={modal.cancelButton}
									confirmButton={modal.confirmButton}
								/>
							</Suspense>
						)
					;

					case 'claim':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<ClaimFeeModal
									name={modal.name}
									modalId={modal.modalId}
									title={modal.title}
									position={modal.position}
								/>
							</Suspense>
						)
					;

					case 'farmModal':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<FarmModal
									name={modal.name}
									modalId={modal.modalId}
									lpPos={modal.lpPos}
									farmPos={modal.farmPos}
									currFarm={modal.currFarm}
									updateFarms={modal.updateFarms}
								/>
							</Suspense>
						)
					;

					case 'transaction':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<TransactionModal
									name={modal.name}
									modalId={modal.modalId}
									transaction={modal.transaction}
								/>
							</Suspense> 
						)
					;

					case 'connectors':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<ConnectorsModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;

					case 'farmingStake':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<FarmingStakeModal
									name={modal.name}
									modalId={modal.modalId}
									farm={modal.farm}
								/>
							</Suspense>
						)
					;

					case 'farmingUnstake':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<FarmingHarvestModal
									name={modal.name}
									modalId={modal.modalId}
									farm={modal.farm}
								/>
							</Suspense>
						)
					;
					
					default:
						return null
					;
				}
				/* return <Suspense
					key={modal.name + modal.modalId}
					fallback={<></>}
				>
					{modal.name === 'info' &&
						<InfoModal
							modalId={modal.modalId}
							title={modal.title}
							message={modal.message}
							type={modal.type}
						/>
					}

					{modal.name === 'approve' &&
						<ApproveModal
							modalId={modal.modalId}
							amount={modal.amount}
							promiseFork={modal.promiseFork}
							token={modal.token}
						/>
					}

					{modal.name === 'tokenSelect' &&
						<TokenSelectModal
							modalId={modal.modalId}
							promiseFork={modal.promiseFork}
						/>
					}

					{/*modal.name === 'transaction' &&
						<></>
					/}
				</Suspense> */
				/*
				if (modal.name === 'info') return (
					<Suspense
						key={modal.name + modal.modalId}
						fallback={<></>}
					> 
						<InfoModal
							modalId={modal.modalId}
							title={modal.title}
							message={modal.message}
							type={modal.type}
						/>
					</Suspense>
				)

				if (modal.name === 'approve') return (
					<Suspense
						key={modal.name + modal.modalId}
						fallback={<></>}
					>
						<ApproveModal
							modalId={modal.modalId}
							approveAmount={modal.approveAmount}
							approvePromiseFork={modal.approvePromiseFork}
							approveToken={modal.approveToken}
						/>
					</Suspense> 
				)

				if (modal.name === 'deposit')return (
					<Suspense
						key={modal.name + modal.modalId}
						fallback={<></>}
					>
						<DepositModal/>
					</Suspense>
				)

				if (modal.name === 'transaction') return (
					<Suspense
						key={modal.name + modal.modalId}
						fallback={<></>}
					>    
					
					</Suspense>
				)

				if (modal.name === 'withdrawDeposit'){
					return <Suspense fallback={<></>}>
						<WithdrawDepositModal/>
					</Suspense>
				}*/
			})}
		</>
	)
}

export default ModalsController;