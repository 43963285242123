export const production: boolean = false;
export const showRedux: boolean = true;
export const basename: string = '/';
export const chainId: '0x38' | '0x61' = '0x61';
export const preventSendingError: boolean = false;
export const chainIdName = 97
export const leverageMultiplier: number = 3;

export const busdTokenAddr = '0x8516Fc284AEEaa0374E66037BD2309349FF728eA';
export const cakeTokenAddr = '0xFa60D973F7642B748046464e165A65B7323b0DEE';
export const lstTok1 = 'btc'
export const lstTok2 = 'usd'
export const projectName = 'DEX_CHROMIA'
export const isProduction = true;

export const whiteList = [
  '0x20cB6fe009479F5eEE7640d179ab958b3A93492E',
  '0xCA9c30daaC90c7b55A36Fc94031f972D8E3CFbD4',
  '0xACC084e04b1fA9E33842B7897753A821581e7795'
]
