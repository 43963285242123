import React from "react";

import './UI.scss';

import Button from "../Button/Button";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
// import ConstructionError from "../../../entities/errors/ConstructionError";


const UI: React.FC = () => {

	// useEffect(()=>{
	// 	throw new ConstructionError();
	// }, [])

	return (
		<div className='UItest'>
			<div className='title'>Buttons</div>
			<div className="testButtons">
				<div>
					<Button>
						Very very long label with short words
					</Button>
					<Button>
						Veeeeeeeeeeeeeeery looooooooooooooong label with long words
					</Button>
					<Button
						className="wide"
					>
						Veeeeeeeeeeeeeeery looooooooooooooong label with long words
					</Button>
					<Button
						className="mediumWide"
					>
						Veeeeeeeeeeeeeeery looooooooooooooong label with long words
					</Button>
				</div>
				<br/>
				<div className="t2">
					<Button
						tabIndex
					>
						+
					</Button>
					<div style={{width: '10px'}}/>
					<Button
						// className="tall"
						tabIndex
					>
						+
					</Button>
					<div style={{width: '10px'}}/>
					<Button
						tabIndex
						blocked
					>
						+
					</Button>
					<div style={{width: '10px'}}/>
					<Button
						tabIndex
						light
					>
						+
					</Button>
					<div style={{width: '10px'}}/>
					<Button
						tabIndex
						light
						blocked
					>
						+
					</Button>
				</div>
				<br/>
				<div className="t2">
					<Button
						className="tall"
					>
						+
					</Button>
					<div style={{paddingLeft: '20px'}}/>
					<Button
						className="wide"
					>
						btn 1
					</Button>
					<div style={{paddingLeft: '20px'}}/>
					<Button
						className="wide tall"
					>
						btn 2
					</Button>
				</div>
			</div>
			<div className="title">Inputs</div>
			<div className="testInputs">
				<div className="flexRow">
					<Input
						type="number"
						value="12345"
						tabIndex
					/>
					<div style={{width: '10px'}}/>
					<Input
						type="number"
						value="12345"
						blocked
						tabIndex
					/>
					<div style={{width: '10px'}}/>
					<Input
						type="number"
						// value="12345"
						invalid
						tabIndex
					/>
				</div>
				<br/>
				<div>
					<Input
						type="date"
						tabIndex
					/>
				</div>
				<br/>
				<div>
					<Input
						type='text'
						tabIndex
					/>
				</div>
			</div>
			<div className="title">Toggles</div>
			<div>
			</div>
			<div className="title">Checkboxes</div>
			<div>
				<Checkbox/>
			</div>
		</div>
	)
}

export default UI;