import { Session } from "@chromia/ft4";
import Web3 from "web3";

import { BigNumber } from "../../entities/BigNumber";
// import { Currency } from "../../entities/Currency";
import { Token } from "../../entities/Token";
import { iChrPool, iChrPoolBalance, TypedGtxClient } from "../../interfaces/chromiaInterfaces";
import { iFactoryContract, iFarmData, iMasterChefContract, iPair, iPairContract, iPancakeContract, iPool, iPoolContract, iPoolPrice, iTokenContract, iV3FactoryContract, iV3PoolNFTRouterContract, iWeb3Provider, tPeriodType, transactionStatus } from "../../interfaces/interfaces";
import { iAccPosition } from "../../components/MyLiqPage/MyLiqPage";
import { iFarm } from "../../components/FarmingPage/FarmingPage";

export enum eAppActionTypes {
	DISCONNECT = 'APP/DISCONNECT',
	SET_CLIENT_ADDR = 'APP/SET_CLIENT_ADDR',
	SET_IS_ACCOUNT = 'APP/SET_IS_ACCOUNT',
	// SET_CONFIG = 'APP/SET_CONFIG',
	SET_API = 'APP/SET_API',
	SET_PS_CONTRACT = 'APP/SET_PS_CONTRACT',
	SET_WEB3_PROVIDER = 'APP/SET_WEB3_PROVIDER',
	SET_WEB3 = 'APP/SET_WEB3',
	// SET_IS_TRANSACTION = 'APP/SET_IS_TRANSACTION',
	// SET_IS_NETWORK_ISSUES = 'APP/SET_IS_NETWORK_ISSUES',
	SET_FACTORY = 'APP/SET_FACTORY',
	SET_V3_FACTORY = 'APP/SET_V3_FACTORY',
	ADD_TOKEN = 'APP/ADD_TOKEN',
	SET_TOKEN_BALANCE = 'APP/SET_TOKEN_BALANCE',
	SET_ALLOWANCE = 'APP/SET_ALLOWANCE',
	// SET_TOKEN_ALLOWANCE = 'APP/SET_TOKEN_ALLOWANCE',
	SET_TOKEN_CONTRACT = 'APP/SET_TOKEN_CONTRACT',
	SET_PAIR = 'APP/SET_PAIR',
	SET_PAIR_BALANCE = 'APP/SET_PAIR_BALANCE',
	// SET_PAIR_ALLOWANCE = 'APP/SET_PAIR_ALLOWANCE',
	SET_PAIR_CONTRACT = 'APP/SET_PAIR_CONTRACT',
	ADD_POOL = 'APP/ADD_POOL',
	SET_POOL_CONTRACT = 'APP/SET_POOL_CONTRACT',
	SET_POOL_PRICE = 'APP/SET_POOL_PRICE',
	CLEAR_BALANCES = 'APP/CLEAR_BALANCES',
	SET_SLIPPAGE_PERCENT = 'APP/SET_SLIPPAGE_PERCENT',
	SET_CHART_PERIOD = 'APP/SET_CHART_PERIOD',
	SET_FARMS = 'APP/SET_FARMS',
	SET_OPENED_FARMS = 'APP/SET_OPENED_FARMS',
	SET_MASTERCHEF_CONTRACT = 'APP/SET_MASTERCHEF_CONTRACT',
	SET_V3_POOL_NFT_ROUTER = 'APP/SET_V3_POOL_NFT_ROUTER',
	SET_IS_REGISTERED = 'APP/SET_IS_REGISTERED',

	SET_GTX_CLIENT = 'APP/SET_GTX_CLIENT',
	SET_SESSION = 'APP/SET_SESSION',
	SET_CHR_CLIENT_ADDR = 'APP/SET_CHR_CLIENT_ADDR',
	SET_CHR_POOLS = 'APP/SET_CHR_POOLS',
	SET_CHR_POOL_BALANCE = 'APP/SET_CHR_POOL_BALANCE',
	SET_SELECTED_POS = 'APP/SET_SELECTED_POS',
	SET_SELECTED_NEW_POS = 'APP/SET_SELECTED_NEW_POS',
	SET_ACCESS_TOKEN = 'APP/SET_ACCESS_TOKEN',
	SET_TRANSACTION_STATUS = 'APP/SET_TRANSACTION_STATUS'
}

export type tAppAction =
	iDisconnect |
	iSetClientAddr |
	iSetApi |
	iSetWeb3Provider |
	iSetWeb3 |
	// iSetConfig |
	iSetPSContract |
	// setIsTransaction |
	// setIsNetworkIssues
	iSetFactory |
	iSetV3Factory |
	iAddToken |
	iSetTokenBalance |
	iSetAllowance |
	// iSetTokenAllowance |
	iSetTokenContract |
	iSetPair |
	iSetPairBalance |
	// iSetPairAllowance |
	iSetPairContract |
	iAddPool |
	iSetPoolContract |
	iSetPoolPrice |
	iClearBalances |
	iSetSlippagePercent |
    iSetChartPeriod |
	iSetMasterChefContract |
	iSetFarms |
	iSetOpenedFarms |
	iSetV3PoolNFTRouter |
	iSetIsRegistered |
	iSetAccessToken |
	iSetGtxClient |
	iSetSession |
	iSetChrClientAddr |
	iSetChrPools |
	iSetChrPoolBalance |
	iSetIsAccount |
	iSetSelectedPos |
	iSetTransactionStatus | 
	iSetSelectedNewPos
;

interface iSetTransactionStatus {
	type: eAppActionTypes.SET_TRANSACTION_STATUS,
	payload: transactionStatus | null
}


interface iSetIsRegistered {
	type: eAppActionTypes.SET_IS_REGISTERED,
	payload: boolean
}

interface iSetSelectedPos {
	type: eAppActionTypes.SET_SELECTED_POS,
	payload: iAccPosition | null
}

interface iSetAccessToken {
	type: eAppActionTypes.SET_ACCESS_TOKEN,
	payload: string
}

interface iSetSelectedNewPos {
	type: eAppActionTypes.SET_SELECTED_NEW_POS,
	payload: iFarm | null
}

interface iSetIsAccount {
	type: eAppActionTypes.SET_IS_ACCOUNT,
	payload: boolean
}

interface iDisconnect {
	type: eAppActionTypes.DISCONNECT
}

interface iSetClientAddr {
	type: eAppActionTypes.SET_CLIENT_ADDR,
	payload: string | null;
}

interface iSetApi {
	type: eAppActionTypes.SET_API,
	payload: string
}

interface iSetWeb3Provider {
	type: eAppActionTypes.SET_WEB3_PROVIDER,
	payload: iWeb3Provider
}

interface iSetWeb3 {
	type: eAppActionTypes.SET_WEB3,
	payload: Web3
}

interface iSetPSContract {
	type: eAppActionTypes.SET_PS_CONTRACT,
	payload: iPancakeContract
}

/*
interface iSetConfig {
	type: eAppActionTypes.SET_CONFIG,
	payload: iConfig
}

interface setIsTransaction {
	type: eAppActionTypes.SET_IS_TRANSACTION,
	payload: boolean
}

interface setIsNetworkIssues {
	type: eAppActionTypes.SET_IS_NETWORK_ISSUES,
}*/

interface iSetFactory {
	type: eAppActionTypes.SET_FACTORY,
	payload: iFactoryContract
}

interface iSetV3Factory {
	type: eAppActionTypes.SET_V3_FACTORY,
	payload: iV3FactoryContract
}

interface iSetFarms {
	type: eAppActionTypes.SET_FARMS,
	payload: iFarmData[]
}

interface iSetOpenedFarms {
	type: eAppActionTypes.SET_OPENED_FARMS,
	payload: number[]
}

interface iAddToken {
	type: eAppActionTypes.ADD_TOKEN,
	payload: Token
}

interface iSetTokenBalance {
	type: eAppActionTypes.SET_TOKEN_BALANCE,
	payload: {
		address: string,
		balance: BigNumber,
	}
}

interface iSetAllowance {
	type: eAppActionTypes.SET_ALLOWANCE,
	payload: {
		address: string,
		allowance: BigNumber
	}
}

/* interface iSetTokenAllowance {
	type: eAppActionTypes.SET_TOKEN_ALLOWANCE,
	payload: {
		address: string,
		allowance: BigNumber,
	}
} */

interface iSetTokenContract {
	type: eAppActionTypes.SET_TOKEN_CONTRACT,
	payload: {
		address: string,
		contract: iTokenContract,
	}
}

interface iSetPair {
	type: eAppActionTypes.SET_PAIR,
	payload: iPair
}

interface iSetPairBalance {
	type: eAppActionTypes.SET_PAIR_BALANCE,
	payload: {
		address: string,
		balance: BigNumber,
	}
}

/* interface iSetPairAllowance {
	type: eAppActionTypes.SET_PAIR_ALLOWANCE,
	payload: {
		address: string,
		allowance: BigNumber,
	}
} */

interface iSetPairContract {
	type: eAppActionTypes.SET_PAIR_CONTRACT,
	payload: {
		address: string,
		contract: iPairContract,
	}
}

interface iAddPool {
	type: eAppActionTypes.ADD_POOL,
	payload: iPool
}

interface iSetPoolContract {
	type: eAppActionTypes.SET_POOL_CONTRACT,
	payload: {
		address: string,
		contract: iPoolContract
	}
}

interface iSetPoolPrice {
	type: eAppActionTypes.SET_POOL_PRICE,
	payload: iPoolPrice
}

interface iClearBalances {
	type: eAppActionTypes.CLEAR_BALANCES
}

interface iSetSlippagePercent {
	type: eAppActionTypes.SET_SLIPPAGE_PERCENT,
	payload: string
}

interface iSetChartPeriod {
    type: eAppActionTypes.SET_CHART_PERIOD,
    payload: tPeriodType
}

interface iSetMasterChefContract {
	type: eAppActionTypes.SET_MASTERCHEF_CONTRACT,
	payload: iMasterChefContract
}

interface iSetV3PoolNFTRouter {
	type: eAppActionTypes.SET_V3_POOL_NFT_ROUTER,
	payload: {
		chainId: number,
		contract: iV3PoolNFTRouterContract,
	}
}

interface iSetGtxClient {
	type: eAppActionTypes.SET_GTX_CLIENT,
	payload: TypedGtxClient
}

interface iSetSession {
	type: eAppActionTypes.SET_SESSION,
	payload: Session | null
}

interface iSetChrClientAddr {
	type: eAppActionTypes.SET_CHR_CLIENT_ADDR,
	payload: string | null
}

interface iSetChrPools {
	type: eAppActionTypes.SET_CHR_POOLS,
	payload: iChrPool[]
}

interface iSetChrPoolBalance {
	type: eAppActionTypes.SET_CHR_POOL_BALANCE,
	payload: {
		address: string,
		balance: iChrPoolBalance,
	}
}