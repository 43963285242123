import React from "react";

import './Footer.scss';

import twitterIcon from '../../assets/images/twitterIcon.svg';
// import gitIcon from '../../assets/images/gitIcon.svg';
import telegram from '../../assets/images/telegram.svg';
import discord from '../../assets/images/discord.svg';


const Footer: React.FC = () => {
 

	return (
    <div className="footer">
      <a href="https://twitter.com/SlurpySwapx" target="_blank" rel="noreferrer">
        <img src={twitterIcon} alt="twitterIcon"></img>
      </a>
      <a href="https://discord.gg/kDN8qGrePB" target="_blank" rel="noreferrer">
        <img src={discord} alt="gitIcon"></img>
      </a>
      <a href="https://t.me/Slurpyswapchat" target="_blank" rel="noreferrer">
        <img src={telegram} alt="gitIcon"></img>
      </a>
    </div>
	)
}

export default Footer;