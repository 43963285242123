import React, { useCallback, useState } from "react";

import { iClaimModal } from "../../../interfaces/interfaces";
import { useStoreDispatch, useStoreSelector } from "../../../store/store";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import closeIcon from '../../../assets/images/closeIcon.svg';

import './ClaimFeeModal.scss';
import { getTokenIconURL, logError, toFixed } from "../../../utils/helpers";
import Button from "../../UI/Button/Button";
import { getChrPools, getGtxClient, updateChromiaBalances } from "../../../utils/Chromia/chromiaHelpers";
import { op } from "@chromia/ft4";


const ClaimFeeModal: React.FC <iClaimModal> = ({modalId, name: modalName, title, position}) => {
  const { session } = useStoreSelector(store => store.app);

	const { closeModal, openNewModal, setTransactionStatus } = useStoreDispatch();

	const [isClosing, setIsClosing] = useState(false);

  function closeInfoModal(){
		setIsClosing(true);
		setTimeout(()=>{
			closeModal(modalName, modalId);
		}, 490);
	}

  const collectFee = useCallback(async () => {
		if (!session) {
			throw new Error();
		}

    try {
      setTransactionStatus('progress');
      const tx = session?.call(op(
        'slurpyswap.Collect', 
        position.pool, 
        position.tick_lower, 
        position.tick_upper
      ));
  
      await tx;
      setTransactionStatus('succes');
      openNewModal({
				name: 'transaction'
			})
  
      if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
        const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
        await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
      }
  
      updateChromiaBalances();
      getChrPools();	
      closeInfoModal();
      // openNewModal({
      //   name: 'info',
      //   message: `You successfully claimed fees: ${position.fee_0} ${position.token0.name} and ${position.fee_1} ${position.token1.name}`
      // })
      // getPositions();
    }
    catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
      else {
				setTransactionStatus(null);
			}
    }
	},
	[position, openNewModal, session])


	return (
		<ModalWrapper
			className='ClaimFeeModal'
			isModalClosing={isClosing}
			closeListenerSettings={{name: modalName, modalId, callback: () => closeInfoModal}}
		>
      <div className="claimModal_header">
        <div className="title">
          {title || 'title'}
        </div>
        <img src={closeIcon} alt="closeIcon" onClick={closeInfoModal} className="closeIcon"/>
      </div>

      <div className="fee_wrapper">
        <div className="fee">
          <div className="token">
            <img src={getTokenIconURL(position.token0.name) || ''} className="tokenIcon" alt="token0Icon" />
            {position.token0.name}
          </div>
          <div className="feeValue">
            {toFixed(position.fee_0, 10)}
          </div>
        </div>
        <div className="fee">
          <div className="token">
            <img src={getTokenIconURL(position.token1.name) || ''} className="tokenIcon" alt="token1Icon" />
            {position.token1.name}
          </div>
          <div className="feeValue">
            {toFixed(position.fee_1, 10)}
          </div>
        </div>
      </div>

      <div className="warning">
        Collecting fees will withdraw currently available fees for you
      </div>

      <div className="collectButton_wrapper">
        <Button
          action={collectFee}
        >
          Collect
        </Button>

      </div>

		</ModalWrapper>
	)
}

export default ClaimFeeModal;