import { createKeyStoreInteractor, createWeb3ProviderEvmKeyStore } from "@chromia/ft4";
import { IClient } from "postchain-client";
import { FC, useEffect } from "react";
import { useAccount } from "wagmi";

import { useStoreDispatch, useStoreSelector } from "../../../store/store";
import { getGtxClient } from "../../../utils/Chromia/chromiaHelpers";
import { connectKAS } from "../../../utils/helpers";


const WagmiWrapper: FC = () => {
	
	const { session, isAccount, isRegistered } = useStoreSelector( store => store.app );
	const { setSession, setChrClientAddr, setIsIniting, setIsAccount } = useStoreDispatch();
	const { connector, address: userAddress } = useAccount();

	useEffect(() => {
		if (connector && !session){
			getSession();
		}
		else {
			setIsIniting(false);
		}
		// eslint-disable-next-line
	}, [connector, session, isRegistered])

	useEffect(() => {
		if (connector){
			getSession();
		}
		// eslint-disable-next-line
	}, [connector, userAddress, isAccount])
	
	const getSession = async () => {
		if (!connector?.getProvider) return 

		const provider = await (connector?.getProvider());
		
		const gtx = await getGtxClient();

		if (!provider) throw new Error('missing provider');

		const interactor = await createWeb3ProviderEvmKeyStore(provider as any).then((keyStore) => { return createKeyStoreInteractor(gtx as IClient, keyStore) })

		const accs = await interactor.getAccounts();

		if (!accs[0]) {
			setChrClientAddr(null);
			setIsAccount(false);
			setSession(null)

			return;
		}

		const session = await interactor.getSession(accs[0].id);
		setChrClientAddr(accs[0].id.toString('hex'));
		setIsAccount(true);
		setSession(session);
		connectKAS();
	}


	return null;
}

export default WagmiWrapper;