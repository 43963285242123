import { useEffect, useState } from "react";

import { BigNumber } from "../entities/BigNumber";
import { Currency } from "../entities/Currency";
import { useStoreSelector } from "../store/store";
import { getBalance } from "../utils/observers";


const useTokenBalance = (token: Currency | undefined): BigNumber | undefined => {
    const [tokenBalance, setTokenBalance] = useState<BigNumber | undefined>();
    const { tokenBalances, clientAddr } = useStoreSelector(store => store.app);

	useEffect(()=>{
        (async () => {
			if (token) {
				try {
					const newBalance = await getBalance({token: token});
					if (newBalance.valueStr !== tokenBalance?.valueStr) {
						setTokenBalance(newBalance);
					}
				} catch (error) {
                    setTokenBalance(undefined);
				}
			} else {
                setTokenBalance(undefined);
			}
		})()
		// eslint-disable-next-line
	}, [token, tokenBalances, clientAddr])

    
    return tokenBalance
}

export default useTokenBalance;