import React, { useCallback, useEffect, useMemo, useState } from "react";

import { iChrFarm, iChrPool } from "../../interfaces/chromiaInterfaces";
import { useStoreDispatch, useStoreSelector } from "../../store/store";
import { getGtxClient } from "../../utils/Chromia/chromiaHelpers";
import { getTokenIconURL, numberWithSpaces, roundSignDigit, toFixed } from "../../utils/helpers";
import { sqrtPriceToPrice } from "../../utils/Chromia/v3";
import { tStatus } from "../../interfaces/interfaces";
import { iAccPosition } from "../MyLiqPage/MyLiqPage";
import { Token } from "../../entities/Token";

import WalletAddress from "../WalletAddress/WalletAddress";
import StarBackground from "../StarBackground/StarBackground";
import Footer from "../Footer/Footer";
import MenuPages from "../MenuPages/MenuPages";
import PixelBorder from "../UI/PixelBorder/PixelBorder";
import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";
import LiquidityPageV2 from "../LiquidityPageV2/LiquidityPageV2";
import PositionPage from "../PositionPage/PositionPage";
import MyFarms from "../MyFarms/MyFarms";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import BigNumber from "bignumber.js";
import noFarmsIcon from '../../assets/images/noLiq.svg';

import './FarmingPage.scss'


export interface iFarm extends iChrFarm {
  farmPool: iChrPool | undefined,
  tok0: Token,
  tok1: Token,
  farmTok: Token,
  lpPos: iAccPosition[],
  farmPos: iFarmPos[],
  tvl: string,
  apr: string,
  chrReward: string,
  selectedPool: iChrPool
}

export interface iFarmPos extends iAccPosition{
  farm: iFarm
  last_update_time: number
  reward: string,
  reward_growth_total: number,
  minPrice: BigNumber,
  maxPrice: BigNumber,
  currentPrice: BigNumber,
  status: tStatus,
  token0: Token,
  token1: Token,
  farmTok: Token
}

export interface iChrFarmPos {
  farm: iFarm
  last_reward_growth_inside: string
  position: iAccPosition,
  reward: number
}

const FarmingPage: React.FC = () => {
  const { chrPools, tokens, chrClientAddr, selectedNewPos } = useStoreSelector(store => store.app);
  const [allFarms, setAllFarms] = useState<iFarm[] | null>(null);
  const [searchFarms, setSearchFarms] = useState<iFarm[]>();
  const [searchFarmInput, setSearchFarmInput] = useState<string>('');
  const [isNewPosition, setIsNewPosition] = useState<boolean>();
  const [selectedMyFarmPosition, setSelectedMyFarmPosition] = useState<iAccPosition>();
  const [isMyFarmOpen, setIsMyFarmOpen] = useState<boolean>(false);
  
  const { openNewModal, setSelectedNewPos } = useStoreDispatch();

  const getFarms = useCallback(async () => {
    if (!chrClientAddr) return 
    const farmsArray = await (await getGtxClient()).query('slurpyswap.GetFarms', {});
    const gtxClient = await getGtxClient();

    const myFarms = await gtxClient.query('slurpyswap.GetFarmingPositionsByOwner', {owner_id: chrClientAddr});

    const usdToken = tokens.find(token => token.name === 'USDT');
    const chrToken = tokens.find(token => token.name === 'CHR');

    const chrPool = chrPools.find((chrPool: iChrPool) => {
      return (chrPool.token0 === usdToken?.address && chrPool.token1 === chrToken?.address) || 
        (chrPool.token1 === usdToken?.address && chrPool.token0 === chrToken?.address)
      }
    )

    if (!chrPool) throw new Error('no chr-usdt pool');
    const chrPoolPrice = sqrtPriceToPrice(chrPool?.sqrt_price);
    const chrPerUsdt = chrPool.token0 === chrToken?.address ? new BigNumber(1).div(chrPoolPrice) : chrPoolPrice;

    if (!usdToken) throw new Error('no usd token')

    const tvls = await Promise.all(
      farmsArray.map((farm) => {
        return gtxClient.query('slurpyswap.GetTVL', {
          pool_id: farm.pool, 
          in_token: Buffer.from(usdToken?.address, 'hex')
        });
      })
    )

    const aprsRes = await Promise.allSettled(
      farmsArray.map((farm) => {
        return gtxClient.query('slurpyswap.GetFarmAPR', {
          farm_id: farm.acc
        });
      })
    )

    const aprs = aprsRes.map((res) => {
      return res.status ==='fulfilled' 
        ? res.value.apr
        : '0'
    })

    let positionsWithPools: any = [];

		const chrPositions = await (await getGtxClient()).query('slurpyswap.GetPositionsByAcc', {acc: chrClientAddr});

    chrPositions.forEach((position, index) => {
			let token0;
			let token1;

			const selectedPool = chrPools.find(pool => { 
				return pool.id === position.pool.toString('hex')
			});

			if (!selectedPool) return null

			tokens.forEach((token) => {
				if (selectedPool.token0 === token.address) {
					token0 = token
				}
	
				if (selectedPool.token1 === token.address) {
					token1 = token
				}
			})

			const minPrice = new BigNumber(position.sqrt_price_lower).div((new BigNumber(2)).pow(96)).pow(2);
			const maxPrice = new BigNumber(position.sqrt_price_upper).div((new BigNumber(2)).pow(96)).pow(2);
      const currentPrice = new BigNumber(selectedPool.sqrt_price).div((new BigNumber(2)).pow(96)).pow(2);
      const status: tStatus = minPrice.gt(currentPrice) || maxPrice.lt(currentPrice) 
          ? 'Out of range'
        : 'In range'

			positionsWithPools.push({...position, id: index, token0: token0, token1, minPrice, maxPrice, selectedPool, status})
		})

    let farmsArrayFormatted: any = [];
    farmsArray.forEach((farmObj, index) => {
      const farmPool = chrPools.find((pool) => pool.id === farmObj.pool.toString('hex'));

      if (farmPool) {
        let tok0, tok1, farmTok;
        tokens.forEach((token) => {
          if (farmPool.token0 === token.address) {
            tok0 = token
          }
    
          if (farmPool.token1 === token.address) {
            tok1 = token
          }

          if (token.address === farmObj.farm_tok.toString('hex')) {
            farmTok = token
          }
        })
        
        const myFarmsFiltered = myFarms?.filter((farm) => farm.farm.acc.toString('hex') === farmObj.acc.toString('hex'))

        const farmPos = myFarmsFiltered.map((farm) => {
          let token0, token1;

          const selectedPool = chrPools.find(pool => { 
            return pool.id === farm.farm.pool.toString('hex')
          });
    
          if (!selectedPool) return null
    
          tokens.forEach((token) => {
            if (selectedPool.token0 === token.address) {
              token0 = token
            }
      
            if (selectedPool.token1 === token.address) {
              token1 = token
            }
          })

          const farmTok = tokens.find((tok: Token) => tok.address.toLocaleLowerCase() === farm.farm.farm_tok.toString('hex').toLocaleLowerCase());
    
          const minPrice = new BigNumber(farm.position.sqrt_price_lower).div((new BigNumber(2)).pow(96)).pow(2);
          const maxPrice = new BigNumber(farm.position.sqrt_price_upper).div((new BigNumber(2)).pow(96)).pow(2);
          const currentPrice = new BigNumber(selectedPool.sqrt_price).div((new BigNumber(2)).pow(96)).pow(2);
          const status: tStatus = minPrice.gt(currentPrice) || maxPrice.lt(currentPrice) 
              ? 'Out of range'
            : 'In range'


          const positionFarm = farmObj;
          
          return {...farm.position, farm: farm.farm, reward: farm.reward, farmTok, currentPrice, status, token0, token1, selectedPool, minPrice, maxPrice, positionFarm}
        })

        const lpPos: iAccPosition = positionsWithPools.filter((pos: iAccPosition) => {
          const liquidityAvailable = new BigNumber(pos.liquidity).sub(new BigNumber(pos.amount_locked_in_farm));
          // return pos.selectedPool.id === farmObj.pool.toString('hex') && +pos.amount_locked_in_farm <= 0 && pos.status === 'In range';
          return pos.selectedPool.id === farmObj.pool.toString('hex') && !liquidityAvailable.isZero();
        })

        const tvl = tvls[index];
        const farmApr = aprs[index];
        const apr = +farmApr > 0 
          ? roundSignDigit(new BigNumber(farmApr).mul(new BigNumber(100).mul(new BigNumber(365* 86400))).toFixed(), 3) 
          : farmApr;

        const selectedPool = chrPools.find(chrPool => chrPool.id.toLocaleLowerCase() === farmObj.pool.toString('hex').toLocaleLowerCase())

        if (!selectedPool) throw new Error('no farm pool');

        const usdtPerLiq = new BigNumber(tvls[index]).div(new BigNumber(selectedPool?.liquidity));
        const usdtDaily = new BigNumber((+farmObj.current_rate * 60 * 60 * 24).toString()).mul(chrPerUsdt)
        const chrReward = +farmObj.current_farming_liquidity > 0 ? usdtDaily.div(new BigNumber(toFixed(+farmObj.current_farming_liquidity + 1, 18)).mul(usdtPerLiq)).toFixed() : '0'

        farmsArrayFormatted.push({
          ...farmObj,
          farmPool,
          tok0,
          tok1,
          farmTok,
          farmPos,
          lpPos,
          tvl,
          apr,
          selectedPool,
          chrReward
        })
      }
    })
    setAllFarms(farmsArrayFormatted);
  }, [chrPools, tokens, chrClientAddr])

  useEffect(() => {
    if (selectedMyFarmPosition && allFarms) {
      let positions:iFarmPos[] = [];
      allFarms?.forEach((farm) => { positions = positions.concat(farm.farmPos)});
      const curPos = positions.find((pos: iFarmPos) => {
        return pos.tick_lower === selectedMyFarmPosition.tick_lower && pos.tick_upper === selectedMyFarmPosition.tick_upper 
        && pos.token0 === selectedMyFarmPosition.token0 && pos.token1 === selectedMyFarmPosition.token1
      })
      setSelectedMyFarmPosition(curPos ? curPos : undefined)
    }
  }, [allFarms])
  
  useEffect(() => {
    getFarms();

    const tickInterval = setInterval(() => {
      getFarms();
		}, 60000)

		return () => {
			clearInterval(tickInterval);
		}
  }, [getFarms])

  const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])
  
  const searchFarmHandler = (farmName: string) => {
    setSearchFarmInput(farmName);

    const foundFarms = farms?.filter((farm) => farm.tok0.name.toLocaleLowerCase().includes(farmName.toLocaleLowerCase()) || farm.tok1.name.toLocaleLowerCase().includes(farmName.toLocaleLowerCase()));
    if (foundFarms) {
      setSearchFarms(foundFarms);
    }
  }

  const farms = useMemo(() => {
    return searchFarmInput.length > 0 && searchFarms ? searchFarms : allFarms
  }, [searchFarmInput, allFarms, searchFarms])


  if (selectedMyFarmPosition) return <PositionPage positions={allFarms} position={selectedMyFarmPosition} getPositions={getFarms} setSelectedPosition={setSelectedMyFarmPosition} isFarming={true}/>
  if (selectedNewPos) return <LiquidityPageV2 setIsNewPosition={setIsNewPosition} getPositions={getFarms} ATokenProps={selectedNewPos.tok0} BTokenProps={selectedNewPos.tok1} feeProps={+selectedNewPos.selectedPool.trading_fees * 100}/>
	return (
    <StarBackground>
      <MenuPages/>
      <div className='FarmingPage'>
          <div className="farmingBlock">
            <PixelBorder>
              <div className="farmingBlockInner">
                <div className="title"> 
                  Farming 
                </div>
                <WalletAddress/>
                {/* <div className="rewardsBlock">
                  <BlockPixelBorder>
                    <div className="rewardsBlockInner">
                      <div className="rewards">
                        <div className="title">
                          My rewards
                        </div>
                        <div className="value">
                          0$
                        </div>
                      </div>
                      <div className="claimButton_wrapper">
                        <button className="claimButton">
                          Claim All
                        </button>
                      </div>
                    </div>
                  </BlockPixelBorder>
                </div> */}

                <div className="farmTable_header">
                  <div className="farmsButtons">
                    <button className={`farmsButton ${!isMyFarmOpen ? 'farmsButton_active' : ''}`}  onClick={() => setIsMyFarmOpen(false)}>
                      Farms
                    </button>
                    <button className={`farmsButton ${isMyFarmOpen ? 'farmsButton_active' : ''}`} onClick={() => setIsMyFarmOpen(true)}>
                      My Farms
                    </button>
                  </div>
                  {!isMyFarmOpen &&
                    <Input
                      className="searchInput"
                      value={searchFarmInput}
                      setValue={searchFarmHandler}
                      searchIcon={true}
                      placeholder="Search"
                    />
                  }
                </div>

                {isMyFarmOpen 
                  ? 
                  <MyFarms allFarms={allFarms} setIsMyFarmOpen={setIsMyFarmOpen} setSelectedMyFarmPosition={setSelectedMyFarmPosition} getFarms={getFarms}/>
                  :
                  isMobile ? 
                  <div className="farmTable_wrapper">
                    {!allFarms
                      ? <div className="noFarms">
                          <img src={noFarmsIcon} alt="noFarms"/>
                          <div className="noFarms_title">
                            Farms will appear here.
                          </div>
                        </div>
                      : farms?.map((farm) => {
                          return <div className="farmItemInner farmItemInnerMobile">
                            <BlockPixelBorder
                              topBorderHeight="4px"
                              bottomBorderHeight="4px"
                            >
                              <div className="farmItem">
                                <div className="farmItemMobile__header">
                                  <div className="rowMobile" key={farm.pool.toString('hex')}>
                                    <img src={getTokenIconURL(farm.tok1.name) || ''} className='tokIcon' alt="tok1Icon"/> 
                                    <img src={getTokenIconURL(farm.tok0.name) || ''} className='tokIcon tokIcon2' alt="tok2Icon"/> 
                                    {farm.tok1.name}/{farm.tok0.name} 
                                  </div>
                                  <div className="rowMobile" key={farm.pool.toString('hex')}>
                                    ${numberWithSpaces(Math.floor(+farm.tvl).toString())}
                                  </div>
                                </div>
                                <div className="rowMobile">
                                  <span className="fee">{(+farm.selectedPool.trading_fees || 0) * 100}%</span>
                                </div>
                                <div className="rowMobile">
                                  <span className="rewardValue">
                                    APR: {+farm.apr > 0 ? toFixed(new BigNumber(farm.apr).toFixed(), 1) : farm.apr}% 
                                  </span> 
                                </div>
                                <div>
                                  <span className="rewardValue">
                                    Reward token: {farm.farmTok.name}
                                  </span> 
                                </div>
                                <div className="rowMobile">
                                  <span className="rewardValue">
                                    Available: {farm.lpPos.filter(lp => lp.status === 'In range').length} LP
                                  </span> 
                                </div>
                                <div className="rowMobile rowMobile__flex">
                                  <span>
                                    Staked: {farm.farmPos.length} LP
                                  </span> 
                                  <div className="row" key={farm.pool.toString('hex')}>
                                      <Button 
                                        light={true}
                                        small={true}
                                        action={() => {
                                            openNewModal({
                                              name: 'farmModal',
                                              farmPos: farm.farmPos,
                                              lpPos: farm.lpPos,
                                              currFarm: farm,
                                              updateFarms: getFarms
                                            })
                                        }}
                                      >
                                        View
                                      </Button>

                                  </div>
                                </div>
                              </div>
                            </BlockPixelBorder>
                          </div>
                      })
                    }
                  </div>
                  :
                    <div className="farmTable_wrapper">
                      <BlockPixelBorder
                        className="farmTable"
                        topBorderHeight="8px"
                        bottomBorderHeight="8px"
                      > 
                        {!allFarms 
                          ?
                          <div className="farmTableInner__empty">
                            <div className="header">
                              <div className="column  row__pool">
                                <div className="row column_header">
                                  Pool 
                                </div>
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Pool TVL
                                </div>
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Farm APR
                                </div>
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Reward Token
                                </div>
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Available
                                </div>
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Staked
                                </div>
                              </div>


                              <div className="column">
                                <div className="row column_header"> 
                                </div>
                              </div>
                            </div>
                            <div className="noFarms">
                              <img src={noFarmsIcon} alt="noFarms"/>
                              <div className="noFarms_title">
                                Farms will appear here.
                              </div>
                            </div>
                          </div>
                          : 
                            <div className="farmTableInner">
                              <div className="column  row__pool">
                                <div className="row column_header">
                                  Pool 
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                      <img src={getTokenIconURL(farm.tok1.name) || ''} className='tokIcon' alt="tok1Icon"/> 
                                      <img src={getTokenIconURL(farm.tok0.name) || ''} className='tokIcon tokIcon2' alt="tok2Icon"/> 
                                      {farm.tok1.name}/{farm.tok0.name} 
                                      <span className="fee">{(+farm.selectedPool.trading_fees || 0) * 100}%</span>
                                    </div>
                                })}
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Pool TVL
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                    ${numberWithSpaces(Math.floor(+farm.tvl).toString())}
                                      {/* {toFixed(+farm.current_farming_liquidity, 2)} {farm.farmTok.name} */}
                                  </div>
                                })}
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Farm APR
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                    <span className="rewardValue">{+farm.apr > 0 ? toFixed(new BigNumber(farm.apr).toFixed(), 1) : farm.apr}% </span> 
                                  </div>
                                })}
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Reward Token
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                    {farm.farmTok.name}
                                  </div>
                                })}
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Available
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                    <span>{farm.lpPos.filter(lp => lp.status === 'In range').length} LP</span> 
                                  </div>
                                })}
                              </div>

                              <div className="column">
                                <div className="row column_header">
                                  Staked
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                    <span>{farm.farmPos.length} LP</span> 
                                  </div>
                                })}
                              </div>


                              <div className="column">
                                <div className="row column_header"> 
                                </div>
                                {farms?.map((farm) => {
                                  return <div className="row" key={farm.pool.toString('hex')}>
                                      <Button 
                                        light={true}
                                        small={true}
                                        action={() => {
                                            openNewModal({
                                              name: 'farmModal',
                                              farmPos: farm.farmPos,
                                              lpPos: farm.lpPos,
                                              currFarm: farm,
                                              updateFarms: getFarms
                                            })
                                        }}
                                      >
                                        View
                                      </Button>

                                    </div>
                                })}
                              
                              </div>

                            </div>
                        }
                      </BlockPixelBorder>
                    </div>
                }
 
              </div>
            </PixelBorder>
          </div>
        <Footer/>
      </div>
    </StarBackground>
	)
}

export default FarmingPage;