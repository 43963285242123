import React, { useCallback, useEffect, useState } from "react";
import { op } from "@chromia/ft4";

import { useStoreDispatch, useStoreSelector } from "../../store/store";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";

import StarBackground from "../StarBackground/StarBackground";
import { sqrtPriceToPrice, sqrtPriceToTick } from "../../utils/Chromia/v3";
import { toFixed } from "../../utils/helpers";
import { iFarm } from "../FarmingPage/FarmingPage";
import { getChrPools, getGtxClient } from "../../utils/Chromia/chromiaHelpers";

import './SettingsPage.scss'


export const TICK_SPACINGS:any= {
  1: 1,
  5: 10,
  25: 50,
  100: 200,
}

const SettingsPage: React.FC = () => {
  const { chrPoolBalances, chrPools, session, chrClientAddr, tokens } = useStoreSelector(store => store.app);
  const { openNewModal, setTransactionStatus } = useStoreDispatch();
  const [token0, setToken0] = useState<string>();
  const [token1, setToken1] = useState<string>();
  const [farms, setFarms] = useState<iFarm[]>([]);
  const [pools, setPools] = useState<any>();
  const [tradingFee, setTradingFee] = useState<string>('');
  const [currPriceTick, setCurrPriceTick] = useState<string>('');
  const [farmPoolAddr, setFarmPoolAddr] = useState<string>();
  const [farmPoolTok, setFarmPoolTok] = useState<string>();
  const [incomePerSec, setIncomePerSec] = useState<string>();
  const [farmPercent, setFarmPercent] = useState<string>();
  const [farmId, setFarmId] = useState<string>();
  const [farmIdCloseTime, setFarmIdCloseTime] = useState<string>();
  const [farmCloseTime, setFarmCloseTime] = useState<string>();
  const [farmAddAmt, setFarmAddAmt] = useState<string>();
  const [farmSubAmt, setFarmSubAmt] = useState<string>();
  const [farmAddBalId, setFarmAddBalId] = useState<string>();
  const [farmSubBalId, setFarmSubBalId] = useState<string>();
  const [curTime, setCurTime] = useState<number>();
  const [time, setTime] = useState<string>();
  const [owner, setOwner] = useState<string>();
  const [itemCreator, setItemCreator] = useState<string>();
  const [currentItemCreator, setCurrentItemCreator] = useState<string>();
  const [currentOwner, setCurrentOwner] = useState<string>();


	useEffect(()=> {    
    try {
      // updateLSTPrices();
    }
    catch(err) {
      console.log(err)
    }
    //eslint-disable-next-line
    getPools();
    getFarms();
    getData();
	}, [chrPools])

  async function createPool() {
    if (!token0 || !token1) {
        return
    }

    const tickPrice = Math.floor(sqrtPriceToTick(currPriceTick));
    try {

      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.CreatePool',
        Buffer.from(token0, 'hex'),
        Buffer.from(token1, 'hex'),
        tradingFee,
        TICK_SPACINGS[+tradingFee * 10000],
        +tickPrice
      ))

      await tx;
      setTransactionStatus('succes');

      // openNewModal({
      //   name: 'info',
      //   title: 'Success',
      //   message: 'You created the pool'
      // });
      getChrPools();
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  async function createFarm() {
    if (!farmPoolAddr || !farmPoolTok || !incomePerSec) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.CreateFarm',
        Buffer.from(farmPoolAddr, 'hex'),
        Buffer.from(farmPoolTok, 'hex'),
        incomePerSec
      ))

      await tx;
      setTransactionStatus('succes');

      // openNewModal({
      //   name: 'info',
      //   title: 'Success',
      //   message: 'You created the farm'
      // });
    }
    catch(error: any) {
        throw new Error(error);
    }
  }

  const getFarms = useCallback( async () => {
    const gtxClient = await getGtxClient();
    setCurTime(await gtxClient.query('slurpyswap.GetTime', {}));
    const farmsArray = await gtxClient.query('slurpyswap.GetFarms', {});

    let farmsArrayFormatted: any = [];
    farmsArray.forEach((farmObj, index) => {
      const farmPool = chrPools.find((pool) => pool.id === farmObj.pool.toString('hex'));

      if (farmPool) {
        let tok0, tok1, farmTok;
        tokens.forEach((token) => {
          if (farmPool.token0 === token.address) {
            tok0 = token
          }
    
          if (farmPool.token1 === token.address) {
            tok1 = token
          }

          if (token.address === farmObj.farm_tok.toString('hex')) {
            farmTok = token.address
          }
        })
        
        farmsArrayFormatted.push({
          ...farmObj,
          farmPool,
          tok0,
          tok1,
          farmTok,
          farmPos: [],
          lpPos: []
        })
      }
    })
    setFarms(farmsArrayFormatted);
  },[chrPools, tokens])

  function getPools() {
    const pools = chrPools.map((pool) => {
      let token0;
      let token1;

      tokens.forEach((token) => {
        if (pool.token0 === token.address) {
          token0 = token.name
        }
  
        if (pool.token1 === token.address) {
          token1 = token.name
        }
      })

      return {
        ...pool,
        t0Name: token0,
        t1Name: token1
      }
    })

    setPools(pools);
  }

  async function getData() {
    const gtxClient = await getGtxClient();
    // const hObs = (await gtxClient.query('slurpyswap.GetHObs', {pool_id: '7780e323652e72ff4497eeee93a9b9b15b82b03bbbf21d3e7f48f12c551a23dc', obs_beg: 0, obs_count: 100}))
    
    setCurrentOwner((await gtxClient.query('slurpyswap.GetOwner', {})).toString('hex'));
    setCurrentItemCreator((await gtxClient.query('GetItemCreator', {})).toString('hex'));
  }

  async function setFarmPercentFunc() {
    if (!farmId || !farmPercent) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.SetFarmRate',
        Buffer.from(farmId, 'hex'),
        farmPercent
      ))

      await tx;
      setTransactionStatus('succes');

      // openNewModal({
      //   name: 'info',
      //   title: 'Success',
      //   message: 'You set the farm perdent'
      // });
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  async function setFarmCloseTimeFunc() {
    if (!farmIdCloseTime || !farmCloseTime) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.SetFarmCloseTime',
        Buffer.from(farmIdCloseTime, 'hex'),
        +farmCloseTime
      ))

      await tx;
      setTransactionStatus('succes');

      // openNewModal({
      //   name: 'info',
      //   title: 'Success',
      //   message: 'You set farm close time'
      // });
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  async function addFarmBal() {
    if (!farmAddBalId || !farmAddAmt) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.AddFarmBalance',
        Buffer.from(farmAddBalId, 'hex'),
        farmAddAmt
      ))
      
      await tx;
      setTransactionStatus('succes');
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  async function subFarmBal() {
    if (!farmSubBalId || !farmSubAmt) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.SubFarmBalance',
        Buffer.from(farmSubBalId, 'hex'),
        farmSubAmt
      ))

      await tx;
      setTransactionStatus('succes');
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  async function setSlurpySwapTime() {
    if (time) {
      await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [+time]});
      setCurTime(await (await getGtxClient()).query("slurpyswap.GetTime", {}));
    }
  }


  const findToken = (tokAddr:string) => {
    return tokens.find((token) => token.address.toLocaleLowerCase() === tokAddr.toLocaleLowerCase())
  }

  async function setOwnerAddress() {
    if (!owner) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.SetOwner',
        Buffer.from(owner, 'hex')
      ))

      await tx;
      setTransactionStatus('succes');

      // openNewModal({
      //   name: 'info',
      //   title: 'Success',
      //   message: `You successfullu set owner`
      // });
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  async function setItemCreatorAddress() {
    if (!itemCreator) {
        return
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'SetItemCreator',
        Buffer.from(itemCreator, 'hex')
      ))

      await tx;
      setTransactionStatus('succes');

      // openNewModal({
      //   name: 'info',
      //   title: 'Success',
      //   message: `You successfullu set item creator address`
      // });
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }


  const getPool = (poolAddr: string) => {
    return chrPools.find((chrPool) => chrPool.id.toLocaleLowerCase() === poolAddr.toLocaleLowerCase())
  }


  return (
    <StarBackground>
      <div className="SettingsPage">
        <div className="functionBlock">
          Current time: {curTime}
          {process.env.REACT_APP_IS_REAL_TIME !== "true" &&
            <>
              <Input
                type="text"
                value={time|| ''}
                setValue={setTime}
                placeholder='time'
              />

              <div className="buttonContainer">
                <Button
                  action={setSlurpySwapTime}
                >
                  Set time
                </Button>
              </div>
            </>
          }
        </div>

        <div className="functionBlock">
          <select 
            className="customSelect"
            onChange={(e) => setToken0(findToken(e.target.value)?.address)}
          >
              <option selected disabled hidden>Select first token</option>
              {tokens.map((token) => {
                return <option value={token.address}>
                  {token.name}
                </option>
              })}
          </select>

          <select 
            className="customSelect"
            onChange={(e) => setToken1(findToken(e.target.value)?.address)}
          >
            <option selected disabled hidden>Select second token</option>
            {tokens.map((token) => {
              return <option value={token.address}>
                {token.name}
              </option>
            })}
          </select>

          <select 
            className="customSelect"
            onChange={(e) => setTradingFee(e.target.value)}
          >
            <option selected disabled hidden>Select fee</option>
            <option value='0.0001'>0.01%</option>
            <option value='0.0005'>0.05%</option>
            <option value='0.0025'>0.25%</option>
            <option value='0.01'>1%</option>
          </select>

          {/* <Input
            type="text"
            value={tickSpacing || ''}
            setValue={setTickSpacing}
            placeholder='tick spacing'
          /> */}

          <Input
            type="text"
            value={currPriceTick || ''}
            setValue={setCurrPriceTick}
            placeholder='current price'
          />

          <div className="buttonContainer">
            <Button
              action={createPool}
            >
                Create pool
            </Button>
          </div>
        </div>
        
        <div className="tokensBlock">
          Tokens: 
          {tokens.map((token) => {
            return <div className="token">
              <div className="token__name">
                {token.name}
              </div>
              <div className="token__addres">
                {token.address}
              </div>
            </div>
          })}
        </div>

        <div className="tokensBlock">
          Farms: 
          {farms.map((farm) => {
            return <div className="farm">
              <div className="farm__name">
                {farm.tok0.name}-{farm.tok1.name}
              </div>
              <div className="farm__addres">
                {farm.acc.toString('hex')}
              </div>
              <div className="farm__addres">
                balance: {toFixed(farm.balance, 2)}
              </div>
              <div className="farm__closeTime">
                farm close time: {farm.close_time}
              </div>
              <div className="farm__closeTime">
                farm percent: {farm.current_rate}
              </div>
              <div className="farm__closeTime">
                pool: {getPool(farm.pool.toString('hex'))?.trading_fees}
              </div>
            </div>
          })}
        </div>

        <div className="pools">
          {pools && pools?.map((pool: any) => {
            return <div style={{marginTop: 40}}>
              Pool {pool.id}
              <div>
                tok0Amt - {chrPoolBalances && chrPoolBalances[pool.id as any]?.token0Amount.valueStr}
              </div>
              <div> 
                tok1Amt -  {chrPoolBalances && chrPoolBalances[pool.id as any]?.token1Amount.valueStr}
              </div>
              <div>
                token0 - {pool.t0Name}
              </div>
              <div>
                token1 - {pool.t1Name}
              </div>
              <div>
                totalAmt - {chrPoolBalances && chrPoolBalances[pool.id as any]?.totalAmount.valueStr}
              </div>
              <div>
                fee - {+pool.trading_fees * 100} %
              </div>
              <div>
                currentPrice - {toFixed(sqrtPriceToPrice(pool.sqrt_price), 6)}
              </div>
            </div>
          })}
        </div>

        <div className="functionBlock">
          <select 
            className="customSelect"
            onChange={(e) => setFarmPoolAddr(e.target.value)}
          >
            <option selected disabled hidden>Select farm pool</option>
            {chrPools?.map((pool) => {
              return <option value={pool.id}>
                {findToken(pool.token1)?.name}-{findToken(pool.token0)?.name}
              </option>
            })}
          </select>

          <select 
            className="customSelect"
            onChange={(e) => setFarmPoolTok(findToken(e.target.value)?.address)}
          >
            <option selected disabled hidden>Select second token</option>
            {tokens.map((token) => {
              return <option value={token.address}>
                {token.name}
              </option>
            })}
          </select>

          <Input
            type="text"
            value={incomePerSec || ''}
            setValue={setIncomePerSec}
            placeholder='farm income per second'
          />

          <div className="buttonContainer">
            <Button
              action={createFarm}
            >
                Create farm
            </Button>
          </div>
        </div>

        <div className="functionBlock">
          <select 
            className="customSelect"
            onChange={(e) => setFarmId(e.target.value)}
          >
            <option selected disabled hidden>Select farm id</option>
            {farms.map((farm) => {
              return <option value={farm.acc.toString('hex')}>
                {farm.tok0.name}-{farm.tok1.name}-{getPool(farm.pool.toString('hex'))?.trading_fees}%
              </option>
            })}
          </select>

          <Input
            type="text"
            value={farmPercent || ''}
            setValue={setFarmPercent}
            placeholder='farm percent'
          />

          <div className="buttonContainer">
            <Button
              action={setFarmPercentFunc}
            >
                Set farm percent
            </Button>
          </div>
        </div>

        <div className="functionBlock">
          <select 
            className="customSelect"
            onChange={(e) => setFarmIdCloseTime(e.target.value)}
          >
            <option selected disabled hidden>Select farm id</option>
            {farms.map((farm) => {
              return <option value={farm.acc.toString('hex')}>
                {farm.tok0.name}-{farm.tok1.name}-{getPool(farm.pool.toString('hex'))?.trading_fees}%
              </option>
            })}
          </select>

          <Input
            type="text"
            value={farmCloseTime || ''}
            setValue={setFarmCloseTime}
            placeholder='farm close time'
          />

          <div className="buttonContainer">
            <Button
              action={setFarmCloseTimeFunc}
            >
                Set farm close time
            </Button>
          </div>
        </div>

        <div className="functionBlock">
          <select 
            className="customSelect"
            onChange={(e) => setFarmAddBalId(e.target.value)}
          >
            <option selected disabled hidden>Select farm id</option>
            {farms.map((farm) => {
              return <option value={farm.acc.toString('hex')}>
                {farm.tok0.name}-{farm.tok1.name}-{getPool(farm.pool.toString('hex'))?.trading_fees}%
              </option>
            })}
          </select>

          <Input
            type="text"
            value={farmAddAmt || ''}
            setValue={setFarmAddAmt}
            placeholder='farm add amount'
          />

          <div className="buttonContainer">
            <Button
              action={addFarmBal}
            >
                Add farm balance
            </Button>
          </div>
        </div>

        <div className="functionBlock">
          <select 
            className="customSelect"
            onChange={(e) => setFarmSubBalId(e.target.value)}
          >
            <option selected disabled hidden>Select farm id</option>
            {farms.map((farm) => {
              return <option value={farm.acc.toString('hex')}>
                {farm.tok0.name}-{farm.tok1.name}-{getPool(farm.pool.toString('hex'))?.trading_fees}%
              </option>
            })}
          </select>

          <Input
            type="text"
            value={farmSubAmt || ''}
            setValue={setFarmSubAmt}
            placeholder='farm sub amount'
          />

          <div className="buttonContainer">
            <Button
              action={subFarmBal}
            >
                Sub farm balance
            </Button>
          </div>
        </div>

        <div className="functionBlock">
          Your chrClientAddr {chrClientAddr}
        </div>

        <div className="functionBlock">
          Owner: {currentOwner}
          <Input
            type="text"
            value={owner || ''}
            setValue={setOwner}
            placeholder='owner address'
          />

          <div className="buttonContainer">
            <Button
              action={setOwnerAddress}
            >
              Set owner
            </Button>
          </div>
        </div>

        <div className="functionBlock">
          Creator: {currentItemCreator}
          <Input
            type="text"
            value={itemCreator || ''}
            setValue={setItemCreator}
            placeholder='item creator address'
          />

          <div className="buttonContainer">
            <Button
              action={setItemCreatorAddress}
            >
              Set Item Creator
            </Button>
          </div>
        </div>
      </div>
    </StarBackground>
	)
}

export default SettingsPage;