interface iProps {
	action?: (...args: any) => any,
}

const PlusIcon: React.FC <iProps> = ({action}) => {

	return (
		<svg style={{cursor: 'pointer'}} onClick={action} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4.5 11.75V9.5V7.25H2.25H0V5H2.25H4.5V2.75V0.5H6.75V2.75V5H9H11.25V7.25H9H6.75V9.5V11.75H4.5Z" fill="#4C4C4C"/>
		</svg>
	)
}

export default PlusIcon;