import React, { useCallback, useEffect, useMemo, useState } from "react";
import { op } from "@chromia/ft4";

import { getGtxClient, updateChromiaBalances } from "../../../utils/Chromia/chromiaHelpers";
import { iFarmModal } from "../../../interfaces/interfaces";
import { useStoreDispatch, useStoreSelector } from "../../../store/store";
import { getTokenIconURL, logError, toFixed } from "../../../utils/helpers";
import { iAccPosition } from "../../MyLiqPage/MyLiqPage";
import { iFarmPos } from "../../FarmingPage/FarmingPage";

import BlockPixelBorder from "../../UI/BlockPixelBorder/BlockPixelBorder";
import outOfRangeIcon from '../../../assets/images/OutOfRangeIcon.svg';
import inRangeIcon from '../../../assets/images/InRangeIcon.svg';
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import backIcon from '../../../assets/images/back.svg';
import Button from "../../UI/Button/Button";

import './FarmModal.scss';
import LiquidityPageV2 from "../../LiquidityPageV2/LiquidityPageV2";
import BigNumber from "bignumber.js";

const FarmModal: React.FC <iFarmModal> = ({name: modalName, modalId, lpPos, farmPos, currFarm, updateFarms}) => {

	const { session, tokens, chrClientAddr, selectedNewPos } = useStoreSelector(store => store.app);
	const { closeModal, setTransactionStatus } = useStoreDispatch();

	const [isClosing, setIsClosing] = useState(false);
	// const tabIndex = useTabIndex({position: 'last', modalName: modalName});
	const { openNewModal, setSelectedNewPos } = useStoreDispatch();

	useEffect(() => {
    updateRewards();
    const tickInterval = setInterval(() => {
      updateFarms();
      
		}, 60000)

		return () => {
			clearInterval(tickInterval);
		}
	}, [])

  const updateRewards = async () => {
    try {
      if (chrClientAddr) {
        const curTime = +(new Date());

        await (await getGtxClient()).sendTransaction({
          operations: [
            {
              name: "slurpyswap.UpdateAllFarmLiquidity", 
              args: [Buffer.from(chrClientAddr, 'hex')]
            },
            {
              name: "nop", 
              args: [+curTime]
            }
          ],
          signers: []
        }
        );
      }
    }
    catch(err: any) {
      throw new Error(err)
    }
  }

	const closeFarmModal = () => {
		setIsClosing(true);
		setTimeout(()=>{
			closeModal(modalName, modalId);
		}, 490);
	}

  const stakeLP = async (selectedLPPos: iAccPosition) => {

    try {
      const now = Math.floor(Date.now() / 1000);
      let minTime = now + 60*60*24*10; //10 Days
      const balance = +currFarm.balance - +currFarm.pending_rewards;

      if (currFarm.close_time !== 0) {
          minTime = Math.min(minTime, currFarm.close_time);
      }
  
      if (now + +balance / +currFarm.current_rate <= minTime) {
        openNewModal({
          name: 'info',
          message: 'Insufficient farm balance :('
        })
        return
      }

      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			})

      const tx = session?.call(op(
        'slurpyswap.AddFarmLiquidity', 
        currFarm.acc,
        selectedLPPos.tick_lower,
        selectedLPPos.tick_upper,
        new BigNumber(selectedLPPos.liquidity).sub(new BigNumber(selectedLPPos.amount_locked_in_farm)).toString()
      ));
  
      await tx;

      setTransactionStatus('succes');
  
      if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
        const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
        await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
      }
  
      closeFarmModal();
  
      // openNewModal({
      //   name: 'info',
      //   message: `You successfully staked ${toFixed(selectedLPPos.liquidity, 4)} farm liquidity`
      // })
      updateFarms();
    }
    catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
      else {
				setTransactionStatus(null);
			}
    }
  
  }

  const unstakeLP = async (selectedFarmPos: iFarmPos) => {

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			})
      const tx = session?.call(op(
        'slurpyswap.SubFarmLiquidity', 
        currFarm.acc,
        selectedFarmPos.tick_lower,
        selectedFarmPos.tick_upper,
        selectedFarmPos.amount_locked_in_farm
      ));
  
      await tx;

      setTransactionStatus('succes');
  
      if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
        const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
        await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
      }
  
      closeFarmModal();
  
      // openNewModal({
      //   name: 'info',
      //   message: `You successfully unstaked ${toFixed(selectedFarmPos.amount_locked_in_farm, 4)} farm liquidity`
      // })
      updateFarms();
    }
    catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  const harvest = async (selectedFarmPos: iFarmPos) => {

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.CollectFarmLiquidity', 
        currFarm.acc,
        selectedFarmPos.tick_lower,
        selectedFarmPos.tick_upper
      ));
  
      await tx;
  
      setTransactionStatus('succes');
      
      closeFarmModal();
      updateChromiaBalances();
  
      if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
        const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
        await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
      }
  
      // openNewModal({
      //   name: 'info',
      //   message: `You successfully collected reward`
      // })
  
      updateFarms();
    }
    catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  const addNewPos = () => {
    closeFarmModal();
    setSelectedNewPos(currFarm)
  }

  const lpCount = useMemo(() => {
    return lpPos.filter(pos => pos.status !== 'Out of range').length
  }, [lpPos])

  const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])

  // if (selectedNewPos) return <LiquidityPageV2 setIsNewPosition={setSelectedNewPos} getPositions={updateFarms} ATokenProps={selectedNewPos.tok0} BTokenProps={selectedNewPos.tok1} feeProps={+selectedNewPos.selectedPool.trading_fees * 100}/>
	
  return (
		<ModalWrapper
			className='FarmModal'
			isModalClosing={isClosing}
			closeListenerSettings={{name: modalName, modalId, callback: closeFarmModal}}
		>
			<div className="FarmModal_header">
				<img src={backIcon} className="backIcon" alt="back" onClick={closeFarmModal}/>
				<div className='title'>
					Farm your positions
				</div>
			</div>

			<div className="positionInfo">
        <BlockPixelBorder className="farmPosition">
          <div className="farmPosition_inner">
            <div className="title">
              {lpCount} LP available for farming
            </div>
            <div className="lpTokens_wrapper">
              {lpPos?.map((lp: any) => {
                if (lp.status === 'Out of range') return <></>

                return <BlockPixelBorder
                  leftBorderWidth="4px"
                  rightBorderWidth="4px"
                >
                  <div className='position'>
                    <div className="positionList">
                      <div className="header">
                        <div className="info">
                          <img src={getTokenIconURL(lp.token1.name) || ''} className='tokIcon' alt="tok1Icon"/>
                          <img src={getTokenIconURL(lp.token0.name) || ''} className='tokIcon tokIcon2' alt="tok0Icon"/>
                          {lp.token1.name}/{lp.token0.name} <span className="fee">{+lp.selectedPool.trading_fees * 100}%</span>
                        </div>
                        <div className={`status ${lp.status === 'In range' ? 'inRange' : 'outOfRange'}`}>
                          {lp.status} <img src={lp.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
                        </div>
                      
                      </div>
                      {isMobile
                        ?
                          <div className="rangeMobile">
                            <div className="title">
                              Min: {toFixed(lp.minPrice.toFixed(), 4)} {lp.token1.name} per {lp.token0.name}
                            </div>
                            <div className="title">
                              Max: {lp.tick_upper < 887000 ?  toFixed(lp.maxPrice.toFixed(), 4) : '∞'} {lp.token1.name} per {lp.token0.name}
                            </div>
                          </div>
                        :
                          <div className="range">
                            <div className="title">
                              Min:
                            </div>
                            <div className="value">
                              {toFixed(lp.minPrice.toFixed(), 4)} {lp.token1.name} per {lp.token0.name}
                            </div>
                            <div className="arrowRange">
                              {' <-> '}
                            </div>
                            <div className="title">
                              Max:
                            </div>
                            <div className="value">
                              {lp.tick_upper < 887000 ?  toFixed(lp.maxPrice.toFixed(), 4) : '∞'} {lp.token1.name} per {lp.token0.name}
                            </div>
                          </div>
                      }
                    </div>
                    <div className="viewButton_wrapper">
                      <Button
                        action={() => stakeLP(lp)}
                        small={true}
                      >
                        Stake
                      </Button>
                    </div>
                  </div>
                </BlockPixelBorder>
              })}
            </div>
          </div>
        </BlockPixelBorder>

        <BlockPixelBorder className="farmPosition">
          <div className="farmPosition_inner">
            <div className="title">
              {farmPos?.length} staked farming
            </div>
            <div className="lpTokens_wrapper">
              {farmPos.map((farm) => {
                return <BlockPixelBorder
                leftBorderWidth="4px"
                rightBorderWidth="4px"
              >
                <div className='position'>
                  <div className="positionList">
                    <div className="header">
                      <div className="info">
                        <img src={getTokenIconURL(farm.token1.name) || ''} className='tokIcon' alt="tok1Icon"/>
                        <img src={getTokenIconURL(farm.token0.name) || ''} className='tokIcon tokIcon2' alt="tok0Icon"/>
                        {farm.token1.name}/{farm.token0.name} <span className="fee">{+farm.selectedPool.trading_fees * 100}%</span>
                      </div>
                      <div className={`status ${farm.status === 'In range' ? 'inRange' : 'outOfRange'}`}>
                        {farm.status} <img src={farm.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
                      </div>
                    
                    </div>
                    {isMobile 
                    ?
                      <div className="rangeMobile">
                        <div className="value">
                          Min: {toFixed(farm.minPrice.toFixed(), 4)} {farm.token1.name} per {farm.token0.name}
                        </div>
                        <div className="value">
                          Max: {farm.tick_upper < 887000 ?  toFixed(farm.maxPrice.toFixed(),4) : '∞'} {farm.token1.name} per {farm.token0.name}
                        </div>
                        <div className="title">
                          {farm.farmTok.name} earned: {toFixed(farm.reward, 5)} 
                        </div>
                      </div>
                    :
                      <div className="range">
                        <div className="title">
                          Min:
                        </div>
                        <div className="value">
                          {toFixed(farm.minPrice.toFixed(), 4)} {farm.token1.name} per {farm.token0.name}
                        </div>
                        <div className="arrowRange">
                          {' <-> '}
                        </div>
                        <div className="title">
                          Max:
                        </div>
                        <div className="value">
                          {farm.tick_upper < 887000 ?  toFixed(farm.maxPrice.toFixed(),4) : '∞'} {farm.token1.name} per {farm.token0.name}
                        </div>

                      </div>
                    }
                    {!isMobile && 
                      <div className="range">
                        <div className="title">
                          {farm.farmTok.name} earned:
                        </div>
                        <div className="value">
                          {toFixed(farm.reward, 5)} 
                        </div>
                      </div>
                    }
                  </div>
                  <div className="viewButton_wrapper">
                    <Button
                      action={() => unstakeLP(farm)}
                      small={true}
                    >
                      Unstake
                    </Button>
                    {(farm.reward && +farm.reward > 0) && 
                      <Button
                        action={() => harvest(farm)}
                        small={true}
                      >
                        Harvest
                      </Button>
                    }
                  </div>
                </div>
              </BlockPixelBorder> 
              })}
            </div>
          </div>
        </BlockPixelBorder>
			</div>

			<div className='modalFooter'>
          <Button
              action={addNewPos}
              // tabIndex={tabIndex}
            >
              Add new position
          </Button>
			</div>
		</ModalWrapper>
	)
}

export default FarmModal;