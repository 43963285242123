import React, { PropsWithChildren, useEffect } from "react";

import { tModalName } from "../../../interfaces/interfaces";

import './ModalWrapper.scss';
import PixelBorder from "../../UI/PixelBorder/PixelBorder";

interface iModalProps {
	className?: string,
	isModalClosing?: boolean,
	// windowStyle?: React.CSSProperties,
	closeListenerSettings?: {name: tModalName, modalId: number, callback: () => void},
}


const ModalWrapper: React.FC <PropsWithChildren<iModalProps>> = ({
	className,
	isModalClosing,
	// windowStyle,
	closeListenerSettings,
	children,
}) => {
	// const modalRef = useRef<HTMLDivElement | null>();

	useEffect(() => {     // resize eventListener
		// let resize_func = () => onWindowResize(modalRef.current);
		// resize_func();
		// window.addEventListener('resize', resize_func);
		
		closeListenerSettings && document.addEventListener(
			`close_${closeListenerSettings.name}_${closeListenerSettings.modalId}`,
			closeListenerSettings.callback
		);

		return ()=>{
			// window.removeEventListener('resize', resize_func);
			
			closeListenerSettings && document.removeEventListener(
				`close_${closeListenerSettings.name}_${closeListenerSettings.modalId}`,
				closeListenerSettings.callback
			);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!isModalClosing) {
			document.body.style.overflow = 'hidden';
		}
		else {
			document.body.style.overflow = 'unset';
		}
 }, [isModalClosing]);

	const bgClassName = 'ModalWrapper' +
		(isModalClosing? ' ModalWrapper_closing': '') +
		(className? ` ${className}` : '')
	;

	/* function onWindowResize(ref: HTMLDivElement | undefined | null){
		if (ref){
			ref.style.top = (window.innerHeight/2 - ref.offsetHeight/2) + 'px';
			ref.style.left = (window.innerWidth/2 - ref.offsetWidth/2) + 'px';
		}
	} */


	return (
		<div className={bgClassName}>
			<div
				className='ModalWrapper_bgClicker'
				onClick={()=>closeListenerSettings?.callback()}
			/>
			<div
				className='ModalWrapper_pixelWrapper'
			>
				<PixelBorder
					modal={true}
				>
					<div className="ModalWrapper_inner">
							{children}
					</div>
				</PixelBorder>
			</div>
		</div>
	)
}

export default ModalWrapper;