import { FC, PropsWithChildren } from "react";

import './BlockPixelBorder.scss';

interface iBlockPixelBorder {
	className?: string,
  topBorderHeight?: string,
  bottomBorderHeight?: string,
  leftBorderWidth?: string,
  rightBorderWidth?: string,
  //цвет в углах блока
  cornerBordersColor?: string,
  leftTopBorderColor?: string,
  bottomRightBorderColor?: string
}

//содержимое chidlren должно быть обернуто во wrapper - чтобы настроить паддинги блока

const BlockPixelBorder: FC <PropsWithChildren<iBlockPixelBorder>> = ({
	children,
  className,
  topBorderHeight,
  bottomBorderHeight,
  leftBorderWidth,
  rightBorderWidth,
  cornerBordersColor,
  leftTopBorderColor,
  bottomRightBorderColor
}) => {

  const blockTopBorderStyle: React.CSSProperties = {
    height: topBorderHeight,
    backgroundColor: leftTopBorderColor
	}

  const blockBottomBorderStyle: React.CSSProperties = {
    height: bottomBorderHeight,
    backgroundColor: cornerBordersColor || bottomRightBorderColor
	}

  const blockLeftBorderStyle: React.CSSProperties = {
    height: `calc(100% - ${bottomBorderHeight})`,
    width: leftBorderWidth,
    backgroundColor: leftTopBorderColor
	}

  const blockRightBorderStyle: React.CSSProperties = {
    height: topBorderHeight,
    width: rightBorderWidth,
    backgroundColor: cornerBordersColor || bottomRightBorderColor
	}


  return (
		<div className={className ? `${className} blockPixelBorder` : 'blockPixelBorder'} >
      <div className="leftBorder">
        <div className="leftBorder_dark_gray" style={blockLeftBorderStyle}/>
        <div className="leftBorder_gray" style={{height: bottomBorderHeight, width: leftBorderWidth}}/>
      </div>
      <div className="blockPixelBorderInner">
        <div className="topBorder" style={blockTopBorderStyle}/>
        {children}
        <div className="bottomBorder" style={blockBottomBorderStyle}/>
      </div>
      <div className="rightBorder">
        <div className="rightBorder_gray" style={blockRightBorderStyle}/>
        <div className="rightBorder_white"style={{width: rightBorderWidth, backgroundColor: bottomRightBorderColor}} />
      </div>
		</div>
	)
}

export default BlockPixelBorder;