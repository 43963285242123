import React, { useEffect, useMemo, useState } from "react";

import { iPositionCard } from "../../interfaces/interfaces";
import arrowLeft from '../../assets/images/arrowleft.svg';
import arrowright from '../../assets/images/arrowright.svg';
import { getTokenIconURL, logError, toFixed } from "../../utils/helpers";
import BigNumber from "bignumber.js";
import { calcBurn, sqrtPriceToPrice } from "../../utils/Chromia/v3";
import backIcon from '../../assets/images/back.svg';
import WalletAddress from "../WalletAddress/WalletAddress";
import { useStoreDispatch, useStoreSelector } from "../../store/store";
import StarBackground from "../StarBackground/StarBackground";
import inRangeIcon from '../../assets/images/InRangeIcon.svg';
import outOfRangeIcon from '../../assets/images/OutOfRangeIcon.svg';
import MenuPages from "../MenuPages/MenuPages";
import PixelBorder from "../UI/PixelBorder/PixelBorder";
import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";
import Button from "../UI/Button/Button";
import starBig4 from '../../assets/images/StarBig4.svg';
import starBig5 from '../../assets/images/StarBig5.svg';
import starSmall4 from '../../assets/images/StarSmall4.svg';

import './PositionPage.scss'
import { op } from "@chromia/ft4";
import { getGtxClient, updateChromiaBalances } from "../../utils/Chromia/chromiaHelpers";


const PositionPage: React.FC <iPositionCard> = ({position, positions, getPositions, selectedPosition, setSelectedPosition, isFarming}) => {
  const { selectedPos, chrPools, session } = useStoreSelector(store => store.app);
  const { openNewModal, setTransactionStatus } = useStoreDispatch();

  const [isQuoteToken1, setIsQuoteToken1] = useState<boolean>(true);
  const [receivedAmounts, setReceivedAmounts] = useState<{amount0: string, amount1: string}>();


  const liquidity = useMemo(() => {
    return +position.amount_locked_in_farm > 0 && isFarming 
      ? position.amount_locked_in_farm 
      : new BigNumber(position.liquidity).sub(new BigNumber(position.amount_locked_in_farm)).toString();
  }, [position, isFarming])

  useEffect(() => {
    // if (!selectedPos|| !positions) return 

    // let newPos;
    // positions.forEach((farm: iFarm) => {
    //   farm.farmPos.forEach((pos) => {
    //     if (pos.tick_lower === selectedPos.tick_lower && pos.tick_upper === selectedPos.tick_upper && pos.selectedPool.id === selectedPos.selectedPool.id) {
    //       newPos = pos;
    //     }
    //   })
    // });
    
    // if (newPos) {
    //   setSelectedPosition(newPos)
    // }
  }, [positions, selectedPos])
  
  useEffect(() => {

    const posInTokens = calcBurn(
      liquidity, 
      position.tick_lower, 
      position.selectedPool.sqrt_price, 
      position.tick_upper
    );

    if (posInTokens.t0_out && posInTokens.t1_out) {
      setReceivedAmounts({
        amount0: posInTokens.t0_out.toFixed(),
        amount1: posInTokens.t1_out?.toFixed()
      })
    }
  }, [position, liquidity, chrPools])

  const back = () => {
    setSelectedPosition(null)
  }

  const callLPTReturnModal = () => {
    if (receivedAmounts) {
      openNewModal({
        name: 'LPTReturn',
        sellToken: position.token0,
        buyToken: position.token1,
        position: position,
        setPositions: getPositions,
        receivedAmounts,
        isFarming
      });
    }

	}

	const callAddLiqModal = () => {

		openNewModal({
			name: 'AddLiqModal',
			sellToken: position.token1,
			buyToken: position.token0,
			position: position,
			setPositions: getPositions,
      isFarming
		});
	}

  const changeQuote = (quoteTok1: boolean) => {
    if (quoteTok1) {
			setIsQuoteToken1(true);
		}
		else {
			setIsQuoteToken1(false);
		}
	}

  const harvest = async () => {
    if (!position.positionFarm) {
      logError('no acc position for harvest');
      throw new Error('no acc position for harvest');
    }

    try {
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			});

      const tx = session?.call(op(
        'slurpyswap.CollectFarmLiquidity', 
        position.positionFarm.acc,
        position.tick_lower,
        position.tick_upper
      )); 
  
      await tx;
  
      setTransactionStatus('succes');
      
      updateChromiaBalances();
  
      if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
        const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
        await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
      }
  
      // openNewModal({
      //   name: 'info',
      //   message: `You successfully collected reward`
      // })
      await delay(1000);
      getPositions();
    }
    catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


	return (
    <StarBackground>
      <MenuPages/>
      <div className='PositionPage'>
        <WalletAddress/>
        <div className="stars_wrapper">
					<img src={starSmall4} className="starSmall1" alt="star"/>
					<img src={starSmall4} className="stars_wrapper_starSmall2" alt="star"/>
					<img src={starSmall4} className="stars_wrapper_starSmall3" alt="star"/>
					<img src={starBig5} className="stars_wrapper_starBig51" alt="star"/>
					<img src={starBig4} className="stars_wrapper_starBig41" alt="star"/>
					<img src={starBig5} className="stars_wrapper_starBig52" alt="star"/> 
					<img src={starBig5} className="stars_wrapper_starBig53" alt="star"/> 

				</div>
        <div className="positionBlock">
          <PixelBorder>
            <div className="posiitionBlockInner">
              <div className="backButton">
                <img src={backIcon} alt="back" onClick={() => back()}/> Back to Pool
              </div>
              <div className="header">
                <div className="tokenPair">
                  <img src={getTokenIconURL(position.token1.name) || ''} className='tokIcon' alt="tok1Icon"/>
                  <img src={getTokenIconURL(position.token0.name) || ''} className='tokIcon tokIcon2' alt="tok0Icon"/>
                  <div className="tokensNames">
                    {position.token1.name}/{position.token0.name}
                  </div>
                </div>
                <div className="fee">
                  {+position.selectedPool.trading_fees * 100}%
                </div>
                <div className={`status ${position.status === 'In range' ? 'inRange' : 'outOfRange'}`}>
                  {isFarming && +position.amount_locked_in_farm > 0 ? 
                    <div className="farmStatus">
                      Farming
                    </div>
                    : <></>
                  }
                  <div>
                    {position.status} <img src={position.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
                  </div>
                </div>
                <div className="liqButtons">
                  {+liquidity > 0 &&
                    <Button
                      small={true}
                      light={true}
                      action={() => callLPTReturnModal()}
                      >
                      Remove liquidity
                    </Button>
                  }
                  {(!isFarming  || (position.status === 'In range' && +position.amount_locked_in_farm > 0 && isFarming)) &&
                    <Button
                      small={true}
                      action={() => callAddLiqModal()}
                    >
                      Increase liquidity
                    </Button>
                  }
                </div>
              </div>
              {/* <div className="harvestButton__wrapper">
                  {+position.amount_locked_in_farm > 0 && (position.reward && +position.reward > 0) &&
                    <div className="rewards__flex">
                      <div className="">
                        {position.farmTok?.name} earned: {toFixed(position.reward, 4)}
                      </div>
                      <div className="harvestButton">
                        <Button
                          small={true}
                          action={harvest}
                        >
                          Harvest
                        </Button>
                      </div>
                    </div>
                  }
              </div> */}
 
              <div className="liqBlock block">
                <BlockPixelBorder
                  topBorderHeight="5px"
                  bottomBorderHeight="5px"
                  leftBorderWidth="3px"
                  rightBorderWidth="3px"
                >
                  <div className="liqBlockInner">
                    <div className="title">
                      Liquidity
                    </div>
                    <div className="liqValue"> 
                      {liquidity && +liquidity > 0 
                        ? new BigNumber(liquidity).gt(new BigNumber(0.00001))
                          ? toFixed(liquidity, 4)
                          : '<0.00001'
                        : '0'
                      }
                    </div>

                    <div className="valuesInTok">
                      <div className="name">
                        <img src={getTokenIconURL(position.token1.name) || ''} className='tokIcon' alt="tok1Icon"/> {position.token1.name} 
                      </div>
                      <div className="values">
                        <div className="value">
                          {receivedAmounts && +receivedAmounts.amount1> 0 
                            ? new BigNumber(receivedAmounts.amount1).gt(new BigNumber(0.00001))
                              ? toFixed(receivedAmounts.amount1, 4)
                              : '<0.00001'
                            : '0'
                          }
                        </div>
                        {/* <div className="value">51%</div> */}
                      </div>
                    </div>

                    <div className="valuesInTok">
                      <div className="name">
                        <img src={getTokenIconURL(position.token0.name) || ''} className='tokIcon' alt="tok0Icon"/> {position.token0.name} 
                      </div>
                      <div className="values">
                        <div className="value">
                          {receivedAmounts && +receivedAmounts.amount0 > 0 
                            ? new BigNumber(receivedAmounts.amount0).gt(new BigNumber(0.00001))
                              ? toFixed(receivedAmounts.amount0, 4)
                              : '<0.00001'
                            : '0'
                          }
                        </div>
                        {/* <div className="value">49%</div> */}
                      </div>
                    </div>
                  </div>
                </BlockPixelBorder>
              </div>

              {isFarming && +position.amount_locked_in_farm > 0  &&
                <div className="rewardsBlock block">
                  <BlockPixelBorder
                    topBorderHeight="5px"
                    bottomBorderHeight="5px"
                    leftBorderWidth="3px"
                    rightBorderWidth="3px"
                  >
                    <div className="rewardsBlockInner">
                      <div className="rewardsBlock_header">
                        <div className="title">
                          {position.farmTok?.name} earned
                        </div>
                        {(position.reward && +position.reward > 0) &&
                          <div className="harvestButton">
                            <Button
                              small={true}
                              action={harvest}
                            >
                              Harvest
                            </Button>
                          </div>

                        }
                      </div>

                      <div className="valuesInTok">
                        <div className="name">
                          <img src={(position.farmTok && getTokenIconURL(position.farmTok?.name)) || ''} className='tokIcon' alt="tok0Icon"/> {position.farmTok?.name} 
                        </div>
                        <div className="value">
                          {position.reward && toFixed(position.reward, 4)}
                        </div>
                      </div>
                    </div>
                  </BlockPixelBorder>
                </div>
              }

              <div className="feeBlock block">
                <BlockPixelBorder
                  topBorderHeight="5px"
                  bottomBorderHeight="5px"
                  leftBorderWidth="3px"
                  rightBorderWidth="3px"
                >
                  <div className="feeBlockInner">
                    <div className="feeBlock_header">
                      <div className="title">
                        Unclaimed fees
                      </div>
                      {
                        (new BigNumber(position.fee_0).gt(new BigNumber(0)) ||
                        new BigNumber(position.fee_1).gt(new BigNumber(0))) &&
                        <Button
                          className="collectFeeButton"
                          small={true}
                          action={() => openNewModal({
                            name: 'claim',
                            title: `Claim fees`,
                            position: position
                          })}
                        >
                          Collect fees
                        </Button>

                      }
                    </div>

                    <div className="valuesInTok">
                      <div className="name">
                        <img src={getTokenIconURL(position.token0.name) || ''} className='tokIcon' alt="tok0Icon"/> {position.token0.name} 
                      </div>
                      <div className="value">
                        {+position.fee_0 > 0 
                          ? new BigNumber(position.fee_0).gt(new BigNumber(0.00001))
                            ? toFixed(position.fee_0, 5)
                            : '<0.00001'
                          : '0'
                        }
                      </div>
                    </div>

                    <div className="valuesInTok">
                      <div className="name">
                        <img src={getTokenIconURL(position.token1.name) || ''} className='tokIcon' alt="tok1Icon"/> {position.token1.name} 
                      </div>
                      <div className="value">
                        {+position.fee_1 > 0 
                          ? new BigNumber(position.fee_1).gt(new BigNumber(0.00001))
                            ? toFixed(position.fee_1, 5)
                            : '<0.00001'
                          : '0'
                        }
                      </div>
                    </div>
                  </div>
                </BlockPixelBorder>
              </div>

              <div className="rangeBlock block">
                <BlockPixelBorder
                  topBorderHeight="15px"
                  bottomBorderHeight="15px"
                  leftBorderWidth="4px"
                  rightBorderWidth="4px"
                >
                  <div className="rangeBlockInner">
                    <div className="header">
                      <div className="title">
                        Price range 
                      </div>
                      <div className="currency">
                        <div 
                          className={`tokBtn ${isQuoteToken1 ? 'tokBtn_active' : ''}`}
                          onClick={() => changeQuote(true)}
                        >
                          {position.token1.name}
                        </div>
                        <div 
                          className={`tokBtn tok1Btn ${!isQuoteToken1 ? 'tokBtn_active' : ''}`}
                          onClick={() => changeQuote(false)}
                        >
                          {position.token0.name}
                        </div>
                      </div>
                    </div>

                    <div className="pricesBlock">
                      <div className="price block">
                        <BlockPixelBorder
                          topBorderHeight="5px"
                          bottomBorderHeight="5px"
                          leftBorderWidth="2.5px"
                          rightBorderWidth="2.5px"
                        >
                          <div className="priceBlockInner">
                            <div className="title">
                              Min price
                            </div>
                            <div className="value">
                              {isQuoteToken1 
                                ? toFixed(position.minPrice.toFixed(), 4)
                                : toFixed(new BigNumber(1).div(position.maxPrice).toFixed(), 4)
                              } 
                            </div>
                            <div>
                              {position.token1.name} per {position.token0.name}
                            </div>
                            <div className="warning">
                              Your position will be 100% {isQuoteToken1 ? position.token1.name : position.token0.name} at this price
                            </div>
                            <div className="priceInner">
                            </div>
                          </div>
                        </BlockPixelBorder>
                      </div>
                      <div>
                        <img src={arrowLeft} alt="arrowLeft"/>
                        <img src={arrowright} alt="arrowRight"/>
                      </div>
                      <div className="price block">
                        <BlockPixelBorder
                          topBorderHeight="5px"
                          bottomBorderHeight="5px"
                          leftBorderWidth="2.5px"
                          rightBorderWidth="2.5px"
                        >
                          <div className="priceBlockInner">
                            <div className="title">
                              Max price
                            </div>
                            <div className="value">
                            {position.tick_upper < 887200 
                              ? isQuoteToken1 ? toFixed(position.maxPrice.toFixed(), 4) : toFixed(new BigNumber(1).div(position.minPrice).toFixed(), 4)
                              : '∞'
                            }
                            </div>
                            <div className="pair">
                              {position.token1.name} per {position.token0.name}
                            </div>
                            <div className="warning">
                              Your position will be 100% {isQuoteToken1 ? position.token1.name : position.token0.name} at this price
                            </div>
                          </div>
                        </BlockPixelBorder>
                      </div>
                    </div>

                    <div className="currentPriceBlock block">
                      <BlockPixelBorder
                        topBorderHeight="5px"
                        bottomBorderHeight="5px"
                        leftBorderWidth="2.5px"
                        rightBorderWidth="2.5px"
                      >
                        <div className="currentPriceBlockInner">
                          <div className="title">
                            Current price
                          </div>
                          <div className="value">
                            {isQuoteToken1 ? toFixed(sqrtPriceToPrice(position.selectedPool.sqrt_price), 4) : new BigNumber(1).div(sqrtPriceToPrice(position.selectedPool.sqrt_price)).toFixed(4)}
                          </div>
                          <div className="pair">
                            {position.token1.name} per {position.token0.name}
                          </div>
                        </div>
                      </BlockPixelBorder>
                    </div>
                  </div>
                </BlockPixelBorder>
              </div>
            </div>
          </PixelBorder>
        </div>
      </div>

    </StarBackground>

	)
}

export default PositionPage;