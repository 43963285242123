import { iChartModal, iConfirmModal, iFarmingUnstakeModal, iFarmingStakeModal, iInfoModal, iLPTReturnModal, iSimpleModal, iTokenSelectModal, iTransactionModal, tModalName, iAddLiqModal, iClaimModal, iFarmModal, iConnectorsModal } from "../../interfaces/interfaces"
import { Dispatch } from "../store"
import { eModalsActionTypes } from "./modalsTypes"

export const openNewModal = (modal:
	Omit<iSimpleModal, 'modalId'> |
	Omit<iInfoModal, 'modalId'> |
	Omit<iTokenSelectModal, 'modalId'> |
	Omit<iLPTReturnModal, 'modalId'> |
	Omit<iConfirmModal, 'modalId'> |
	Omit<iChartModal, 'modalId'> | 
	Omit<iTransactionModal, 'modalId'> | 
	Omit<iFarmingStakeModal, 'modalId'> |
	Omit<iFarmingUnstakeModal, 'modalId'> |
	Omit<iAddLiqModal, 'modalId'> |
	Omit<iClaimModal, 'modalId'> |
	Omit<iFarmModal, 'modalId'> |
	Omit<iConnectorsModal, 'modalId'>
) => {
	return (dispatch : Dispatch) => { 
		dispatch({
			type: eModalsActionTypes.OPEN_NEW_MODAL,
			payload: modal
		})
	}
}

export const closeModal = (name: tModalName, id: number) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eModalsActionTypes.CLOSE_MODAL,
			payload: {name, id}
		})
	}
}