import { FC, PropsWithChildren, useEffect, useState } from "react";

import './Button.scss';

interface iButtonProps {
	className?: string,
	action?: (...params: any) => any,	// any number of params
	/**
	 * Overrides default action for 'Enter' and 'Space' if passed.
	 */
	enterAction?: (...params: any) => any,	// any number of params
	blocked?: boolean,
	light?: boolean,
	small?: boolean,
	active?: boolean,
	tabIndex?: boolean | number,
	onMouseOver?: (...args: any) => any,
	onMouseOut?: (...args: any) => any,
	onFocus?: (...args: any) => any,
	onBlur?: (...args: any) => any,
}


const Button: FC <PropsWithChildren<iButtonProps>> = ({
	children,
	className,
	action,
	enterAction,
	blocked,
	light,
	small,
	active,
	tabIndex,
	onMouseOver,
	onMouseOut,
	onFocus,
	onBlur,
}) => {
	const [isActive, setIsActive] = useState(false);
	const [innerClassName, setInnerClassName] = useState('buttonInner');
	const [containerClassName, setContainerClassName] = useState('_ButtonContainer');

	useEffect(()=>{
		setInnerClassName(
			'buttonInner ' +
			((blocked || light)? 'button' : '') +
			(blocked ? 'Blocked' : '') +
			(light ? 'Light' : '')
		)
	}, [blocked, light])

	useEffect(()=>{
		setContainerClassName(
			(blocked? '_ButtonContainerBlocked' : '_ButtonContainer') +
			(isActive? ' _ButtonContainer_active' : '') +
			(className? ' ' + className : '') +
			(light ? '_light' : '') +
			(small? ' _ButtonContainer_small' : '') +
			(active!== undefined && !active ? ' _ButtonContainer_notActive' : '')
		)
	}, [blocked, isActive, className, light, small, active])


	const mouseAction = () => {
		if (blocked) return;
		action && setTimeout(() => {
			action();
		});
	}

	const keyDownEvent = (event: React.KeyboardEvent) => {
		if (blocked) return;
		if (event.key === 'Enter' || event.key === ' '){	// onKeyDown !== onKeyPress, e.key !== e.code
			setIsActive(true);
			setTimeout(() => {
				setIsActive(false);
			}, 100);
			const _action = enterAction? enterAction : action? action : undefined;
			_action && setTimeout(() => {
				_action();
			});
		}
	}


	return (
		<div
			className={containerClassName}
			tabIndex={(tabIndex && !blocked)? (typeof tabIndex === 'boolean'? 0 : tabIndex) : undefined}
			onClick={mouseAction}
			onKeyDown={keyDownEvent}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<button className={innerClassName}
			>
				{children}
			</button>
		</div>
	)
}

export default Button;