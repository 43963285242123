import { FC } from "react";


const GearIcon: FC = () => {

	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20 9V8H19V6H20V4H19V3H18V2H16V3H14V2H13V0H9V2H8V3H6V2H4V3H3V4H2V6H3V8H2V9H0V13H2V14H3V16H2V18H3V19H4V20H6V19H8V20H9V22H13V20H14V19H16V20H18V19H19V18H20V16H19V14H20V13H22V9H20ZM9 9V8H13V9H14V13H13V14H9V13H8V9H9Z" fill="#4C4C4C"/>
		</svg>
	)
}

export default GearIcon;