import { iUIState } from "../../interfaces/interfaces";
import { eUIActionTypes, tUIAction } from "./UITypes";

const initialState: iUIState = {
	isDarkMode: true,
	// isBgAnimation: true,
	isBgAnimation: false,
	isIniting: true,
	isGrabbingCursor: false,
}

export const UIReducer = (state = initialState, action: tUIAction): iUIState => {
	switch (action.type){
		case eUIActionTypes.TOGGLE_DARK_MODE:
			return {...state, isDarkMode: !state.isDarkMode};

		case eUIActionTypes.TOGGLE_BG_ANIMATION:
			return {...state, isBgAnimation: !state.isBgAnimation};

		case eUIActionTypes.SET_IS_INITING:
			return action.payload === state.isIniting
				? state
				: {...state, isIniting: action.payload}
			;

		case eUIActionTypes.SET_IS_GRABBING_CURSOR:
			return {...state, isGrabbingCursor: action.payload};

		default:
			// throw error ?
			return state;
	}
}