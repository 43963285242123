import React, { useState } from "react";

import { useStoreDispatch, useStoreSelector } from "../../../store/store";
import downArrowMenu from '../../../assets/images/downArrowMenu.svg';
import upArrowMenu from '../../../assets/images/upArrowMenu.svg';
import settingsIcon from "../../../assets/images/settingsIcon.svg";

import './SettingsIcon.scss';
import Input from "../Input/Input";
import { defaultSlippagePercent } from "../../../interfaces/interfaces";
import Button from "../Button/Button";


const SettingsIcon: React.FC  = () =>{
  const {slippagePercent} = useStoreSelector(store => store.app);

	const { setSlippagePercent } = useStoreDispatch();

	const [isSettingOpened, setIsSettingOpened] = useState<boolean>(false);
  const [isSlippagePercent, setIsSlippagePercent] = useState<boolean>(false);
  const [mode, setMode] = useState<'auto' | 'custom'>(slippagePercent === defaultSlippagePercent ? 'auto' : 'custom');

  const slippagePercentHandler = (value: string) => {
    setMode('custom');

    if (+value <= 10) {
      setSlippagePercent(value);
    }
  }
  
  const setAutoSlippage = () => {
    setMode('auto');
    setSlippagePercent(defaultSlippagePercent)
  }

	return (
    <div className="settingsIcon_wrapper ">
      <img src={settingsIcon} 
      alt="settings" 
      className="settingsIcon" 
      onClick={() => {
        setIsSettingOpened(!isSettingOpened);
        localStorage.setItem('LSSlippagePercent', slippagePercent);
      }}/>

      {isSettingOpened && 
        <div className="settingsModal">
          <div className="settingsModal_inner">
            <div>
              Max. slippage
            </div>
            <div className="modeSelect">
              {mode === 'auto' ? 'Auto' : 'Custom'} <img src={isSlippagePercent ? upArrowMenu : downArrowMenu} className="arrow" alt="dropDownArrow" onClick={() => setIsSlippagePercent(!isSlippagePercent)}/>
            </div>
          </div>
          {isSlippagePercent && 
            <div className="slippagePercent_wrapper">
              <div className="mode">
                <Button
                  light={true}
                  small={true}
                  active={mode === 'auto'}
                  action={() => setAutoSlippage()}
                >
                  Auto
                </Button>
                <Button
                  light={true}
                  small={true}
                  active={mode === 'custom'}
                  action={() => setMode('custom')}
                >
                  Custom
                </Button>
              </div>
              <div className="inputSlipPercent_wrapper">
                <Input
                  type="number"
                  value={slippagePercent}
                  setValue={slippagePercentHandler}
                  currency="%"
                  maxValue="10"
                />
              </div>
            </div>
          }
        </div>
      }
    </div>
	)
}

export default SettingsIcon;