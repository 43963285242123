import React, { useState } from "react";

import { iChrFarm, iChrPool } from "../../interfaces/chromiaInterfaces";
import { Token } from "../../entities/Token";
import { tStatus } from "../../interfaces/interfaces";
import { iAccPosition } from "../MyLiqPage/MyLiqPage";

import StarBackground from "../StarBackground/StarBackground";
import Footer from "../Footer/Footer";
import MenuPages from "../MenuPages/MenuPages";
import BigNumber from "bignumber.js";
import PixelBorder from "../UI/PixelBorder/PixelBorder";
import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";
import Button from "../UI/Button/Button";

import swapFeature from '../../assets/images/swapFeature.svg';
import lpFeature from '../../assets/images/lpFeature.svg';
import liqFeature from '../../assets/images/liqFeature.svg';
import analyticsFeature from '../../assets/images/analyticsFeature.svg';
import faqDownArrow from '../../assets/images/faqDownArrow.svg';
import faqUpArrow from '../../assets/images/faqUpArrow.svg';
import twitterIcon from '../../assets/images/twitterIcon.svg';
import discordIcon from '../../assets/images/discordIcon.svg';
import gitIcon from '../../assets/images/gitIcon.svg';

import './LandingPage.scss'

export interface iFarm extends iChrFarm {
  farmPool: iChrPool | undefined,
  tok0: Token,
  tok1: Token,
  farmTok: Token,
  lpPos: iAccPosition[],
  farmPos: iFarmPos[],
  tvl: string,
  chrReward: string,
  apr: string,
  selectedPool: iChrPool
}

export interface iFarmPos extends iAccPosition{
  farm: iFarm
  last_update_time: number
  reward: string,
  reward_growth_total: number,
  minPrice: BigNumber,
  maxPrice: BigNumber,
  currentPrice: BigNumber,
  status: tStatus,
  token0: Token,
  token1: Token,
  farmTok: Token
}

export interface iChrFarmPos {
  farm: iFarm
  last_reward_growth_inside: string
  position: iAccPosition,
  reward: number
}

const LandingPage: React.FC = () => {
  const [isFaqOpen, setIsFaqOpen] = useState<boolean>(false);


	return (
    <StarBackground>
      <MenuPages/>
      <div className='landingPage'>
          <div className="landingBlock">
            <PixelBorder>
              <div className="landingBlockInner">
                <div className="totalValue">
                  <div className="totalValue__title">
                    Total Value Locked
                  </div>
                  <div className="totalValue__value">
                    $ <div className="preload"/>
                  </div>
                  <div className="totalValue__subtitle">
                    Top Asset Exchange on the Polygon Network
                  </div>
                  <div className="enterAppButton__wrapper">
                    <Button>
                      Enter App
                    </Button>
                  </div>
                </div>

                <div className="statistics">
                  <div className="statistics__item">
                    <div className="statistics__value">
                      <div className="preload"/>
                    </div>
                    <div className="statistics__subtitile">
                      24H transactions
                    </div>
                  </div>
                  
                  <div className="statistics__item">
                    <div className="statistics__value">
                    <div className="preload"/>
                    </div>
                    <div className="statistics__subtitile">
                      24H trading volume
                    </div>
                  </div>

                  <div className="statistics__item">
                    <div className="statistics__value">
                    <div className="preload"/>
                    </div>
                    <div className="statistics__subtitile">
                      Total trading pairs
                    </div>
                  </div>
                </div>

                <div className="volume">
                  <BlockPixelBorder>
                    <div className="volume__inner">
                      <div className="volume__title">
                        24h Most Volume
                      </div>
                      <div className="volume__content">
                        <div className="preload"/>
                      </div>
                    </div>
                  </BlockPixelBorder>
                </div>
                <div className="features">
                  <div className="features__title">
                    Features
                  </div>
                  <div className="features__list">
                    <div className="feature">
                      <img src={swapFeature} className="feature__icon" alt="featureIcon"/>
                      <div className="feature__inner">
                        <div className="feature__title">
                          Swap Tokens
                        </div>
                        <div className="feature__content">
                          Trade  any combination of ERC-20 tokens with permissionless, with ease
                        </div>
                      </div>
                    </div>

                    <div className="feature">
                      <img src={liqFeature} className="feature__icon" alt="featureIcon"/>
                      <div className="feature__inner">
                        <div className="feature__title">
                          Supply liquidity
                        </div>
                        <div className="feature__content">
                          Earn 0.25% fee on trades proportional your share of the pool
                        </div>
                      </div>
                    </div>

                    <div className="feature">
                      <img src={lpFeature} className="feature__icon" alt="featureIcon"/>
                      <div className="feature__inner">
                        <div className="feature__title">
                          Earn dSLURPY
                        </div>
                        <div className="feature__content">
                          Deposit your LP to earn additional rewards
                        </div>
                      </div>
                    </div>

                    <div className="feature">
                      <img src={analyticsFeature} className="feature__icon" alt="featureIcon"/>
                      <div className="feature__inner">
                        <div className="feature__title">
                          Analytics
                        </div>
                        <div className="feature__content">
                          Scan through SlurpySwap analytics & Historical Data
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="news">
                  <BlockPixelBorder>
                    <div className="news__inner">
                      <div className="news__subtitle">
                        SlurpySwap Dispatch
                      </div>
                      <div className="news__title">
                        Newsletter Sign Up
                      </div>
                      <div className="news__subtitle">
                        Sing-up for the Slurpyswap newsletter to get the latest SlurpySwap alpha.
                      </div>
                      <div className="signUp">
                        <BlockPixelBorder>
                          <div className="signUp__inner">
                            <div>
                              Enter your email 
                            </div>
                            <div className="signUpButton__wrapper">
                              <Button>
                                Sign up
                              </Button>
                            </div>
                          </div>
                        </BlockPixelBorder>
                      </div>
                    </div>
                  </BlockPixelBorder>
                </div>
                <div className="faq">
                  <div className="faq__header">
                    <div className="faq__title">
                      FAQs
                    </div>
                    <div className="arrow__wrapper" onClick={() => setIsFaqOpen(!isFaqOpen)}>
                      <img src={!isFaqOpen ? faqUpArrow : faqDownArrow } className="faqArrow" alt="faqArrow"/>
                    </div>
                  </div>
                  {isFaqOpen && 
                    <div className={`faq__content`}>
                      <div className="questions">
                        <BlockPixelBorder>
                          <div className="question__inner">
                              <div className="question">
                                What is SlurpySwap?
                              </div>
                              <div className="answer"></div>
                          </div>
                        </BlockPixelBorder>
                        <BlockPixelBorder>
                          <div className="question__inner">
                              <div className="question">
                                What makes SlurpySwap unique from other DEXs?
                              </div>
                              <div className="answer"></div>
                          </div>
                        </BlockPixelBorder>
                        <BlockPixelBorder>
                          <div className="question__inner">
                              <div className="question">
                                What is SLURPY?
                              </div>
                              <div className="answer"></div>
                          </div>
                        </BlockPixelBorder>
                        <BlockPixelBorder>
                          <div className="question__inner">
                              <div className="question">
                                How does governance work in the SlurpySwap DAO?
                              </div>
                              <div className="answer"></div>
                          </div>
                        </BlockPixelBorder>
                        <BlockPixelBorder>
                          <div className="question__inner">
                              <div className="question">
                                What is SlurpySwap`s integration?
                              </div>
                              <div className="answer"></div>
                          </div>
                        </BlockPixelBorder>

                      </div>

                    </div>
                  }
                </div>
                <div className="connection">
                  <div className="connection__title">
                    Join the ever-growing slurpy community
                  </div>

                  <div className="links">
                    <div className="link">
                      Discord
                      <img src={discordIcon} className="linkIcon" alt="discordLink"/>
                    </div>
                    <div className="link">
                      Twitter
                      <img src={twitterIcon} className="linkIcon" alt="twitterLink"/>
                    </div>
                    <div className="link">
                      GitHub
                      <img src={gitIcon} className="linkIcon" alt="gitLink"/>
                    </div>
                  </div>
                  <div className="connection__subtitle">
                    SlurpuSwap`s community is building a comprehensive decentralised trading platform in the Chromia ecosystem for the future of finance. Join the slurpy!
                  </div>
                </div>
              </div>
            </PixelBorder>
          </div>
        <Footer/>
      </div>
    </StarBackground>
	)
}

export default LandingPage;