import { Dispatch } from "../store"
import { eUIActionTypes } from "./UITypes"


export const toggleDarkMode = () => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eUIActionTypes.TOGGLE_DARK_MODE,
		})
	}
}

export const toggleBgAnimation = () => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eUIActionTypes.TOGGLE_BG_ANIMATION,
		})
	}
}

export const setIsIniting = (state: boolean) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eUIActionTypes.SET_IS_INITING,
			payload: state
		})
	}
}

export const setIsGrabbingCursor = (state: boolean) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eUIActionTypes.SET_IS_GRABBING_CURSOR,
			payload: state
		})
	}
}