interface iProps {
	action?: (...args: any) => any,
}

const MinusIcon: React.FC <iProps> = ({action}) =>{

	return (
		<svg style={{cursor: 'pointer'}} onClick={action} width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9 0H11.25V2.25H9H6.75H4.5H2.25H0V0H2.25H4.5H6.75H9Z" fill="#4C4C4C"/>
		</svg>
	)
}

export default MinusIcon;