import React, { useEffect, useRef, useState } from 'react'

import searchIconImg from '../../../assets/images/searchIcon.svg';

import './Input.scss';

interface iProps {
	value?: string,
	setValue?: (value: string) => void,
	setInvalid?: (bool: boolean) => void,
	/**
	 * Triggers on 'Enter' press.
	 */
	action?: (...args: any) => any,
	tabIndex?: boolean,
	type?: 'number' | 'text' | 'date',
	blocked?: boolean,
	invalid?: boolean,
	showInvalidHint?: boolean,
	placeholder?: string,
	negativeValues?: boolean,
	digitsBeforeDot?: number,
	digitsAfterDot?: number,
	minValue?: number,
	maxValue?: string,
	notNull?: boolean,
	className?: string,
	label?: string,
	disablePaste?:boolean,
	currency?: string,
	focused?: boolean,
	// onChangeFunc?: () => void
	onBlur?: Function,
	flatTop?: boolean,
	flatRight?: boolean,
	flatBottom?: boolean,
	flatLeft?: boolean,
	maxButton?: boolean,
	searchIcon?: boolean
}


const Input: React.FC <iProps> = ({
	value,
	setValue,
	setInvalid,
	action,
	tabIndex,
	type,   // text is default
	blocked,
	invalid,
	showInvalidHint = true,   // initial
	className,
	label,
	disablePaste,
	currency,
	focused,
	onBlur,

	// for text and number type
	placeholder,

	// for number type
	negativeValues,
	digitsBeforeDot, // must be bigger than 0
	digitsAfterDot,   // can be 0
	minValue,
	maxValue,
	notNull,

	flatTop,
	flatRight,
	flatBottom,
	flatLeft,
	maxButton,
	searchIcon
}) => {
	const inputRef = useRef<HTMLInputElement | null>();

	useEffect(()=>{     // sets focus on input after rendering
		if (inputRef.current && focused ){
			inputRef.current.focus();
		}
		// eslint-disable-next-line
	}, [inputRef.current]);

	// const now_ts = use_store_selector(store => store.app.system_timestamp);

	const [invalid_hint, set_invalid_hint] = useState('');
	const [isFocused, setIsFocused] = useState(false);

	function numberValidator(val: string){
		let res_string = val;
		
		if(!setValue){ // if no setter do nothing
			return;
		}

		if (!negativeValues && val === ''){    // transform empty string to 0
			setValue('0');
			if (notNull && setInvalid){
				setInvalid(true);
			}
			return;
		}

		res_string = res_string.replace(/,/, '.'); // replace , to .


		if (res_string.length > 1 && /^-?0+[^\\.]/.test(res_string)){    // removes 0 before number if any
			res_string.substring(0,1) === '-' ? res_string = '-' + res_string.slice(2)  : res_string = res_string.slice(1);    
		}

		let regexp_string = `${negativeValues? '^-?' : '^'}`;
			regexp_string += `[0-9]{0,${typeof digitsBeforeDot === 'number'? digitsBeforeDot : 99}}`;
			regexp_string += digitsAfterDot === 0 ? `$` : `(?:\\.[0-9]{0,${typeof digitsAfterDot === 'number' ? digitsAfterDot : 18}})?$`;  
		/*  regExp which allows
			1) only numbers and dot
			2) 1-n digits before dot, 1-m digits after dot
			3) optional fractional part
			4) optional negative value
		*/
		let regexp = new RegExp (regexp_string, 'g');

		if (setInvalid && (res_string === '-' || res_string === '-.')){
			setValue(res_string);
			// onChangeFunc && onChangeFunc();
			setInvalid(true);
			return;
		}


		if (regexp.test(res_string)){

			if (setInvalid) {
				let local_invalid_state = false;
				let local_invalid_hint = '';

				if (notNull && +res_string === 0){
					local_invalid_state = true;
					// local_invalid_hint = 'Should be bigger than 0';
				}

				if (minValue !== undefined && +res_string < minValue){
					local_invalid_state = true;
					local_invalid_hint = `min value: ${minValue}`;
				}
				
				if (maxValue !== undefined && +res_string > +maxValue){
					local_invalid_state = true;
					local_invalid_hint = `max value: ${maxValue}`;
				}

				if ( Number.isNaN(+res_string) ){
					local_invalid_state = true;
				}

				set_invalid_hint(local_invalid_hint);
				setInvalid(local_invalid_state);
			}
			
			setValue(res_string);
			
		} 
	}

	function textValidator(text: string){
		setValue && setValue(text)
	}

	function dateValidator(date: string){
		/*date += 'T08:00:00Z';
		let ts = +(new Date(date)) / 1000;
		set_value(ts + '');*/
		setValue && setValue(date);
	}


	if (type === 'date') return (
		<div
			className={
				'_InputContainer' +
				(flatTop? ' _InputContainer_flatTop' : '') +
				(flatRight? ' _InputContainer_flatRight' : '') +
				(flatBottom? ' _InputContainer_flatBottom' : '') +
				(flatLeft? ' _InputContainer_flatLeft' : '') +
				(isFocused? ' _InputContainer_focused' : '') +
				(className? ' ' + className : '')
			}
		>
			<input
				type='date'
				value={value}
				onPaste={e => disablePaste && e.preventDefault()}
				onFocus={()=>setIsFocused(true)}
				onBlur={()=>{setIsFocused(false); onBlur && onBlur()}}
				onChange={e => dateValidator(e.target.value)}
				tabIndex={ (tabIndex && !blocked)? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				readOnly={blocked? blocked : false}
				// min={now_ts? get_human_date(now_ts, 'YYYY-MM-DD') : undefined}
				// className={`${blocked? 'blocked' : ''} ${className? className : ''}`}
				className={blocked? 'blocked' : ''}
				ref={reference => inputRef.current = reference}
				onKeyDown={e => e.key === 'Enter' && action && action()}
			/>
			{showInvalidHint && invalid && <div className='input_invalid'>Inavlid date</div>}
		</div>
	)

	if (type === 'number') return (
		<div
			className={
				'_InputContainer' +
				(flatTop? ' _InputContainer_flatTop' : '') +
				(flatRight? ' _InputContainer_flatRight' : '') +
				(flatBottom? ' _InputContainer_flatBottom' : '') +
				(flatLeft? ' _InputContainer_flatLeft' : '') +
				(isFocused? ' _InputContainer_focused' : '') +
				(className? ' ' + className : '')
			}
		>
			<input
				type='text'
				value={value}
				onPaste={e => disablePaste && e.preventDefault()}
				onFocus={()=>setIsFocused(true)}
				onBlur={()=>{setIsFocused(false); onBlur && onBlur()}}
				onChange={e => {
					// action && action();
					numberValidator(e.target.value)}
				}
				placeholder={placeholder}
				tabIndex={ (tabIndex && !blocked)? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				readOnly={blocked? blocked : false}
				ref={reference => inputRef.current = reference}
				// className={`${blocked? 'blocked' : ''} ${className? className : ''}`}
				className={blocked? 'blocked' : ''}
				// onKeyDown={e => {
				// 	e.key === 'Enter' && action && action()
				// }}
			/>
			{maxValue && maxButton &&
				<div 
					className='maxButton'
					onClick={() => {
						action && action();
						numberValidator(maxValue)}
					}
				>
					MAX
				</div>
			}
			{currency && <div className='currency'>{currency}</div>}
			{showInvalidHint && invalid && <div className='input_invalid'>{invalid_hint}</div>}
		</div>
	)


	return (
		<div
			className={
				'_InputContainer' +
				(flatTop? ' _InputContainer_flatTop' : '') +
				(flatRight? ' _InputContainer_flatRight' : '') +
				(flatBottom? ' _InputContainer_flatBottom' : '') +
				(flatLeft? ' _InputContainer_flatLeft' : '') +
				(isFocused? ' _InputContainer_focused' : '') +
				(className? ' ' + className : '')
			}
		>
			<input 
				type='text'
				value={value}
				onPaste={e => disablePaste && e.preventDefault()}
				onFocus={()=>setIsFocused(true)}
				onBlur={()=>{setIsFocused(false); onBlur && onBlur()}}
				onChange={e => {
					action && action();
					textValidator(e.target.value)}
				}
				// onChange={e => textValidator(e.target.value)}
				placeholder={placeholder}
				tabIndex={ (tabIndex && !blocked)? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				readOnly={blocked? blocked : false}
				// className={`${blocked? 'blocked' : ''} ${className? className : ''}`}
				className={blocked? 'blocked' : ''}
				ref={reference => inputRef.current = reference}
				// onKeyDown={e => e.key === 'Enter' ? action && action() : e.key === 'Backspace' && backspaceHandler(e) }
				// onKeyDown={e => e.key === 'Enter' && action && action() }
			/>
			{maxValue && maxButton &&
				<div 
					className='maxButton'
					onClick={() => {
						action && action();
						setValue && setValue(maxValue)}
					}
				>
					MAX
				</div>
			}
			{currency &&
			<div className='currency'>
				{currency}
			</div>
			}
			{searchIcon && 
        <img src={searchIconImg} alt="searchIcon"/>
			}
		</div>
	)
}

export default Input;