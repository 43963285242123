import { iChartModal, iConfirmModal, iFarmingUnstakeModal, iFarmingStakeModal, iInfoModal, iLPTReturnModal, iSimpleModal, iTokenSelectModal, iTransactionModal, tModalName, iAddLiqModal, iClaimModal, iFarmModal, iConnectorsModal } from "../../interfaces/interfaces";

export enum eModalsActionTypes {
    DISCONNECT = 'MODALS/DISCONNECT',
    OPEN_NEW_MODAL = 'MODALS/OPEN_NEW_MODAL',
    CLOSE_MODAL = 'MODALS/CLOSE_MODAL',
}

export type tModalsAction =
    iClearModalsState |
    iOpenNewModal |
    iCloseModal
;

interface iClearModalsState {
    type: eModalsActionTypes.DISCONNECT
}
interface iOpenNewModal {
    type: eModalsActionTypes.OPEN_NEW_MODAL,
    payload:
        Omit<iSimpleModal, 'modalId'> |
        Omit<iInfoModal, 'modalId'> |
        Omit<iTokenSelectModal, 'modalId'> |
        Omit<iLPTReturnModal, 'modalId'> |
        Omit<iConfirmModal, 'modalId'> |
        Omit<iChartModal, 'modalId'> | 
        Omit<iTransactionModal, 'modalId'> | 
        Omit<iFarmingStakeModal, 'modalId'> |
        Omit<iFarmingUnstakeModal, 'modalId'> |
        Omit<iAddLiqModal, 'modalId'> |
        Omit<iClaimModal, 'modalId'> | 
        Omit<iFarmModal, 'modalId'> |
        Omit<iConnectorsModal, 'modalId'>
}

interface iCloseModal {
    type: eModalsActionTypes.CLOSE_MODAL,
    payload: {name: tModalName, id: number}
}