import React, { useEffect, useState } from "react";

import { useStoreDispatch, useStoreSelector } from "../../store/store";
import { getChromiaToken, getChrPools, getGtxClient } from "../../utils/Chromia/chromiaHelpers";

import './StartUpPage.scss';


const StartUpPage: React.FC = () => {

	const { isAccount } = useStoreSelector(store => store.app);
	const { setSlippagePercent, setIsIniting } = useStoreDispatch();
	
	const [isPTILoaded, setIsPTILoaded] = useState(false);
	const [isLSLoaded, setIsLSLoaded] = useState(false);
	const [isLSTLoaded, setIsLSTLoaded] = useState(false);
	const [isChromiaLoaded, setIsChromiaLoaded] = useState(false);

	useEffect(()=>{
		loadChromia();
		loadLocalStorage();
		
		// eslint-disable-next-line
	},[])

	useEffect(() => {
		if ( isChromiaLoaded){
			setIsIniting(false);
		} else {
			setIsIniting(true);
		}
		// eslint-disable-next-line
	}, [isPTILoaded, isLSLoaded, isLSTLoaded, isChromiaLoaded])

	const loadPtiConfig = async () => {
		setIsPTILoaded(true)
	}

	const loadLSTConfig = async () => {
		setIsLSTLoaded(true);
	}

	const loadLocalStorage = () => {
		const storedPercent = localStorage.getItem('LSSlippagePercent');
		if (storedPercent && +storedPercent <= 1) {
			setSlippagePercent(storedPercent);
		}
		setIsLSLoaded(true);
	}

	const loadChromia = async () => {
		const res = await (await getGtxClient()).query("ft4.get_all_assets", { page_size: 200, page_cursor: null });

		res.data.forEach(token => {
			if (!token.symbol.includes('ltv_') && !token.symbol.includes('stv_') && !token.symbol.includes('CHR_18') && !token.symbol.includes('CHR_6') && !token.symbol.includes('USDС')) {
				getChromiaToken(token.id.toString('hex'), token.decimals, token.symbol, undefined , token.symbol.substring(0, 2) === 'LP'? ['hidden'] : undefined);
			}
		})

		await getChrPools();

		setIsChromiaLoaded(true);

		loadPtiConfig();
		loadLSTConfig();
	}


	return(
	
		<div className="StartUpPage">
			{isAccount &&  `Loading...`}
		</div>
	)
}

export default StartUpPage;