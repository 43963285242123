import BigNumber from "bignumber.js";
// import BТ from "bignumber.js";
import { maxTick, minTick, q96 } from "../../constants";
import { iChrPool } from "../../interfaces/chromiaInterfaces";


export function formatVal(value: string | number, digits = 2) {
	if (typeof(value) === 'number') {
		value = value.toString();
	}
	let splitted = value.split('.');
	if (splitted[1]) {
		for (let i=1; i<digits; i++){
			splitted[1] = splitted[1] + '0';
		}
	}
	return splitted.length === 2
		? splitted[0] + '.' + splitted[1].substring(0, digits)
		: splitted[0]
	;
}

export function priceToSqrtPrice(price: string) {
  return ((new BigNumber(price)).sqrt()).mul((new BigNumber(2)).pow(96));
}

export function sqrtPriceToPrice(sqrtPrice: string) {
  return new BigNumber(sqrtPrice).div((new BigNumber(2)).pow(96)).pow(2).toString()
}

/**
 * Возвращает sqrt price, соответствующую переданному тику 
 * @param tick 
 * @param isSqrtPrice флаг, можно вернуть обычную цену(не sqrt) если флаг = false
 * @returns sqrt price
 */
export function tickToPrice(tick: number, isSqrtPrice = true) {
  const res =  new BigNumber(Math.pow(1.0000499987500624, tick).toString());


  if (!isSqrtPrice) {
    return (res.mul(q96)).mul(res.mul(q96)).div(q96).div(q96)
  }
  return res.mul(q96)
}

/**
 * price to tick(not sqrt)
 * @param price 
 * @returns tick
 */
export function sqrtPriceToTick(price: string) {
  var l_tick = minTick;
  var r_tick = maxTick;
  var cur_tick = 0;
  var cur_price;

  const sqrtPrice = new BigNumber(priceToSqrtPrice(price).toString());

  while(l_tick + 1 < r_tick) {
    cur_tick = r_tick - Math.abs(r_tick - l_tick) / 2;
    cur_price = tickToPrice(cur_tick);
    
      if(sqrtPrice.greaterThan(cur_price)) 
          l_tick = cur_tick;
      else if (sqrtPrice.lessThan(cur_price))
          r_tick = cur_tick;
      else break;
  }

  return cur_tick;
}

function liquidity0(amount: BigNumber, pa: BigNumber, pb: BigNumber) {
  if (pa.gt(pb)) {
    const temp = pa;
    pa = pb;
    pb = temp;
  }
  return (amount.mul(pa.mul(pb)).div(q96)).div(pb.sub(pa))
}

function liquidity1(amount: BigNumber, pa: BigNumber, pb: BigNumber) {
  if (pa.gt(pb)) {
    const temp = pa;
    pa = pb;
    pb = temp;
  }
  return (amount.mul(q96)).div(pb.sub(pa))
}

export function calcSingleMint(pool: iChrPool, amount0: string, amount1: string, lowerTick: number, upperTick: number) {
  const amount0BN = new BigNumber(amount0);
  const amount1BN = new BigNumber(amount1);
  const curSqrtPriceBN = new BigNumber(pool.sqrt_price);
  const lowerSqrtPriceBN = tickToPrice(lowerTick);
  const upperSqrtPriceBN = tickToPrice(upperTick);
  const q96BN = new BigNumber(q96);
  let liq;

  const pu_pl =  upperSqrtPriceBN.sub(lowerSqrtPriceBN);

  if (curSqrtPriceBN.gt(upperSqrtPriceBN)) {
    liq = amount1BN.mul(q96BN).div(pu_pl);
    return liq
  }
  else if (curSqrtPriceBN.lt(lowerSqrtPriceBN)) {
    liq = amount0BN.mul(upperSqrtPriceBN).mul(lowerSqrtPriceBN).div(q96BN).div(pu_pl);
    return liq
  }
  

  const liq0 = liquidity0(amount0BN, curSqrtPriceBN, lowerSqrtPriceBN);
  const liq1 = liquidity1(amount1BN, curSqrtPriceBN, upperSqrtPriceBN);


  if (liq0.isZero()) {
    return liq1
  }
  else if (liq1.isZero()) {
    return liq0
  }
  else {
    if (liq0.lt(liq1)) {
      return liq0
    }
    else {
      return liq1
    }
  }
}

export function calcMint(pool: iChrPool, tok: string, tokInAmt: string, tick_lower: number, tick_upper: number) {
  const price_lower = tickToPrice(tick_lower)
  const price_upper = tickToPrice(tick_upper)
  const priceCurBN = new BigNumber(pool.sqrt_price);
  const tokInAmtBN = new BigNumber(tokInAmt); 

  const q96BN = new BigNumber(q96)

  if (+tokInAmt < 0) {
    throw new Error('zero in amount');
  }
  if (tick_lower > tick_upper) {
    throw new Error('wrong ticks1');
  }

  let liq = new BigNumber(0);
  let secTokAmt = new BigNumber(0);

  if (tok === pool.token0) {
      if (priceCurBN.gte(price_upper))
        throw new Error("t0_in_amt must be zero")
      
      if (priceCurBN.lt(price_lower)) {
          liq = tokInAmtBN.mul(price_upper).mul(price_lower).div(q96BN).div(price_upper.sub(price_lower));
      }
      else {
          liq = tokInAmtBN.mul(price_upper).mul(priceCurBN).div(q96BN).div(price_upper.sub(priceCurBN));
          secTokAmt = liq.mul(priceCurBN.sub(price_lower)).div(q96BN);
      }
  }
  else {
      if (priceCurBN.lte(price_lower))
        throw new Error("t1_in_amt must be zero")
      
      if(priceCurBN.gt(price_upper)) {
          liq = tokInAmtBN.mul(q96BN).div(price_upper.sub(price_lower));
      }
      else {
          liq = tokInAmtBN.mul(q96BN).div(priceCurBN.sub(price_lower));
          secTokAmt = liq.mul(q96BN).mul(price_upper.sub(priceCurBN)).div(price_upper).div(priceCurBN);
      }
  }

  return {
    amount: secTokAmt?.toFixed() || '0', 
    liquidity: liq.toFixed() || '0'
  }
}

export function calcBurn2(lowerTick: number, upperTick: number, liq2: string, sqrt_cur_p: string) {
  let x_, y_;
  const liq2BN = new BigNumber(liq2);
  const sqrtCrPriceBN = new BigNumber(sqrt_cur_p);
  const lowPrice = tickToPrice(lowerTick);
  const uppPrice = tickToPrice(upperTick);

  if ((lowPrice.lte(sqrtCrPriceBN )) && (sqrtCrPriceBN <= uppPrice)) {
    x_ = calc_amount0(liq2BN, sqrtCrPriceBN, uppPrice)
    y_ = calc_amount1(liq2BN, lowPrice, sqrtCrPriceBN)
  }
  else if (sqrtCrPriceBN.lt(lowPrice)) {
    x_ = calc_amount0(liq2BN, lowPrice, uppPrice)
    y_ = new BigNumber(0)
  }
  else if (uppPrice.lt(sqrtCrPriceBN)) {
    x_ = new BigNumber(0)
    y_ = calc_amount1(liq2BN, lowPrice, uppPrice)
  }
  return {x_, y_}
}

export function calcBurn(burnLiq: string, lowerTick: number, priceCur: string, upperTick: number) {
  const lowPrice = tickToPrice(lowerTick);
  const uppPrice = tickToPrice(upperTick);
  const curPriceBN = new BigNumber(priceCur);
  const liq = new BigNumber(burnLiq)

  if (+burnLiq < 0) {
    throw new Error('zero liq')
  }

  let t0_out = new BigNumber(0);
  let t1_out = new BigNumber(0);

  if(curPriceBN.gt(uppPrice)) {
      t1_out = liq.mul(uppPrice.sub(lowPrice)).div(q96);
  }
  else if (curPriceBN.lt(lowPrice)) {
      t0_out = liq.mul(q96).mul(uppPrice.sub(lowPrice)).div(uppPrice).div(lowPrice);
  }
  else {
      t0_out = liq.mul(q96).mul(uppPrice.sub(curPriceBN)).div(uppPrice).div(curPriceBN);
      t1_out = liq.mul(curPriceBN.sub(lowPrice)).div(q96);
  }

  return {t0_out, t1_out};
}

function calc_amount0(liq: BigNumber, pa: BigNumber, pb: BigNumber) {
  if (pa > pb) {
    const temp = pa;
    pa = pb;
    pb = temp;
  }
  return (liq.mul(q96).mul(pb.sub(pa).abs())).div(pb).div(pa)
}

function calc_amount1(liq: BigNumber, pa: BigNumber, pb: BigNumber) {
  if (pa > pb) {
    const temp = pa;
    pa = pb;
    pb = temp;
  }
    return (liq.mul(pb.sub(pa).abs()).div(q96))
}

export function calcSwap(pool: iChrPool, tok: string | undefined, amtIn: string, ticksData: any, currentPrice: string) {
  const amtInBN = new BigNumber(amtIn);
  const feeBN = new BigNumber(+pool.trading_fees/100);

  if (amtInBN.lt(new BigNumber(0))) {
    throw new Error("zero in amt");
  }
  if (new BigNumber(pool.liquidity).lte(new BigNumber(0))) {
    throw new Error("zero pool liq");
  }

  const amtSwapBN = amtInBN.sub(amtInBN.mul(feeBN));

  let res;
 
  if (tok === pool.token0) {
    res = calc_swap_t0_t1(ticksData, new BigNumber(currentPrice.toString()), amtSwapBN);
  }
  else {
    res = calc_swap_t1_t0(ticksData, new BigNumber(currentPrice.toString()), amtSwapBN);
  }

  return {
    outAmount: res.out_amt.toFixed(), 
    tickNew: res.price_new, 
    price: res.price_new
  }
  
}

export function calc_swap_t0_t1(ticksData: any, currentPriceBN: BigNumber, amt_swap:  BigNumber) {
  let ticks = ticksData.filter((tick: any) => {
    return tickToPrice(+tick.tick).lte(currentPriceBN)
  })

  ticks = ticks.map((tick: any) => {
    return {...tick, liquidityNet: tick.liq_net}
  })
  // var ticks = Tick @* {.pool == pool, .price <= price_cur }(.tick, @sort_desc .price, .liq_net, .liq_gross);

  var L = new BigNumber(0);//cur liq

  for (const t of ticks) { 
    L = L.add(new BigNumber(t.liquidityNet)); 
  }

  let price_new = new BigNumber(0);
  let amt_cur_swap = new BigNumber(0);
  let sum_amt_0 = new BigNumber(0); 
  let sum_amt_1 = new BigNumber(0);
  const q96BN = new BigNumber(q96);

  for (let i = ticks.length - 1; i >= 0; i--) { 
    price_new = tickToPrice(+ticks[i].tick);
    
    amt_cur_swap = (L.mul(q96BN).mul(currentPriceBN.sub(price_new))).div(price_new).div(currentPriceBN);

    if (amt_cur_swap.add(sum_amt_0).gt(amt_swap)) {
        amt_cur_swap = amt_swap.sub(sum_amt_0);
        price_new = (currentPriceBN.mul(L)).mul(q96BN).div(L.mul(q96BN).add(currentPriceBN.mul(amt_cur_swap)));
    }
        
    sum_amt_0 = sum_amt_0.add(amt_cur_swap);
    sum_amt_1 = sum_amt_1.add((currentPriceBN.sub(price_new)).mul(L).div(q96BN));

    if (sum_amt_0.gte(amt_swap)) {
      break;
    }

    currentPriceBN = price_new;

    L = L.sub(ticks[i].liquidityNet);
  }
  
  return {
    out_amt: sum_amt_1, 
    price_new: price_new.div((new BigNumber(2)).pow(96)).pow(2).toFixed()
  }
}

function calc_swap_t1_t0(ticksData: any, currentPriceBN: BigNumber, amt_swap:  BigNumber) {
  
  const ticks = ticksData.map((tick: any) => {
    return {...tick, liquidityNet: tick.liq_net}
  })

  let k = 0;
  for (const tick of ticks) {  
      if (tickToPrice(+tick.tick).gte(currentPriceBN)) break;
      k += 1;
  }

      
  let L = new BigNumber(0);//cur liq

  for (let i = 0; i < k; i++) {
    L = L.add(new BigNumber(ticks[i].liquidityNet)); 
  } 


  let cur_tick = ticks[k];
  let price_new = new BigNumber(0);
  let amt_cur_swap = new BigNumber(0);
  let sum_amt_0 = new BigNumber(0); 
  let sum_amt_1 = new BigNumber(0);
  const q96BN = new BigNumber(q96)


  while (k < ticks.length) { 
      cur_tick = ticks[k];
      price_new = tickToPrice(+cur_tick.tick);
      
      amt_cur_swap = L.mul(price_new.sub(currentPriceBN));

      if ((amt_cur_swap.div(q96BN).add(sum_amt_1)).gt(amt_swap)) {
          amt_cur_swap = (amt_swap.sub(sum_amt_1)).mul(q96BN);
          price_new = (amt_cur_swap.div(L)).add(currentPriceBN);
      }
      sum_amt_0 = sum_amt_0.add((price_new.sub(currentPriceBN)).mul(L).mul(q96BN).div(currentPriceBN).div(price_new));
      sum_amt_1 = sum_amt_1.add(amt_cur_swap.div(q96BN));
      
      if (sum_amt_1.gte(amt_swap)) break;

      currentPriceBN = price_new;
      L = L.add(cur_tick.liquidityNet);

      k += 1;
  }

  return {
    out_amt: sum_amt_0, 
    price_new: price_new.div((new BigNumber(2)).pow(96)).pow(2).toFixed()
  }
}

export function calcSwapIn(pool:iChrPool, tokOut: string, amtOut: string, ticksData: any, currentPrice: string) {
      // require(dec_to_asset(tok_out, amt_out) > 0, "tool little out_tok_amt");
      // require(amt_out < get_bal_dec(pool.acc, tok_out) * 0.99, "too more out_tok amt");
  
  const amtOutBN = new BigNumber(amtOut);
  const q96BN = new BigNumber(q96);
  let currentPriceBN = new BigNumber(currentPrice);
  currentPriceBN = currentPriceBN.pow(2).div(q96BN).div(q96BN)

  const tokIn = (tokOut === pool.token0) 
    ? pool.token1 
    : pool.token0;

  let curAmtIn = (tokOut === pool.token0) 
    ? amtOutBN.mul(currentPriceBN) 
    : amtOutBN.div(currentPriceBN);

  let swapRes = calcSwap(pool, tokIn, curAmtIn.toString(), ticksData, currentPrice);
  
  const maxDiff = amtOutBN.mul(new BigNumber(0.0001));

  if ((new BigNumber(swapRes.outAmount).sub(amtOutBN).abs()).lte(maxDiff)) 
    return {
      outAmount: curAmtIn.toFixed(), 
      tickNew: swapRes.tickNew, 
      price: swapRes.price
    };

  let curStep = curAmtIn.div(new BigNumber(2));

  if (new BigNumber(swapRes.outAmount).gt(amtOutBN)) {
      if (new BigNumber(calcSwap(pool, tokIn, curAmtIn.sub(curStep).toFixed(), ticksData, currentPrice).outAmount).gt(amtOutBN))
          curStep = curAmtIn.mul(new BigNumber(0.99));
  }
  else {
      if (new BigNumber(calcSwap(pool, tokIn, curAmtIn.add(curStep).toFixed(), ticksData, currentPrice).outAmount).lt(amtOutBN))
          curStep = curAmtIn.mul(new BigNumber(100));
  }

  
  for (let i = 0; i < 25; i++) {
      swapRes = calcSwap(pool, tokIn, curAmtIn.toFixed(), ticksData, currentPrice);

      if ((new BigNumber(swapRes.outAmount).sub(amtOutBN)).abs().lte(maxDiff)) {
        break;

      }

      if (new BigNumber(swapRes.outAmount).gt(amtOutBN)) {
        curAmtIn = curAmtIn.sub(curStep);
      }
      else {
        curAmtIn = curAmtIn.add(curStep);
      }

      curStep = curStep.div(new BigNumber(2));
  }

  return {
    outAmount: curAmtIn.toFixed(), 
    tickNew: swapRes.tickNew, 
    price: swapRes.price
  }
}