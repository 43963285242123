import React, { useEffect } from "react";

import { useStoreDispatch, useStoreSelector } from "../../../store/store";
import { updateChromiaBalances } from "../../../utils/Chromia/chromiaHelpers";
// import { getAllowance, getBalance } from "../../../utils/observers";


const BalanceUpdater: React.FC = () => {

	const { tokens, chrClientAddr } = useStoreSelector(store => store.app);
	const { clearBalances } = useStoreDispatch();
	
	useEffect(()=>{
		// let interval: NodeJS.Timer;
		if (!chrClientAddr){	
			clearBalances();	// maybe some check?
			return;
		}
	
		if (chrClientAddr){
			updateChromiaBalances();
		}
		/* interval = setInterval(()=>{
			updateBalances();
		}, 60000) */

		/* return () => {
			interval && clearInterval(interval);
		} */
		// eslint-disable-next-line
	},[tokens, chrClientAddr])

	/* const updateBalances = () => {
		tokens.forEach(token => {
			getBalance({token: token}, {update: true});
			getAllowance({token: token, type: 'token'}, {update: true});
		});
	} */


	return null;
}

export default BalanceUpdater;