import React, { useState } from "react";

import { useStoreDispatch } from "../../../store/store";
import { useTabIndex } from "../../../hooks/useTabIndex";
import { iConnectorsModal, iInfoModal } from "../../../interfaces/interfaces";

import './ConnectorsModal.scss';

import Button from "../../UI/Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import metaMaskSDK from "../../../assets/images/metamask.svg";
import WalletConnect from "../../../assets/images/walletConnect.svg";
import { useConnect } from "wagmi";
import { metaMask, walletConnect } from "wagmi/connectors";
// import Warning from '../../../assets/warning.png';
// import Success from '../../../assets/success.png';


const ConnectorsModal: React.FC <iConnectorsModal> = ({name: modalName, modalId}) =>{

	const { closeModal } = useStoreDispatch();
  const { connectors, connect } = useConnect();

	const [isClosing, setIsClosing] = useState(false);
	const isTabIndex = useTabIndex({position: 'last', modalName: modalName});

	function closeInfoModal(){
		setIsClosing(true);
		setTimeout(()=>{
			closeModal(modalName, modalId);
		}, 490);
	}


  const getConnector = (name: string) => {
    return connectors.find((connector) => connector.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()))
  }

  const getImage = (name: string) => {
    if (name.toLocaleLowerCase().includes('metamask')) return metaMaskSDK
    else return WalletConnect
  }


	return (
		<ModalWrapper
			className="InfoModal"
			isModalClosing={isClosing}
			closeListenerSettings={{name: modalName, modalId, callback: closeInfoModal}}
		>
      {connectors.map((connector) => {
        return <div
          onClick={() => connect({connector})}
          className=""
        >
          <img src={getImage(connector.name)} alt="mm"/>
        </div>
      })}
      
		</ModalWrapper>
	)
}

export default ConnectorsModal;