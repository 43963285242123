import { tTokenFeatures } from "../interfaces/interfaces";
import { Currency } from "./Currency";

/**
 * A currency is any fungible financial instrument, including Ether, all ERC20 tokens, and other chain-native currencies
 */
export abstract class BaseCurrency {
	/**
	 * Returns whether the currency is native to the chain and must be wrapped (e.g. Ether)
	 */
	abstract readonly isNative: boolean;
	/**
	 * Returns whether the currency is a token that is usable in Uniswap without wrapping
	 */
	abstract readonly isToken: boolean;
	/**
	 * The chain ID on which this currency resides
	 */
	readonly chainId: number;
	/**
	 * The decimals used in representing currency amounts
	 */
	readonly decimals: number;
	/**
	 * The name of the currency, i.e. a descriptive textual non-unique identifier
	 */
	readonly name: string;
	/**
	 * The symbol of the currency, i.e. a short textual non-unique identifier
	 */
	readonly symbol?: string;
	/**
	 * 
	 */
	readonly features?: tTokenFeatures[];
	/**
	 * Constructs an instance of the base class `BaseCurrency`.
	 * @param chainId the chain ID on which this currency resides
	 * @param decimals decimals of the currency
	 * @param name of the currency
	 * @param symbol symbol of the currency
	 */
	protected constructor(chainId: number, decimals: number, name: string, symbol?: string, features?: tTokenFeatures[]) {
		// invariant(Number.isSafeInteger(chainId), 'CHAIN_ID')
		if (!Number.isSafeInteger(chainId)) throw new Error();
		// invariant(decimals >= 0 && decimals < 255 && Number.isInteger(decimals), 'DECIMALS')
		if (!(decimals >= 0 && decimals < 255 && Number.isInteger(decimals))) throw new Error();

		this.chainId = chainId;
		this.decimals = decimals;
		this.name = name;
		this.symbol = symbol;
		this.features = features;
	}
	/**
	 * Returns whether this currency is functionally equivalent to the other currency
	 * @param other the other currency
	 */
	abstract equals(other: Currency): boolean;
	/**
	 * Return the wrapped version of this currency that can be used with the Uniswap contracts. Currencies must
	 * implement this to be used in Uniswap
	 */
	// abstract get wrapped(): Token;
}