import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useStoreSelector } from "../../store/store";


import Loader from "../UI/Loader/Loader";
import StartUpPage from "../StartUpPage/StartUpPage";
import BalanceUpdater from "../utilComponents/BalanceUpdater/BalanceUpdater";
import SettingsPage from "../SettingsPage/SettingsPage";
import MyLiqPage from "../MyLiqPage/MyLiqPage";
import PoolPage from "../PoolPage/PoolPage";
import FarmingPage from "../FarmingPage/FarmingPage";
import LandingPage from "../LandingPage/LandingPage";
import RegisterPage from "../RegisterPage/RegisterPage";
import SwapPage from "../SwapPage/SwapPage";

import './App.scss';

const LiquidityPageV2 = lazy(() => import("../LiquidityPageV2/LiquidityPageV2"));
// const FarmsPage = lazy(() => import("../FarmsPage/FarmsPage"));


const App: React.FC = () => {

	const { isIniting, isGrabbingCursor } = useStoreSelector(store => store.UI);
	const { chrClientAddr, isAccount } = useStoreSelector(store => store.app);


	useEffect(() => {
	
	}, [isAccount, chrClientAddr])


	return (
		<>
			{isIniting 
				? <StartUpPage/>
				:
						<div
							className={
								'_App' +
								(isGrabbingCursor? ' _App_cursorGrabbed' : '')
							}
						>
							{!isAccount 
								? 
									<Switch>
										<Route exact path='/register' component={RegisterPage}/>
										<Redirect to='/register'/> 
									</Switch>
								: 
								<div className="subsubmain">
									<BalanceUpdater/>
									<div className='contentWrapper' id='CW2'>
										<Suspense
											fallback={<Loader/>}
										>
											<Switch>
												<Route exact path='/swap' component={SwapPage}/>
												<Route exact path='/pool' component={PoolPage}/>
												<Route exact path='/settings' component={SettingsPage}/>
												<Route exact path='/liquidity' component={LiquidityPageV2}/>
												<Route exact path='/my-liquidity' component={MyLiqPage}/>
												<Route exact path='/farming' component={FarmingPage}/>
												<Route exact path='/landing' component={LandingPage}/>
												<Redirect to='/swap'/>
											</Switch>
										</Suspense>
									</div>
								</div>
							}
						</div>
			}
		</>
			)
}

export default App;