import { defaultSlippagePercent, iAppState } from "../../interfaces/interfaces";
import { tAppAction, eAppActionTypes} from "./appTypes";

const initialState: iAppState = {
	clientAddr: null,
	api: null,
	web3Provider: null,
	web3: null,
	// config: null,
	PScontract: null,
	// isTransaction: false,
	// isNetworkIssues: false,
	factory: null,
	V3Factory: null,
	masterChefContract: null,
	tokens: [],
	tokenBalances: {},
	allowances: {},
	// tokenAllowances: {},
	tokenContracts: {},
	pairs: [],
	pairBalances: {},
	// pairAllowances: {},
	pairContracts: {},
	pools: [],
	poolContracts: {},
	poolPrices: {},
	slippagePercent: defaultSlippagePercent,
    chartPeriod: 'hour',
	lpFarms: [],
	openedFarms: [],
	v3PoolNFTRouters: {},

	gtxClient: null,
	session: null,
	chrClientAddr: null,
	chrPools: [],
	chrPoolBalances: {},
	isAccount: true,
	transactionStatus: null,
	selectedPos: null,
	selectedNewPos: null,
	isRegistered: false,
	accessToken: null
}

export const appReducer = (state = initialState, action: tAppAction) : iAppState => {
	switch (action.type){

		case eAppActionTypes.DISCONNECT:
			return {
				...state,
				clientAddr: null,
				tokenBalances: {},
				allowances: {},
				pairBalances: {},
				// tokenAllowances: {}

				session: null,
				chrClientAddr: null,
			};

		case eAppActionTypes.SET_CLIENT_ADDR:
			return {...state, clientAddr: action.payload};

		case eAppActionTypes.SET_IS_ACCOUNT:
			return {...state, isAccount: action.payload};

		case eAppActionTypes.SET_API:
			return {...state, api: action.payload};

		case eAppActionTypes.SET_WEB3_PROVIDER:
			return {...state, web3Provider: action.payload};

		case eAppActionTypes.SET_WEB3:
			return {...state, web3: action.payload};

		case eAppActionTypes.SET_PS_CONTRACT:
			return {...state, PScontract: action.payload};

		case eAppActionTypes.SET_TRANSACTION_STATUS:
			return {...state, transactionStatus: action.payload}

		/*
		case eAppActionTypes.SET_CONFIG:
			return {...state, config: action.payload};

		case eAppActionTypes.SET_IS_TRANSACTION:
			return {...state, isTransaction: action.payload};

		case eAppActionTypes.SET_IS_NETWORK_ISSUES:
			return {...state, isNetworkIssues: true};
		*/

		case eAppActionTypes.SET_FACTORY:
			return {...state, factory: action.payload};

		case eAppActionTypes.SET_V3_FACTORY:
			return {...state, V3Factory: action.payload};

		case eAppActionTypes.ADD_TOKEN:
			const tokensSlice = state.tokens.slice();
			tokensSlice.push(action.payload);
			return {...state, tokens: tokensSlice};

		case eAppActionTypes.SET_TOKEN_BALANCE:
			const newTokenBalances = {...state.tokenBalances};
			newTokenBalances[action.payload.address] = action.payload.balance;
			return {...state, tokenBalances: newTokenBalances};
		
		case eAppActionTypes.SET_ALLOWANCE: 
			const newAllowances = {...state.allowances};
			newAllowances[action.payload.address] = action.payload.allowance;
			return {...state, allowances: newAllowances};
			

		/* case eAppActionTypes.SET_TOKEN_ALLOWANCE:
			const newTokenAllowances = {...state.tokenAllowances};
			newTokenAllowances[action.payload.address] = action.payload.allowance;
			return {...state, tokenAllowances: newTokenAllowances}; */

		case eAppActionTypes.SET_TOKEN_CONTRACT:
			const newTokenContracts = {...state.tokenContracts};
			newTokenContracts[action.payload.address] = action.payload.contract;
			return {...state, tokenContracts: newTokenContracts};

		case eAppActionTypes.SET_PAIR:
			const pairsSlice = state.pairs.slice();
			const foundPairIndex = pairsSlice.findIndex(pair =>
				(pair.currencyA.equals(action.payload.currencyA) && pair.currencyB.equals(action.payload.currencyB)) ||
				(pair.currencyA.equals(action.payload.currencyB) && pair.currencyB.equals(action.payload.currencyA))
			);
			if (foundPairIndex < 0){    // pair not exists
				pairsSlice.push(action.payload);
			} else {
				pairsSlice[foundPairIndex] = action.payload;
			}
			return {...state, pairs: pairsSlice};

		case eAppActionTypes.SET_PAIR_BALANCE:
			const newPairBalances = {...state.pairBalances};
			newPairBalances[action.payload.address] = action.payload.balance;
			return {...state, pairBalances: newPairBalances};

		/* case eAppActionTypes.SET_PAIR_ALLOWANCE:
			const newPairAllowances = {...state.pairAllowances};
			newPairAllowances[action.payload.address] = action.payload.allowance;
			return {...state, pairAllowances: newPairAllowances}; */

		case eAppActionTypes.SET_PAIR_CONTRACT:
			const newPairContracts = {...state.pairContracts};
			newPairContracts[action.payload.address] = action.payload.contract;
			return {...state, pairContracts: newPairContracts};

		case eAppActionTypes.ADD_POOL:
			const poolsSlice = state.pools.slice();
			poolsSlice.push(action.payload);
			return {...state, pools: poolsSlice};

		case eAppActionTypes.SET_POOL_CONTRACT:
			const newPoolContracts = {...state.poolContracts};
			newPoolContracts[action.payload.address] = action.payload.contract;
			return {...state, poolContracts: newPoolContracts};

		case eAppActionTypes.CLEAR_BALANCES:
			return {
				...state,
				allowances: {},
				// tokenAllowances: {},
				tokenBalances: {},
				// pairAllowances: {},
				pairBalances: {},
			};

		case eAppActionTypes.SET_SLIPPAGE_PERCENT:
			return {...state, slippagePercent: action.payload};

		case eAppActionTypes.SET_SELECTED_POS:
			return {...state, selectedPos: action.payload};

		case eAppActionTypes.SET_SELECTED_NEW_POS:
			return {...state, selectedNewPos: action.payload};

		case eAppActionTypes.SET_ACCESS_TOKEN:
			return {...state, accessToken: action.payload}
		
		case eAppActionTypes.SET_CHART_PERIOD:
			return {...state, chartPeriod: action.payload};

		case eAppActionTypes.SET_IS_REGISTERED:
			return {...state, isRegistered: action.payload};

		case eAppActionTypes.SET_FARMS:
			return {...state, lpFarms: action.payload};

		case eAppActionTypes.SET_OPENED_FARMS:
			return {...state, openedFarms: action.payload};

		case eAppActionTypes.SET_V3_POOL_NFT_ROUTER:
			const newV3PoolNFTContracts = {...state.v3PoolNFTRouters};
			newV3PoolNFTContracts[action.payload.chainId] = action.payload.contract;
			return {...state, v3PoolNFTRouters: newV3PoolNFTContracts};

		case eAppActionTypes.SET_GTX_CLIENT:
			return {...state, gtxClient: action.payload};

		case eAppActionTypes.SET_SESSION:
			return {...state, session: action.payload};

		case eAppActionTypes.SET_CHR_CLIENT_ADDR:
			return {...state, chrClientAddr: action.payload};

		case eAppActionTypes.SET_CHR_POOLS:
			return {...state, chrPools: action.payload}

		case eAppActionTypes.SET_CHR_POOL_BALANCE:
			const newChrPoolBalances = {...state.chrPoolBalances};
			newChrPoolBalances[action.payload.address] = action.payload.balance;
			return {...state, chrPoolBalances: newChrPoolBalances};

		default: return state;
	}
}