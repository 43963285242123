import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { WagmiProvider, createConfig, http } from 'wagmi';
// import { Web3Modal } from '@web3modal/react';
// import type { Chain } from "wagmi";
// import * as wagmiChains from "wagmi/chains";
import { bsc, mainnet } from "wagmi/chains";
// import { publicProvider } from "wagmi/providers/public";
import { store } from './store/store';
import { basename, production } from './settings';

import ModalsController from './components/modals/ModalsController/ModalsController';
import EscapeHandler from './components/utilComponents/EscapeHandler/EscapeHandler';
import UI from './components/UI/Test/UI.test';
import App from './components/App/App';
// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'

import './index.scss';
import WagmiWrapper from './components/utilComponents/WagmiWrapper/WagmiWrapper';
import { metaMask, walletConnect } from 'wagmi/connectors'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import PingPage from './components/PingPage/PingPage';

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

window.Buffer = require('buffer/').Buffer;
const projectId = '1dd4fbc33f393ff31d96077f860275cb';

// const ethereumClient = new EthereumClient(config, chains)

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const chains = [mainnet] as const;
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
	themeVariables: {
		'--w3m-font-family': 'Minecraft, sans-serif',
	}
})

root.render(
	<React.StrictMode>
        <WagmiProvider config={config}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<BrowserRouter basename={basename}>
							
							<Switch>
								{!production &&
									// <>	// not working
										<Route exact path='/ui-test' component={UI}/>
										// </Switch>
									}
								<Route exact path='/ping-page' component={PingPage}/>
								<Route>
									<EscapeHandler/>
									<WagmiWrapper/>
									<App/>
									<ModalsController/>
								</Route>
							</Switch>
						</BrowserRouter>
					</Provider>
				</QueryClientProvider>
        </WagmiProvider>

				{/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
	</React.StrictMode>
);