interface iProps {
	action?: (...args: any) => any,
}

const ZoomInIcon: React.FC <iProps> = ({action}) => {

	return (
		<svg onClick={action} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect y="12" width="2" height="2" fill="#4C4C4C"/>
			<rect x="2" y="10" width="2" height="2" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(1 0 0 -1 2 26)" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(-1 0 0 1 34 10)" fill="#4C4C4C"/>
			<rect x="34" y="26" width="2" height="2" transform="rotate(180 34 26)" fill="#4C4C4C"/>
			<rect x="2" y="8" width="2" height="2" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(1 0 0 -1 2 28)" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(-1 0 0 1 34 8)" fill="#4C4C4C"/>
			<rect x="34" y="28" width="2" height="2" transform="rotate(180 34 28)" fill="#4C4C4C"/>
			<rect x="4" y="6" width="2" height="2" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(1 0 0 -1 4 30)" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(-1 0 0 1 32 6)" fill="#4C4C4C"/>
			<rect x="32" y="30" width="2" height="2" transform="rotate(180 32 30)" fill="#4C4C4C"/>
			<rect x="8" y="2" width="2" height="2" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(1 0 0 -1 8 34)" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(-1 0 0 1 28 2)" fill="#4C4C4C"/>
			<rect x="28" y="34" width="2" height="2" transform="rotate(180 28 34)" fill="#4C4C4C"/>
			<rect x="6" y="4" width="2" height="2" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(1 0 0 -1 6 32)" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(-1 0 0 1 30 4)" fill="#4C4C4C"/>
			<rect x="30" y="32" width="2" height="2" transform="rotate(180 30 32)" fill="#4C4C4C"/>
			<rect x="10" y="2" width="2" height="2" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(1 0 0 -1 10 34)" fill="#4C4C4C"/>
			<rect width="2" height="2" transform="matrix(-1 0 0 1 26 2)" fill="#4C4C4C"/>
			<rect x="26" y="34" width="2" height="2" transform="rotate(180 26 34)" fill="#4C4C4C"/>
			<rect x="12" width="2" height="2" fill="#4C4C4C"/>
			<rect x="12" y="34" width="2" height="2" fill="#4C4C4C"/>
			<rect x="14" width="2" height="2" fill="#4C4C4C"/>
			<rect x="14" y="34" width="2" height="2" fill="#4C4C4C"/>
			<rect x="16" width="2" height="2" fill="#4C4C4C"/>
			<rect x="16" y="34" width="2" height="2" fill="#4C4C4C"/>
			<rect x="18" width="2" height="2" fill="#4C4C4C"/>
			<rect x="18" y="34" width="2" height="2" fill="#4C4C4C"/>
			<rect x="20" width="2" height="2" fill="#4C4C4C"/>
			<rect x="20" y="34" width="2" height="2" fill="#4C4C4C"/>
			<rect x="22" width="2" height="2" fill="#4C4C4C"/>
			<rect x="22" y="34" width="2" height="2" fill="#4C4C4C"/>
			<rect x="34" y="12" width="2" height="2" fill="#4C4C4C"/>
			<rect y="14" width="2" height="2" fill="#4C4C4C"/>
			<rect x="34" y="14" width="2" height="2" fill="#4C4C4C"/>
			<rect y="16" width="2" height="2" fill="#4C4C4C"/>
			<rect x="34" y="16" width="2" height="2" fill="#4C4C4C"/>
			<rect y="18" width="2" height="2" fill="#4C4C4C"/>
			<rect x="34" y="18" width="2" height="2" fill="#4C4C4C"/>
			<rect y="20" width="2" height="2" fill="#4C4C4C"/>
			<rect x="34" y="20" width="2" height="2" fill="#4C4C4C"/>
			<rect y="22" width="2" height="2" fill="#4C4C4C"/>
			<rect x="34" y="22" width="2" height="2" fill="#4C4C4C"/>
			<rect width="7.63649" height="1.90909" transform="matrix(-1 0 0 1 18.4541 8)" fill="#4C4C4C"/>
			<rect width="7.63649" height="1.90909" transform="matrix(-1 0 0 1 18.4541 21.3636)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 10.8184 9.90912)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 10.8184 19.4546)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 20.3633 9.90912)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 20.3633 19.4546)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 22.2725 21.3636)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 24.1807 21.3636)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 22.2725 23.2727)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 24.1807 23.2727)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 26.0918 23.2727)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 24.1807 25.1819)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 26.0918 25.1819)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 26.0918 27.0909)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 28 25.1819)" fill="#4C4C4C"/>
			<rect width="1.90912" height="1.90909" transform="matrix(-1 0 0 1 28 27.0909)" fill="#4C4C4C"/>
			<rect width="1.90912" height="7.63637" transform="matrix(-1 0 0 1 8.90918 11.8181)" fill="#4C4C4C"/>
			<rect width="1.90912" height="7.63637" transform="matrix(-1 0 0 1 22.2725 11.8181)" fill="#4C4C4C"/>
			<path d="M14 19.5V18V16.5H12.5H11V15H12.5H14V13.5V12H15.5V13.5V15H17H18.5V16.5H17H15.5V18V19.5H14Z" fill="#4C4C4C"/>
		</svg>
	)
}

export default ZoomInIcon;