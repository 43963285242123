import { tTokenFeatures } from '../interfaces/interfaces';
// import { checkValidAddress } from '../utils/checkValidAddress';
import { BaseCurrency } from './BaseCurrency';
import { Currency } from './Currency';

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends BaseCurrency {
	readonly isNative: false = false;
	readonly isToken: true = true;

	/**
	 * The contract address on the chain on which this token lives
	 */
	readonly address: string;

	// * @param bypassChecksum If true it only checks for length === 42, startsWith 0x and contains only hex characters
	/**
	 *
	 * @param chainId {@link BaseCurrency#chainId}
	 * @param address The contract address on the chain on which this token lives
	 * @param decimals {@link BaseCurrency#decimals}
	 * @param name {@link BaseCurrency#name}
	 * @param symbol {@link BaseCurrency#symbol}
	 */
	constructor(chainId: number, address: string, decimals: number, name: string, symbol?: string/*bypassChecksum?: boolean*/, features?: tTokenFeatures[]){
		super(chainId, decimals, name, symbol, features);
		// if (bypassChecksum) {
		// this.address = checkValidAddress(address).toLocaleLowerCase();
		this.address = address.toLocaleLowerCase();
		// } else {
		//   this.address = validateAndParseAddress(address)
		// }
	}

	/**
	 * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
	 * @param other token to compare
	 */
	equals(other: Currency): boolean {
		return other.isToken && this.chainId === other.chainId && this.address === other.address;
	};
	
	/**
	 * Returns true if the address of this token sorts before the address of the other token
	 * @param other other token to compare
	 * @throws if the tokens have the same address
	 * @throws if the tokens are on different chains
	 */
	public sortsBefore(other: Token): boolean {
		if (this.chainId !== other.chainId){
			throw new Error();
		}
		if (this.address.toLowerCase() === other.address.toLowerCase()){
			throw new Error();
		}
		return this.address.toLowerCase() < other.address.toLowerCase()
	}

	/**
	 * Return this token, which does not need to be wrapped
	 */
	public get wrapped(): Token {
		return this;
	}
}