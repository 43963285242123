import React, { useMemo } from "react";

import { iFarm, iFarmPos } from "../FarmingPage/FarmingPage";
import { getTokenIconURL } from "../../utils/helpers";
import { useStoreDispatch } from "../../store/store";

import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";
import outOfRangeIcon from '../../assets/images/OutOfRangeIcon.svg';
import inRangeIcon from '../../assets/images/InRangeIcon.svg';
import noFarmsIcon from '../../assets/images/noLiq.svg';
import noFarm from "../../assets/images/sadSmile.svg";
import Button from "../UI/Button/Button";

import './MyFarms.scss'


const MyFarms: React.FC <{allFarms: iFarm[] | null, setIsMyFarmOpen: any, setSelectedMyFarmPosition: any, getFarms: any}> = ({allFarms, setIsMyFarmOpen, setSelectedMyFarmPosition, getFarms}) => {
  const { setSelectedPos } = useStoreDispatch();


  const farms = useMemo(() => {
    return allFarms
  }, [allFarms])

  
  const selectPosition = (position: iFarmPos) => {
    setSelectedMyFarmPosition(position);
    setSelectedPos(position)
  }

  const allPositions = useMemo(() => {
    let positions:iFarmPos[] = [];
    farms?.forEach((farm) => { positions = positions.concat(farm.farmPos)});

    return positions
  }, [farms])

  const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])


	return (
    <div className="farmTable_wrapper">
      
        { isMobile ? 
          <div className="farmTable_wrapper">
            {allFarms !== null 
              ? allPositions.length > 0 
                ? farms?.map((farm) => {
                    return farm.farmPos.map((farmPos) => {
                  return <div className="farmItemInner farmItemInnerMobile">
                    <BlockPixelBorder
                      topBorderHeight="4px"
                      bottomBorderHeight="4px"
                    >
                      <div className="farmItem">
                        <div className="farmItemMobile__header">
                          <div className="rowMobile" key={farm.pool.toString('hex')}>
                            <img src={getTokenIconURL(farmPos.token1.name) || ''} className='tokIcon' alt="tok1Icon"/> <img src={getTokenIconURL(farmPos.token0.name) || ''} className='tokIcon tokIcon2' alt="tok2Icon"/> {farmPos.token1.name}/{farmPos.token0.name}
                          </div>
                        </div>
                        <div className="rowMobile">
                          {+farmPos.selectedPool.trading_fees * 100}%
                        </div>
                        <div>
                          <span className="row">
                            {+farmPos.minPrice.toFixed(5)}{'<->'}{farmPos.tick_upper < 887000 ?  farmPos.maxPrice.toFixed(4) : '∞'} {farmPos.token1.name} per {farmPos.token0.name}
                          </span> 
                        </div>
                        <div className="rowMobile rowMobile__flex">
                          <span className={`status ${farmPos.status === 'In range' ? 'inRange' : 'outOfRange'}`}> 
                            {farmPos.status} <img src={farmPos.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
                          </span> 
                          <div className="row" key={farm.pool.toString('hex')}>
                            <Button 
                              light={true}
                              small={true}
                              action={() => selectPosition(farmPos)}
                            >
                              View 
                            </Button>
                          </div>
                        </div>
                      </div>
                    </BlockPixelBorder>
                  </div>
                  })})
                : <div className="noFarms">
                    <img src={noFarm} alt="noFarm" className="noFarmIcon"/>
                    <div>No farms</div>
                </div>
                // : <div className="myFarmsnoFarms">
                //   <img src={noFarm} alt="noFarm" className="noFarmIcon"/>
                //   <div>No farms</div>
                // </div>
              : <div className="noFarms">
                  <img src={noFarmsIcon} alt="noFarms"/>
                  <div className="noFarms_title">
                    Farms will appear here.
                  </div>
                </div>
             }
          </div>
        :
          <BlockPixelBorder
            className="farmTable"
            topBorderHeight="8px"
            bottomBorderHeight="8px"
          >
            {
              allFarms !== null 
              ?
                allPositions.length > 0 
                ?
                <div className="farmTableInner">
                  <div className="column">
                    <div className="row column_header">
                      Pool
                    </div>
                    {farms?.map((farm) => {
                      return farm.farmPos.map((farmPos) => {
                        return <div className="row" key={`${farmPos.selectedPool.id}-${farmPos.tick_lower}-${farmPos.tick_upper}`}>
                          <img src={getTokenIconURL(farmPos.token1.name) || ''} className='tokIcon' alt="tok1Icon"/> <img src={getTokenIconURL(farmPos.token0.name) || ''} className='tokIcon tokIcon2' alt="tok2Icon"/> {farmPos.token1.name}/{farmPos.token0.name}
                        </div>
                      })
                    })}
                  </div>

                  <div className="column">
                    <div className="row column_header">
                      Fee
                    </div>
                    {farms?.map((farm) => {
                      return farm.farmPos.map((farmPos) => {
                        return <div className="row" key={`${farmPos.selectedPool.id}-${farmPos.tick_lower}-${farmPos.tick_upper}`}>
                          {+farmPos.selectedPool.trading_fees * 100}%
                        </div>
                      })
                    })}

                  </div>

                  <div className="column">
                    <div className="row column_header">
                      Min/Max price
                    </div>
                    {farms?.map((farm) => {
                      return farm.farmPos.map((farmPos) => {
                        return <div className="row" key={`${farmPos.selectedPool.id}-${farmPos.tick_lower}-${farmPos.tick_upper}`}>
                          {+farmPos.minPrice.toFixed(5)}{'<->'}{farmPos.tick_upper < 887000 ?  farmPos.maxPrice.toFixed(4) : '∞'} {farmPos.token1.name} per {farmPos.token0.name}
                        </div>
                      })
                    })}
                  </div>

                  <div className="column">
                    <div className="row column_header">
                      Status
                    </div>
                    {farms?.map((farm) => {
                      return farm.farmPos.map((farmPos) => {
                        return  <div className={`row status ${farmPos.status === 'In range' ? 'inRange' : 'outOfRange'}`} key={`${farmPos.selectedPool.id}-${farmPos.tick_lower}-${farmPos.tick_upper}`}>
                        {farmPos.status} <img src={farmPos.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
                      </div>
                      })
                    })}
                  </div>

                  <div className="column">
                    <div className="row column_header">
                    </div>
                      {farms?.map((farm) => {
                        return farm.farmPos.map((farmPos) => {
                          return <div className="row" key={`${farmPos.selectedPool.id}-${farmPos.tick_lower}-${farmPos.tick_upper}`}>
                            <Button 
                              light={true}
                              small={true}
                              action={() => selectPosition(farmPos)}
                            >
                              View 
                            </Button>
                          </div>
                        })
                      })}
                  
                  </div>

                </div>
                : <div className="noFarms">
                    <img src={noFarm} alt="noFarm" className="noFarmIcon"/>
                    <div>No farms</div>
                  </div>
              : 
                <div className="noFarms">
                  <img src={noFarmsIcon} alt="noFarms"/>
                  <div className="noFarms_title">
                    Farms will appear here.
                  </div>
                </div>
            }
          </BlockPixelBorder>
        }
    </div>
	)
}

export default MyFarms;