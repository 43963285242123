import React, { useMemo } from "react";
import { getTokenIconURL, toFixed} from "../../utils/helpers";
import Button from "../UI/Button/Button";
import { iPositionCard } from "../../interfaces/interfaces";
import inRangeIcon from '../../assets/images/InRangeIcon.svg';
import outOfRangeIcon from '../../assets/images/OutOfRangeIcon.svg';
import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";

import './PositionCard.scss';


const PositionCard: React.FC <iPositionCard> = ({position, getPositions, setSelectedPosition}) => {

  const selectPosition = () => {
    setSelectedPosition(position)
  }

  const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])


	return (
    <BlockPixelBorder
      leftBorderWidth="4px"
      rightBorderWidth="4px"
    >
      <div className='position'>
        <div>
          <div className="header">
            <div className="info">
              <img src={getTokenIconURL(position.token1.name) || ''} className='tokIcon' alt="tok1Icon"/>
              <img src={getTokenIconURL(position.token0.name) || ''} className='tokIcon tokIcon2' alt="tok0Icon"/>
              {position.token1.name}/{position.token0.name} <span className="fee">{+position.selectedPool.trading_fees * 100}%</span>
            </div>
            <div className={`status ${position.status === 'In range' ? 'inRange' : 'outOfRange'}`}>
              {position.status} <img src={position.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
            </div>
          
          </div>
            {isMobile
              ? <div className="rangeWrapper__flex">
                  <div className="range">
                    <div className="value">
                      <span className="titleMobile">Min:</span>{toFixed(position.minPrice.toFixed(), 4)} {position.token1.name} per {position.token0.name}
                    </div>
                    <div className="value">
                      <span className="titleMobile">Max: </span>{position.tick_upper < 887000 ?  toFixed(position.maxPrice.toFixed(), 4) : '∞'} {position.token1.name} per {position.token0.name}
                    </div>
                  </div>
                  <div className="viewButton_wrapper">
                    <Button
                      action={selectPosition}
                      light={true}
                      small={true}
                    >
                      View
                    </Button>
                  </div>
                </div> 
              : <div className="range">
                  <div className="title">
                    Min:
                  </div>
                  <div className="value">
                    {toFixed(position.minPrice.toFixed(), 4)} {position.token1.name} per {position.token0.name}
                  </div>
                  <div className="arrowRange">
                    {' <-> '}
                  </div>
                  <div className="title">
                    Max:
                  </div>
                  <div className="value">
                  {position.tick_upper < 887000 ?  toFixed(position.maxPrice.toFixed(), 4) : '∞'} {position.token1.name} per {position.token0.name}
                  </div>
                </div>
            }
        </div>
        {!isMobile && 
          <div className="viewButton_wrapper">
            <Button
              action={selectPosition}
              light={true}
              small={true}
            >
              View
            </Button>
          </div>
        }
      </div>
    </BlockPixelBorder>
	)
}

export default PositionCard;