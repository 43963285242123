import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import closeIcon from '../../assets/images/closeIcon.svg';
import pageArrow from '../../assets/images/pageArrow.svg';

import './MobileMenu.scss';

const pages = ['swap', 'liquidity', 'liquidity-pdm', 'pools-info', 'my-liquidity', 'pool', 'farming'] as const;
type tPages = typeof pages[number];
interface iProps {
	className?: string,
	tabIndex?: number | boolean,
	closeMenuAction: (...args: any) => any,
}


const MobileMenu: React.FC <iProps> = ({className, tabIndex, closeMenuAction}) => {

	const [currentPage, setCurrentPage] = useState<tPages>();
	const location = useLocation(); 

  useEffect(()=>{     // page observer
		for (let key of pages){
			if (location.pathname === '/'+key){
				setCurrentPage(key as tPages);
				return;
			}
		}
		setCurrentPage(undefined);
	}, [location])



  const handleOutMenuClick = (event: any) => {
    if (event.target.className !== 'MenuPages_activePage' &&
      event.target.className !== 'MenuPages_inactivePage' &&
      event.target.className !== 'mobileMenu__content'
    ) {
      closeMenuAction(false)
    }
  }


	return (
    <div className="mobileMenu"
      onClick={(event) => handleOutMenuClick(event)}
    >
      <div
        className={
          'mobileMenu__content' +
          (className? ` ${className}` : '')
        }
      >
        <div className="mobileMenu__header">
          <div className="title">
            Menu
          </div>
          <div className="circle">
            <img 
              src={closeIcon} 
              className="closeIcon" 
              alt="closeIcon" 
              onClick={() => closeMenuAction(false)}
            />
          </div>
        </div>
        <div className="menu">
          <div className="menuItem">
            {currentPage === 'swap' &&  
              <img 
                src={pageArrow} 
                alt="pageArrow" 
                className="pageArrow"
              />
            }
            <Link
              className={currentPage === 'swap' 
                ? 'MenuPages_activePage' 
                : 'MenuPages_inactivePage'
              }
              to='/swap'
              onClick={closeMenuAction}
              tabIndex={(tabIndex && currentPage !== 'swap')
                ? (typeof tabIndex === 'boolean'? 0 : tabIndex) 
                : -1
              }
            >
              Swap
            </Link>
          </div>
          <div className="menuItem">
            {currentPage === 'farming' && 
              <img 
                src={pageArrow} 
                alt="pageArrow" 
                className="pageArrow"
              />
            }
            <Link
              className={currentPage === 'farming'
                ? 'MenuPages_activePage' 
                : 'MenuPages_inactivePage'
              }
              to='/farming'
              onClick={closeMenuAction}
              tabIndex={(tabIndex && currentPage !== 'farming')
                ? (typeof tabIndex === 'boolean'? 0 : tabIndex) 
                : -1
              }
            >
              Farming
            </Link>
          </div>
          <div className="menuItem">
            {currentPage === 'pool' && 
              <img 
                src={pageArrow} 
                alt="pageArrow" 
                className="pageArrow"
              />
            }
            <Link
              className={currentPage === 'pool'
                ? 'MenuPages_activePage' 
                : 'MenuPages_inactivePage'
              }
              to='/pool'
              onClick={closeMenuAction}
              tabIndex={(tabIndex && currentPage !== 'pool')
                ? (typeof tabIndex === 'boolean'? 0 : tabIndex) 
                : -1
              }
            >
              Pool
            </Link>
          </div>
        </div>
      </div>
    </div>
	)
}

export default MobileMenu;