import { Session } from "@chromia/ft4";

import { Dispatch } from "../store"
import { iFarmData, iMasterChefContract, tPeriodType, transactionStatus } from "../../interfaces/interfaces"
import { eAppActionTypes } from "./appTypes"
import { iAccPosition } from "../../components/MyLiqPage/MyLiqPage";
import { iFarm } from "../../components/FarmingPage/FarmingPage";

export const setClientAddr = (addr: string | null) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CLIENT_ADDR,
			payload: addr
		})
	}
}

export const setApi = (api: string) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_API,
			payload: api
		}) 
	}
}

export const setIsAccount = (account: boolean) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_IS_ACCOUNT,
			payload: account
		}) 
	}
}

/* export const setWeb3Provider = (provider: iWeb3Provider) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_WEB3_PROVIDER,
			payload: provider
		}) 
	}
} */

/*
export const setConfig = (config: iConfig) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CONFIG,
			payload: config
		}) 
	}
}

export const setIsTransaction = (state: boolean) => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_IS_TRANSACTION,
			payload: state
		})
	}
}

export const setIsNetworkIssues = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_IS_NETWORK_ISSUES
		})
	}
}*/

export const clearBalances = () => {
	return (dispatch : Dispatch) => {
		dispatch({
			type: eAppActionTypes.CLEAR_BALANCES
		}) 
	}
}

export const setSlippagePercent = (percent: string) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_SLIPPAGE_PERCENT,
			payload: percent
		})
	}
}

export const setChartPeriod = (period: tPeriodType) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CHART_PERIOD,
			payload: period
		})
	}
}

export function setTransactionStatus(status : transactionStatus | null) {
	return (dispatch: Dispatch) => {
			dispatch({
					type: eAppActionTypes.SET_TRANSACTION_STATUS,
					payload: status 
			})
	}
}

export const setMasterChefContract = (masterChefContract: iMasterChefContract) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_MASTERCHEF_CONTRACT,
			payload: masterChefContract
		})
	}
}

export const setFarms = (farms: iFarmData[]) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_FARMS,
			payload: farms
		})
	}
}

export const setOpenedFarms = (pids: number[]) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_OPENED_FARMS,
			payload: pids
		})
	}
}

export const setSession = (session: Session | null) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_SESSION,
			payload: session
		})
	}
}

export const setChrClientAddr = (addr: string | null) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CHR_CLIENT_ADDR,
			payload: addr
		})
	}
}

export const setSelectedPos = (selectedPos: iAccPosition | null) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_SELECTED_POS,
			payload: selectedPos 
		})
	}
}

export const setIsRegistered = (isRegistered: boolean) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_IS_REGISTERED,
			payload: isRegistered
		})
	}
}

export const setSelectedNewPos = (selectedNewPos: iFarm | null) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: eAppActionTypes.SET_SELECTED_NEW_POS,
			payload: selectedNewPos
		})
	}
}

export function setAccessToken(token: string) {
	return (dispatch: Dispatch) => {
			dispatch({
					type: eAppActionTypes.SET_ACCESS_TOKEN,
					payload: token
			})
	}
}