import { iPromiseFork1, iConfirmModal, iPromiseFork2 } from "../interfaces/interfaces";
import { eModalsActionTypes } from "../store/modals/modalsTypes";
import { BigNumber } from "../entities/BigNumber";
import { Currency } from "../entities/Currency";
import { store } from "../store/store";
import { chainId, isProduction, projectName } from "../settings";
import { eAppActionTypes } from "../store/app/appTypes";
import { memoize } from "lodash";

type dateFormat = 
	'YYYY-MM-DD' |
	'YYYY-MM-DD hh:mm' |
	'YYYY-MM-DD hh:mm:ss' |
	'DDMMMYY' |
	'DDmmmYY' |
	'mmm DD' |
	'YYYY.MM.DD' |
	'DD mmm YY, hh:mm' | 
	'hh:mm'
;
export function getHumanDate(ts: number, format: dateFormat): string {
	if(Number.isNaN(ts)) throw new Error('Error code: 6_9');

	let date = new Date(ts * 1000);
	let year = '' + date.getUTCFullYear();
	let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	let monthsCaps = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
	let month = date.getUTCMonth();
	let humanMonth = '0' + (date.getUTCMonth()+1);
	let day = '0' + date.getUTCDate();
	let hour = '0' + date.getUTCHours();
	let minutes = '0' + date.getUTCMinutes();
	let seconds = '0' + date.getUTCSeconds();
	
	switch (format) {
		case 'YYYY-MM-DD':
			return `${year}-${humanMonth.substring(humanMonth.length-2)}-${day.substring(day.length-2)}`;
		
		case 'YYYY-MM-DD hh:mm':
			return `${year}-${humanMonth.substring(humanMonth.length-2)}-${day.substring(day.length-2)} ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}`;

		case 'YYYY-MM-DD hh:mm:ss':
			return `${year}-${humanMonth.substring(humanMonth.length-2)}-${day.substring(day.length-2)} ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}:${seconds.substring(seconds.length-2)}`;

		case 'DDMMMYY':
			return `${day.substring(day.length-2)}${monthsCaps[month]}${year.substring(year.length-2)}`

		case 'DDmmmYY':
			return `${day.substring(day.length-2)}${months[month]}${year.substring(year.length-2)}`

		case 'mmm DD':
			return `${months[month]} ${day.substring(day.length-2)}`

		case 'YYYY.MM.DD':
			return `${year}.${humanMonth.substring(humanMonth.length-2)}.${day.substring(day.length-2)}`

		case 'DD mmm YY, hh:mm':
			return `${day.substring(day.length-2)} ${months[month]} ${year.substring(year.length-2)}, ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}`

		case 'hh:mm': 
			return `${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}`
		default:
			return `${year}-${humanMonth.substring(humanMonth.length-2)}-${day.substring(day.length-2)} ${hour.substring(hour.length-2)}:${minutes.substring(minutes.length-2)}:${seconds.substring(seconds.length-2)}`;
	}
}

/**
 * Works as default toFixed but not rounds.
*/
export function toFixed(value: number | string, digits = 2): string{
	if (typeof(value) === 'number'){
		value = value.toString();
	}
	if (!new BigNumber(value).valueBN18.isZero() && new BigNumber(value).valueBN18.lt(new BigNumber(1/(10 ** digits)).valueBN18)) {
		return '<' + new BigNumber(1/ 10 ** digits).valueStr
	}
	let splitted = value.split('.');
	if (splitted[1]){
		for (let i=1; i<digits; i++){
			splitted[1] = splitted[1] + '0';
		}
	}
	return splitted.length === 2
		? splitted[0] + '.' + splitted[1].substring(0,digits)
		: splitted[0]
	;
}

/**
 * Opens modal and waits for user actions. 
 * @param blockedTokens 
 * @returns token address.
 * @throws error if user closes modal.
 */
export const getSelectedToken = async (blockedTokens?: Currency[]): Promise<Currency> => {
	let promiseFork: iPromiseFork2<Currency, null> = {resolve: null, reject: null};
	// let promiseFork: iPromiseFork;
	
	let tokenPromise = new Promise((resolve, reject) => {
		// /*promiseFork = {
		// 	resolve: (value) => resolve(value),
		// 	reject: (value) => reject(value)
		// }*/
		promiseFork.resolve = ((value) => resolve(value));
		promiseFork.reject = ((value) => reject(value));
	})

	store.dispatch({
		type: eModalsActionTypes.OPEN_NEW_MODAL,
		payload: {
			name: 'tokenSelect',
			promiseFork: promiseFork,
			blockedTokens: blockedTokens,
		}
	})

	return tokenPromise as Promise<Currency>;
}

/**
 * Throw the 'e' letter(exponential)  
 * @returns formated value
 */
export function fixExponential(value: string | number): string{
	const data = value.toString().split(/[eE]/);
	if (data.length === 1) return data[0];
  
	let z = '',
		sign = +value < 0 ? '-' : '',
		str = data[0].replace('.', ''),
		mag = Number(data[1]) + 1;
  
	if (mag < 0) {
		z = sign + '0.';
		while (mag++) z += '0';

		return z + str.replace(/^-/, '');
	}

	mag -= str.length;
	while (mag--) z += '0';

	return str + z;
}

/**
 * Check the numbers on a rendered page(cut decimals and also check N/A value)
 * @param value number
 * @param decimals how much decimals are needed to stay
 * @returns formated output
 * Example: 0.1534 => 0.15, 5 => 5
 */
/* export function cutValue(value: number | string, decimals?: number ): string {
	if (isNaN(+value) || !isFinite(+value)) {
		return ("N/A");
	}

	value = fixExponential(value);

	const splited = value.toString().split('.');
	let res: string;
	if (splited[1] === undefined){
		res = splited[0];
	}
	else {
		res = splited[0] + '.' + splited[1].substring(0, decimals || 4);
	} 
	return res
} */

/**
 * Sorts an array of tokens. 
 * 
 * Default: descending.
 */
export function sortTokensByBalance(balances: BigNumber[], direction?: 'ascending' | 'descending'): BigNumber[]  {
	if (direction === 'ascending'){
		return balances.sort((a, b) => +a.sub(b).valueStr);
	} else {
		// return balances.sort((a, b) => +pseudoBNToVal(b.valueBN18.sub( a.valueBN18 ).toString()));
		return balances.sort((a, b) => +b.sub(a).valueStr);
	}
}

/**
 * Opens confirm modal and returns a promise.
 * Promise resolves in case of accepting modal, and rejects otherwise.
 */
export const getConfirm = (props: Omit<iConfirmModal, 'name' | 'modalId' | 'promiseFork'>) => {
	const promiseFork: iPromiseFork1<any> = {resolve: null, reject: null};

	const confirmPromise = new Promise((resolve, reject) => {
		promiseFork.resolve = ((value) => resolve(value));
		promiseFork.reject = ((value) => reject(value));
	})

	store.dispatch({
		type: eModalsActionTypes.OPEN_NEW_MODAL,
		payload: {
			name: 'confirm',
			promiseFork: promiseFork,
			...props
		}
	})

	return confirmPromise;
}

export const getScanner = (): {url: string, name: string} => {
	switch (chainId) {
		case '0x38':
			return {
				url: 'https://bscscan.com/tx/',	// tx only for transactions
				name: 'BscScan'
			};
	
		case '0x61':
			return {
				url: 'https://testnet.bscscan.com/tx/',	// tx only for transactions
				name: 'BscScan'
			};

		default:
			// err + chainId
			throw new Error();
	}
}

/**
 * Decide the number by thosausands, adding spaces.
 * For example, 1234567 => 1 234 567
 * @param str 
 * @returns 
 */
export function formatStringNumber(str: string) {
	const s = str.length;
	const chars = str.split('');
	const strWithSpaces = chars.reduceRight((acc, char, i) => {
		const spaceOrNothing = ((((s - i) % 3) === 0) ? ' ' : '');
		return (spaceOrNothing + char + acc);
	}, '');

	return ((strWithSpaces[0] === ' ') ? strWithSpaces.slice(1) : strWithSpaces);
}

export function numberWithSpaces(x: string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function roundSignDigit(x: string, maxSignDigitsAfterDot = 4) {
	const digits = x.split('.');

	let res = digits[0] + '.' || '';
	const digitsAfterDot = digits[1]
	let digitsNum = 0;
	for (let i = 0; i < digitsAfterDot.length; i++) {
		if (digitsNum === maxSignDigitsAfterDot) {
			return res
		}
		else {
			res += digitsAfterDot[i].toString();

			if (+digitsAfterDot[i] > 0) {
				digitsNum += 1;
			}
		}
	}

	return res
}

export const disconnectWallet = () => {
	/* const provider = store.getState().app.web3Provider;
	localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
	if (localStorage.getItem('walletconnect') && provider && provider.disconnect){
		provider.disconnect();
	} */

	store.dispatch({
		type: eAppActionTypes.DISCONNECT
	});
	store.dispatch({
		type: eModalsActionTypes.DISCONNECT
	});
}

// export function getHistoryPriceApi(pairAddress: string, data_type: "h24" | "1m" | "1w" | "1y"):Promise<iPriceHistory[]> {
// 	return  fetch(`${priceApi}/prices/${pairAddress}?data_type=${data_type}`)
// 	.then(response => response.json())
// 	.then(res => res.data)
// 	.catch(() => {
// 			throw new Error('get price history error')
// 	})
// }

export const getTokenIconURL = memoize((token: string) => {
	try {
		return process.env.REACT_APP_CHROMIA_BLOCKCHAIN_IMG_BACKADDR + `/info/get_token_image?token=${token}`
	}
	catch {
		return null
	}
})

export async function connectKAS() {
	
	const authorization: any = await KASapiAuth();

	if (authorization.ok) {
		const accessToken = (await authorization.json()).access_token;
		store.dispatch({
			type: eAppActionTypes.SET_ACCESS_TOKEN,
			payload: accessToken
		});
	}
}

export function KASapiAuth() {
	const username = 'hello';
	const password = 'world';

	return fetch(process.env.REACT_APP_KAS_BACKADDR + '/users/auth', {
			method: 'POST',
			headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: `username=${username}&password=${password}`
	})
}

export function logError(message: string) {
	const accessToken =  store.getState().app.accessToken;

	if (isProduction)
			return fetch(`${process.env.REACT_APP_KAS_BACKADDR}users/send_message?project_name=${projectName}&text=${message}`, {
					method: 'GET',
					headers: {
							'Authorization': `bearer ${accessToken}`
					},
			})
	throw new Error(message);
}