import React, { useEffect, useState } from "react";

import { getGtxClient } from "../../utils/Chromia/chromiaHelpers";

import './PingPage.scss'



const PingPage: React.FC = () => {
  const [curTime, setCurTime] = useState<number>();


  useEffect(() => {
    getTime();
  }, [])
  
  async function getTime () {
    setCurTime(await (await getGtxClient()).query('slurpyswap.GetTime', {}));
  }

  return (
    <div className="PingPage">
      <span>
        Time: {curTime}
      </span>
    </div>
	)
}

export default PingPage;