import React, { useEffect } from "react";

import { store } from "../../../store/store";


const EscapeHandler: React.FC = () => {
    
    useEffect(()=>{
        document.addEventListener('keydown', escapeHandler);
        // eslint-disable-next-line
    },[])

    function closeLastWindow() {
        let modals = store.getState().modals.openedModals;
        if (modals.length > 0){
            const lastWindow = modals[modals.length -1];
            document.dispatchEvent(new Event(`close_${lastWindow.name}_${lastWindow.modalId}`));
        }
    }

    function escapeHandler(e: KeyboardEvent) {
        if (e.key === 'Escape'){
            setTimeout(() => {
                closeLastWindow();
            });
        }
    };


    return null;
}

export default EscapeHandler;