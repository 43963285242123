import { useEffect, useState, useMemo, useCallback } from "react";
import { BigNumber } from "bignumber.js";
import { op } from "@chromia/ft4";
import { format } from 'd3'

import { calcMint, calcSingleMint, sqrtPriceToPrice, sqrtPriceToTick, tickToPrice } from "../../utils/Chromia/v3";
import { getChrPools, getGtxClient, updateChromiaBalances } from "../../utils/Chromia/chromiaHelpers";
import { feeDesctiption, fees, iPoolPrice, iTickData, tPoolFee } from "../../interfaces/interfaces";
import { fixExponential, getTokenIconURL, logError, toFixed } from "../../utils/helpers";
import { useStoreDispatch, useStoreSelector } from "../../store/store";
import { iChrPool } from "../../interfaces/chromiaInterfaces";
import { FeeAmount, ZOOM_LEVELS } from "../../constants";
import { useTabIndex } from "../../hooks/useTabIndex";
import { getPool } from "../../utils/observers";
import { Token } from "../../entities/Token";

import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import TokenSelector from "../TokenSelector/TokenSelector";
import useTokenBalance from "../../hooks/useTokenBalance";
import EmptyBoxIcon from "../UI/icons/EmptyBoxIcon/EmptyBoxIcon";
import BackArrowIcon from "../UI/icons/BackArrowIcon/BackArrowIcon";
import GearIcon from "../UI/icons/GearIcon/GearIcon";
import PlusIcon from "../UI/icons/PlusIcon/PlusIcon";
import MinusIcon from "../UI/icons/MinusIcon/MinusIcon";
import WalletAddress from "../WalletAddress/WalletAddress";
import StarBackground from "../StarBackground/StarBackground";
import MenuPages from "../MenuPages/MenuPages";
import PixelBorder from "../UI/PixelBorder/PixelBorder";
import ConnectionBlock from "../ConnectionBlock/ConnectionBlock";
import BlockPixelBorder from "../UI/BlockPixelBorder/BlockPixelBorder";
import starBig4 from '../../assets/images/StarBig4.svg';
import starBig5 from '../../assets/images/StarBig5.svg';
import starSmall4 from '../../assets/images/StarSmall4.svg';


import './LiquidityPageV2.scss';
import DexChart, { ChartEntry } from "../IntervalChart/DexChart";

interface iPoolFee {
	fee: tPoolFee,
	pool: iChrPool | undefined
}


const LiquidityPageV2: React.FC <{setIsNewPosition: any, getPositions: any, isFarming?: boolean, ATokenProps?: Token, BTokenProps?: Token, feeProps?: any}> = ({setIsNewPosition, getPositions, isFarming, ATokenProps, BTokenProps, feeProps}) => {

	const { clientAddr, session, chrPools } = useStoreSelector(store => store.app);
	const { openNewModal, setSelectedNewPos, setTransactionStatus } = useStoreDispatch();

	const [AToken, setAToken] = useState<Token | undefined>(ATokenProps ?? undefined);
	const [BToken, setBToken] = useState<Token | undefined>(BTokenProps ?? undefined);
	const [currentFee, setCurrentFee] = useState<tPoolFee | null>(feeProps ?? null);
	const [allFeePools, setAllFeePools] = useState<iPoolFee[]>();

	const [intervalData, setIntervalData] = useState<iTickData[]>([]);
	const [currentPrice, setCurrentPrice] = useState<iPoolPrice>();
	const [currentPool, setCurrentPool] = useState<iChrPool>();
	const [lowerTick, setLowerTick] = useState<number>();
	const [upperTick, setUpperTick] = useState<number>();

	const tabIndex = useTabIndex({position: 'first'});

	const [ATokenValue, setATokenValue] = useState('');
	const [BTokenValue, setBTokenValue] = useState('');

	const ATokenBalance = useTokenBalance(AToken);
	const BTokenBalance = useTokenBalance(BToken);

	const [liquidity, setLiquidity] = useState<string | null>();

	const [minPrice, setMinPrice] = useState<string>();
  const [maxPrice, setMaxPrice] = useState<string>();

	const [isFullRange, setIsFullRange] = useState<boolean>(false);
	const [isQuoteToken1, setIsQuoteToken1] = useState<boolean>(true);
	const [isFeeEditing, setIsFeeEditing] = useState<boolean>(false);
	const [startPoolPrice, setStartPoolPrice] = useState<string>();
	const [exactField, setExactField] = useState<'Atoken' | 'Btoken'>();
	

	const invalidRange = useMemo(() => {
		if (minPrice && maxPrice && maxPrice !== '∞') {
			return new BigNumber(minPrice).gte(new BigNumber(maxPrice))
		}
		else return false
	}, [minPrice, maxPrice])

	const formattedData = useMemo(() => {
		if (!intervalData)
			return []

    const newData: ChartEntry[] = [];

    for (let i = 0; i < intervalData.length; i++) {
      const t = intervalData[i];

      const chartEntry = {
        activeLiquidity: parseFloat(t.liquidityNet.toString()),
        price0: parseFloat(isQuoteToken1 ? t.price : new BigNumber(1).div(new BigNumber(t.price)).toFixed()),
      };

      if (chartEntry.activeLiquidity > 0) {
        newData.push(chartEntry);
      }
    }

    return newData;
  }, [intervalData, isQuoteToken1]);

	// useEffect(() => {
	// 	const tickInterval = setInterval(() => {
	// 		getChrPools();
	// 	}, 10000)
	
	// 	return () => {
	// 		clearInterval(tickInterval);
	// 	}
	// }, [])

	useEffect(() => {
		getAllFeePools();

		if (AToken && BToken && currentFee) {
			getPoolData(currentFee);
		}

		const tickInterval = setInterval(() => {
			getChrPools();
			getAllFeePools();

			if (AToken && BToken && currentFee) {
				getPoolData(currentFee);
			}
		}, 6000000)
	
		return () => {
			clearInterval(tickInterval);
		}
	}, [AToken, BToken, currentFee, clientAddr]) 

	const getAllFeePools = useCallback(async () => {
		if (!AToken || !BToken) return

		const pools = await Promise.all(fees.map((fee) => {
			const res = getPool(AToken, BToken, +fee / 100);

			return res.then((res) => {
				return {pool: res, fee}
			})
			.catch(() => {
				return {pool: undefined, fee}
			})

		}))

		setAllFeePools(pools as any);
	}, [AToken, BToken, chrPools])

	useEffect(() => {
		if (currentFee) {
			getPoolData(currentFee);
		}
	}, [allFeePools])

	useEffect(() => {
		if (isSingleDepo1) {
			getBtokenLiq(BTokenValue)
		}
		else if (isSingleDepo2) {
			getAtokenLiq(ATokenValue)
		}
		else {
			if (ATokenValue && exactField === 'Atoken') {
				getAtokenLiq(ATokenValue)
			}
			else {
				getBtokenLiq(BTokenValue)
			} 
		}
	}, [lowerTick, upperTick, AToken, BToken, currentPrice])

	const { isSingleDepo1, isSingleDepo2 } = useMemo(
		() => {

			let quotedLowerTick = lowerTick;
			let quotedUpperTick = upperTick;
			let quotedPrice = currentPrice;
			
			if (!isQuoteToken1 && currentPool && minPrice && maxPrice) {
				// const newMinPrice = new BigNumber(1).div(new BigNumber(minPrice)).toFixed();
				// const newMaxPrice = new BigNumber(1).div(new BigNumber(maxPrice)).toFixed();
				// const quotedMinPriceTick = sqrtPriceToTick(newMinPrice);
				// const quotedMaxPriceTick = sqrtPriceToTick(newMaxPrice);
				// quotedLowerTick = Math.min(quotedMinPriceTick, quotedMaxPriceTick);
				// quotedUpperTick = Math.max(quotedMinPriceTick, quotedMaxPriceTick);
				const quotedPoolPrice = new BigNumber(1).div(new BigNumber(sqrtPriceToPrice(currentPool?.sqrt_price))).toFixed()
				quotedPrice = {
					price: quotedPoolPrice,
					tick: sqrtPriceToTick(quotedPoolPrice)
				}
			}

			if (isQuoteToken1) {
				const deposit0Disabled = Boolean( 
					quotedPrice &&  quotedUpperTick 
						&& quotedPrice?.tick >= quotedUpperTick,
				)
				const deposit1Disabled = Boolean(
					quotedPrice &&  quotedLowerTick 
					&& quotedPrice?.tick <= quotedLowerTick,
				)
				
				if (((currentPool?.token0 === AToken?.address && deposit0Disabled) || 
					(currentPool?.token1 === AToken?.address && deposit1Disabled))) {
						return { isSingleDepo1: true, isSingleDepo2: false }
				}
		
				if (((currentPool?.token0 === BToken?.address && deposit0Disabled) || 
					(currentPool?.token1 === BToken?.address && deposit1Disabled))) {
						return { isSingleDepo1: false, isSingleDepo2: true }
				}
				
				return { isSingleDepo1: false, isSingleDepo2: false }
			}
			else {
				const deposit0Disabled = Boolean( 
					quotedPrice &&  quotedLowerTick 
						&& quotedPrice?.tick <= quotedLowerTick,
				)
				const deposit1Disabled = Boolean(
					quotedPrice &&  quotedUpperTick 
					&& quotedPrice?.tick >= quotedUpperTick,
				)
				
				if (((currentPool?.token0 === AToken?.address && deposit0Disabled) || 
					(currentPool?.token1 === AToken?.address && deposit1Disabled))) {
						return { isSingleDepo1: true, isSingleDepo2: false }
				}
		
				if (((currentPool?.token0 === BToken?.address && deposit0Disabled) || 
					(currentPool?.token1 === BToken?.address && deposit1Disabled))) {
						return { isSingleDepo1: false, isSingleDepo2: true }
				}
				
				return { isSingleDepo1: false, isSingleDepo2: false }
			}
			
		},
		[AToken?.address, BToken?.address, currentPool, currentPrice, lowerTick, upperTick, isQuoteToken1, minPrice, maxPrice],
	)

	const inputError: string | null = useMemo(() => {
			if (!clientAddr) {
				return 'Connect wallet';
			}

			if (!AToken || !BToken) {
				return 'Select tokens';
			}

			if (!currentFee) {
				return 'Select fee';
			}
	
			if (!currentPool) {
				return 'Pool doesn`t exist';
			}
	
			if (
				ATokenBalance === undefined ||
				BTokenBalance === undefined 
			) {
				return 'Loading';
			}
	
			if (
				Number.isNaN(+ATokenValue) ||
				!Number.isFinite(+ATokenValue) ||
				(+ATokenValue <= 0 && !isSingleDepo1) ||
				Number.isNaN(+BTokenValue) ||
				!Number.isFinite(+BTokenValue) ||
				(+BTokenValue <= 0 && !isSingleDepo2)
			) {
				return 'Type amount';
			}
	
			const ATokenValueBN = new BigNumber(ATokenValue || 0);
			const BTokenValueBN = new BigNumber(BTokenValue || 0);
	
			if (ATokenValueBN.gt(ATokenBalance.valueStr)){
				return `Insufficient ${AToken.name} balance`;
			}
	
			if (BTokenValueBN.gt(BTokenBalance.valueStr)){
				return `Insufficient ${BToken.name} balance`;
			}
			if (!liquidity) {
				return 'Insufficient liquidity';
			}

			if (invalidRange) {
				return 'Invalid range'
			}

			return null
		},
		[AToken, ATokenBalance, ATokenValue, BToken, BTokenBalance, BTokenValue, clientAddr, currentPool, isSingleDepo1, isSingleDepo2, liquidity, invalidRange]
	)

	const getAtokenLiq = useCallback(
		async (amount: string) => {
			if (!session || !AToken || amount.length === 0 || !currentPool || !lowerTick || !upperTick || ! BToken || !currentPrice || amount === undefined) return;

			if (+amount === 0) {
				setATokenValue(amount);
				setExactField('Atoken');
				setBTokenValue('0')
			}

			let newLowerTick = lowerTick, newUpperTick = upperTick;
			if (!isQuoteToken1) {
				const quotedLowPrice = new BigNumber(1).div(new BigNumber(tickToPrice(lowerTick, false).toFixed())).toFixed();
				newLowerTick = sqrtPriceToTick(quotedLowPrice);

				const quotedUpperPrice = new BigNumber(1).div(new BigNumber(tickToPrice(upperTick, false).toFixed())).toFixed();
				newUpperTick = sqrtPriceToTick(quotedUpperPrice);
			}

			const minTick = Math.min(newLowerTick, newUpperTick);
			const maxTick = Math.max(newLowerTick, newUpperTick);
			const lowTick = Math.round(Math.round(minTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;
			const upTick = Math.round(Math.round(maxTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;

			if (isSingleDepo1 || isSingleDepo2) {
				let liq;

				if (currentPool.token0 === AToken.address) {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, '0', ATokenValue, lowTick, upTick).toString();
					}
					else {
						liq = calcSingleMint(currentPool, amount, '0',  lowTick, upTick).toString();
					}
				}
				else {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, '0', amount, lowTick, upTick).toString();
					}
					else {
						liq = calcSingleMint(currentPool, '0', amount, lowTick, upTick).toString();
					}
				}
				setLiquidity(liq)
			}
			
			setATokenValue(amount);
			setExactField('Atoken');
			
			if (amount.endsWith('.')) {
				amount = amount.slice(0, -1)
			}

			if (isSingleDepo1 || isSingleDepo2) return 

			if ((currentPrice && (lowerTick > currentPrice?.tick || upperTick < currentPrice?.tick)) || +amount === 0) 
				return
			
			try {

				const mint = calcMint(currentPool, AToken?.address, amount, lowTick, upTick)
				
				setLiquidity(mint.liquidity);
				setBTokenValue(toFixed(mint.amount, 18));
			}
			catch (err: any) {
				logError(err);
				throw new Error(err);
			}	

		}, 
		[AToken, BToken, currentPool, currentPrice, lowerTick, session, upperTick, isQuoteToken1, minPrice, isSingleDepo1, isSingleDepo2],
	)

	const getBtokenLiq = useCallback(
		async (amount: string) => {
			if (!session || !AToken || amount.length === 0 || !currentPool || !lowerTick || !upperTick || !BToken || !currentPrice || !amount) return;
			
			if (+amount === 0) {
				setBTokenValue(amount);
				setExactField('Btoken');
				setATokenValue('0')
			}

			let newLowerTick = lowerTick, newUpperTick = upperTick;
			if (!isQuoteToken1) {
				const lowPrice = tickToPrice(lowerTick, false);
				const quotedLowPrice = new BigNumber(1).div(new BigNumber(lowPrice.toFixed())).toFixed();
				newLowerTick = sqrtPriceToTick(quotedLowPrice);
				
				
				const upperPrice = tickToPrice(upperTick, false);
				const quotedUpperPrice = new BigNumber(1).div(new BigNumber(upperPrice.toFixed())).toFixed();
				newUpperTick = sqrtPriceToTick(quotedUpperPrice);
			}

			const minTick = Math.min(newLowerTick, newUpperTick);
			const maxTick = Math.max(newLowerTick, newUpperTick);
			const lowTick = Math.round(Math.round(minTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;
			const upTick = Math.round(Math.round(maxTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;

			setBTokenValue(amount);
			setExactField('Btoken');
		
			if (amount.endsWith('.')) {
				amount = amount.slice(0, -1)
			}

			
			if (isSingleDepo1 || isSingleDepo2) {
				let liq;
				if (currentPool.token0 === AToken.address) {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, '0', amount,  lowTick, upTick).toString();
					}
					else {
						liq = calcSingleMint(currentPool, ATokenValue, '0', lowTick, upTick).toString();
					}
				}
				else {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, amount, '0', lowTick, upTick).toString();
					}
					else {
						liq = calcSingleMint(currentPool, amount, '0', lowTick, upTick).toString();
					}
				}

				setLiquidity(liq);
			}

			if (isSingleDepo1 || isSingleDepo2) return 

			if ((currentPrice && (lowerTick > currentPrice?.tick || upperTick < currentPrice?.tick)) || +amount === 0) 
				return

			try {
				const mint = calcMint(currentPool, BToken?.address, amount, lowTick, upTick);
				
				setLiquidity(mint.liquidity);
				setATokenValue(toFixed(mint.amount, 18));
			}
			catch (err: any) {
				logError(err);
				throw new Error(err);
			}
	},
		[AToken, BToken, currentPool, currentPrice, lowerTick, session, upperTick, isQuoteToken1, isSingleDepo1, isSingleDepo2],
	)
	
	const getPoolData = useCallback(async (fee: tPoolFee) => {
		if (!allFeePools || allFeePools.length === 0) return 

		const pool = (allFeePools?.find((pool) => +pool.fee === +fee))?.pool

		if (pool && pool.id) {
			setIsFullRange(false);
			setCurrentPool(pool);

			let currPrice;

			if (!isQuoteToken1) {
				const quotedPrice = new BigNumber(1).div(new BigNumber(sqrtPriceToPrice(pool?.sqrt_price))).toFixed();
				const newTick = sqrtPriceToTick(quotedPrice);
				setCurrentPrice({price: quotedPrice, tick: newTick});
				currPrice = {price: quotedPrice, tick: newTick};
			}
			else {
				const price2 = (new BigNumber(pool.sqrt_price)).div((new BigNumber(2)).pow(96)).pow(2).toString();
				currPrice = {price: price2.toString(), tick: pool.tick};
				setCurrentPrice(currPrice);
			}

			const ticks = (await (await getGtxClient()).query("slurpyswap.GetTicks", {pool: pool?.id}));

			if (ticks.length > 0) {
				const resArr: iTickData[] = [];
				let isPriceSet = false;

				for (let i = 0; i < ticks.length; i++) {
					const lNet = new BigNumber(ticks[i].liq_net);
					const liquidityGross = (new BigNumber(ticks[i].liq_gross)).toString();

					const amount = resArr[i-1] === undefined
						? lNet.toString()
						: lNet.plus(fixExponential(resArr[i-1].amount.toString())).toString()
					
					const a = (new BigNumber(ticks[i].price)).div((new BigNumber(2)).pow(96)).pow(2);

					const price = new BigNumber(a);

					resArr.push({
						tick: ticks[i].tick.toString(),
						price: price.toString(),
						liquidityNet: lNet.toString(), 
						liquidityGross,
						amount
					})

					// otherwise currentPrice not drawings on chart
					if (!isPriceSet && currPrice) {
						if ((!ticks[i+1]) || Math.abs(+ticks[i+1].tick) < Math.abs(currPrice.tick)) {
							resArr.push({
								tick: currPrice.tick.toString() + '',
								price: currPrice.price,
								liquidityNet: lNet.toString(),
								liquidityGross,
								amount
							})
							isPriceSet = true;
						}
					}
				}

				resArr.sort((a, b) => {
					return +a.price - +b.price
				})

				if (!isQuoteToken1) {
					resArr.map((item) => {
						return {...item, price: new BigNumber(1).div(new BigNumber(item.price).toFixed())}
					})
				}
				else {
					setIntervalData(resArr);
				}
			}
		}
		else {
			setCurrentPool(undefined);
			setIntervalData([])
			setATokenValue('0');
			setBTokenValue('0');
		}
	}, [allFeePools, isQuoteToken1])

	const changeToken = async (newToken: Token, isAToken: boolean) => {
		if (isAToken){
			setAToken(newToken);
			if (BToken && newToken.equals(BToken)){
				setBToken(AToken);
			}
		} else {
			setBToken(newToken);
			if (AToken && newToken.equals(AToken)){
				setAToken(BToken);
			}
		}
	}

	const addLiquidity = async () => {
		if (!AToken || !BToken || !currentFee || lowerTick === undefined || upperTick === undefined || !clientAddr || !currentPool || !session || !liquidity || !currentPrice) return;

		let lowTickT1T0 = lowerTick;
		let uppTickT1T0 = upperTick;

		//send ticks and liquidity only as t1/t0
		if (!isQuoteToken1) {
			const lowPrice = tickToPrice(lowerTick, false);
			const quotedLowPrice = new BigNumber(1).div(new BigNumber(lowPrice.toFixed())).toFixed();
			const lowTick = sqrtPriceToTick(quotedLowPrice);

			const upperPrice = tickToPrice(upperTick, false);
			const quotedUpperPrice = new BigNumber(1).div(new BigNumber(upperPrice.toFixed())).toFixed();
			const uppTick = sqrtPriceToTick(quotedUpperPrice);

			const minTick = Math.min(lowTick, uppTick);
			const maxTick = Math.max(lowTick, uppTick);
			lowTickT1T0 = Math.round(Math.round(minTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;
			uppTickT1T0 = Math.round(Math.round(maxTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;
		}

		try {
			const tx = session.call(op(
				'slurpyswap.Mint',
				Buffer.from(currentPool.id, 'hex'),
				lowTickT1T0, 
				uppTickT1T0,
				liquidity
			))
			setTransactionStatus('progress');

			openNewModal({
				name: 'transaction'
			})
	
			await tx;
			setTransactionStatus('succes');
			
			if (ATokenProps && BTokenProps && feeProps) {
				setSelectedNewPos(null); 
			}
			setIsNewPosition(null);
		}
		catch(err: any) {
			// && !err.toString().includes('Invalid signature')
			if (!err.toString().includes('Unable to sign') ) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
			else {
				setTransactionStatus(null);
			}
		}
	
		if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
			const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
			await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
		}

		updateChromiaBalances();
		getPositions();
		getPoolData(currentFee);
	}

	const brushLabelValue = useCallback(
    (d: 'w' | 'e', x: number) => {
      if (!currentPrice) return '' 

      const percent = (x < +currentPrice.price ? -1 : 1) * ((Math.max(x, +currentPrice.price) - Math.min(x, +currentPrice.price)) / +currentPrice.price) * 100

      return currentPrice ? `${format(Math.abs(Math.round(+percent)) > 1 ? '.2~s' : '.2~f')(Math.round(+percent))}%` : ''
    },
    [currentPrice],
  )

	const brushDomain: [number, number] | undefined = useMemo(() => {
		if (!currentPool) 
			return [0,0]

		if ((!lowerTick || !upperTick) && currentPrice) {
			const currLowTick = Math.floor(currentPrice?.tick / currentPool.tick_spacing) * currentPool.tick_spacing  - 30*currentPool.tick_spacing;
			const currUpTick = Math.floor(currentPrice?.tick / currentPool.tick_spacing) * currentPool.tick_spacing  + 30*currentPool.tick_spacing;
			const leftPrice = tickToPrice(currLowTick, false);
			const rightPrice = tickToPrice(currUpTick, false);
			
			setMinPrice(toFixed(leftPrice.toString(), 7));
			setMaxPrice(toFixed(rightPrice.toString(), 7));
			setLowerTick(currLowTick);
			setUpperTick(currUpTick);

			return [parseFloat(toFixed(leftPrice.toString(), 6)), parseFloat(toFixed(rightPrice.toString(), 6))]
		}
		
		if (!lowerTick || !upperTick) 
			return [0,0]
		
		const leftPrice = tickToPrice(lowerTick, false);
    const rightPrice = tickToPrice(upperTick, false);

		if (!leftPrice || !rightPrice) 
		return [0,0]

		
		if (+leftPrice > 1 / 10 ** 6) {
			setMinPrice(toFixed(leftPrice.toString(), 7));
			setMaxPrice(toFixed(rightPrice.toString(), 7)); 
			return [parseFloat(toFixed(leftPrice.toFixed(), 6)), parseFloat(toFixed(rightPrice.toFixed(), 6))]
		}
		
		if (isFullRange) {
			setMinPrice("0");
			setMaxPrice('∞');
			return [0, +toFixed(rightPrice.toFixed(), 7) ]
		}
	
		setMinPrice(toFixed(leftPrice.toString(), 7));
		setMaxPrice(toFixed(rightPrice.toString(), 7)); 

    return [parseFloat(toFixed(leftPrice.toFixed(), 6)), parseFloat(toFixed(rightPrice.toFixed(), 6))]
  }, [lowerTick, upperTick, currentPrice, currentPool, isFullRange])

	const onBrushDomainChangeEnded = useCallback(
    (domain: [number, number], mode: string | undefined, step?: number, isMin?: boolean) => {
			if (!currentPool) return 

			if (mode === 'change' && lowerTick && step && upperTick) {
				if (isMin)
					setLowerTick(lowerTick + step);
				else {
					setUpperTick(upperTick + step);
				}
				return
			}

			if (mode === 'fullRange') {
				const nearestTick = Math.floor(887272 / +currentPool.tick_spacing) * +currentPool.tick_spacing

				setLowerTick(-nearestTick);
				setUpperTick(nearestTick);

				return
			}

      let leftRangeValue = domain[0]
      const rightRangeValue = domain[1]

      if (leftRangeValue <= 0) {
        leftRangeValue = 0
      }

			const lowTick = sqrtPriceToTick((leftRangeValue.toFixed(6)));
			const uppTick = sqrtPriceToTick((rightRangeValue.toFixed(6)));

			const nearestLowerTick = Math.round(Math.round(lowTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;
			const nearestUpperTick = Math.round(Math.round(uppTick) / currentPool?.tick_spacing) * currentPool.tick_spacing;
			
			setLowerTick(nearestLowerTick);
			setUpperTick(nearestUpperTick);
    },
    [currentPool, lowerTick, upperTick],
  )

	const setNextTick = (isMin: boolean) => {
		onBrushDomainChangeEnded([0,0], 'change', currentPool?.tick_spacing, isMin)
	}

	const setPrevTick = (isMin: boolean) => {
		if (currentPool?.tick_spacing) {
			onBrushDomainChangeEnded([0,0], 'change', -currentPool?.tick_spacing, isMin)
		}
	}

	const getNearestPrice = () => {
		if (minPrice && maxPrice) {
			onBrushDomainChangeEnded([+minPrice, +maxPrice], '')
		}
	}

	const changeRange = useCallback((percent: number) => {
		let fullRange = false;
		if (currentPrice && currentPool) {
			if (percent === 1) {
				const leftPrice = 0;
				const rightPrice = tickToPrice(Math.floor(887272 / +currentPool.tick_spacing) * +currentPool.tick_spacing, false).toString();

				onBrushDomainChangeEnded([+leftPrice, +rightPrice], 'fullRange');
				fullRange = true;
			}
			else {
				onBrushDomainChangeEnded([+new BigNumber(currentPrice?.price).mul(new BigNumber(1).sub(new BigNumber(percent))).toFixed(), +new BigNumber(currentPrice?.price).mul(new BigNumber(1).add(new BigNumber(percent))).toFixed()], '')
			}

			setIsFullRange(fullRange);
		}
	}, [currentPool, currentPrice, onBrushDomainChangeEnded])

	const clearAll = () => {
		setAToken(undefined);
		setBToken(undefined);
		setCurrentPool(undefined);
		setMinPrice('0');
		setMaxPrice('0');
		setCurrentPrice(undefined);
		setATokenValue('');
		setBTokenValue('')
	}

	const changeQuote = (quoteTok1: boolean) => {
		if (!currentPool) return 

		if (quoteTok1) {
			const quotedPrice = sqrtPriceToPrice(currentPool.sqrt_price);
			setCurrentPrice({price: quotedPrice, tick: currentPool.tick});
			setIsQuoteToken1(true);
			
			onBrushDomainChangeEnded([+quotedPrice * 0.9, +quotedPrice* 1.1], '');
		}
		else {
			const quotedPrice = new BigNumber(1).div(new BigNumber(sqrtPriceToPrice(currentPool?.sqrt_price))).toFixed();
			const newTick = sqrtPriceToTick(quotedPrice);

			setCurrentPrice({price: quotedPrice, tick: newTick});
			setIsQuoteToken1(false);

			if (minPrice === undefined || maxPrice === undefined) return 

			const newMinPrice = new BigNumber(1).div(new BigNumber(minPrice)).toFixed();
			const newMaxPrice = new BigNumber(1).div(new BigNumber(maxPrice)).toFixed();

			onBrushDomainChangeEnded([Math.min(+newMinPrice, +newMaxPrice), Math.max(+newMinPrice, +newMaxPrice)], '');
		}
	}

	const tok0 = useMemo(() => {
		if (currentPool && AToken?.address === currentPool.token0) {
			return AToken
		}
		if (currentPool && BToken?.address === currentPool.token0) {
			return BToken
		}
		return null
	},[AToken, BToken, currentPool])

	const tok1 = useMemo(() => {
		if (currentPool && AToken?.address === currentPool.token1) {
			return AToken
		}
		if (currentPool && BToken?.address === currentPool.token1) {
			return BToken
		}
		return null
	},[AToken, BToken, currentPool])

	const handleFeeClick = (fee: tPoolFee) => {
		setIsFeeEditing(false);
		setCurrentFee(fee);
	}

	async function createPool() {
    if (!AToken || !BToken || !currentFee || !startPoolPrice) {
      throw new Error('no params for create pool')
    }

    const tickPrice = Math.floor(sqrtPriceToTick(startPoolPrice));
    try {
 
      setTransactionStatus('progress');
      openNewModal({
				name: 'transaction'
			}); 

      const tx = session?.call(op(
        'slurpyswap.CreatePool',
        Buffer.from(AToken?.address, 'hex'),
        Buffer.from(BToken?.address, 'hex'),
        (+currentFee / 100).toString(),
        +tickPrice
      ))

      await tx;
      setTransactionStatus('succes');

      getChrPools(); 
			getAllFeePools();
    }
    catch(error: any) {
			if (!error.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				throw new Error(error);
			}
      else {
				setTransactionStatus(null);
			}
    }
  }

	function validateNum(val: string) {
		return val.replace(/[^\d.]/ig, '')
	}


	return (
		<StarBackground>
			<MenuPages/>
			<div className="AddLiquidityPage">
				<WalletAddress/>
				<div className="stars_wrapper">
					<img src={starSmall4} className="starSmall1" alt="star"/>
					<img src={starSmall4} className="starSmall2" alt="star"/>
					<img src={starSmall4} className="starSmall3" alt="star"/>
					<img src={starBig5} className="starBig51" alt="star"/>
					<img src={starBig4} className="starBig41" alt="star"/>
					<img src={starBig5} className="starBig52" alt="star"/> 
					<img src={starBig5} className="starBig53" alt="star"/> 
					<img src={starBig5} className="starBig54" alt="star"/>
					<img src={starBig4} className="starBig55" alt="star"/>

					<img src={starSmall4} className="starSmall4" alt="star"/>
					<img src={starSmall4} className="starSmall5" alt="star"/>
					<img src={starSmall4} className="starSmall6" alt="star"/>
					<img src={starBig4} className="starBig42" alt="star"/>
					<img src={starBig4} className="starBig57" alt="star"/> 
					<img src={starSmall4} className="starBig58" alt="star"/> 
					<img src={starBig5} className="starBig59" alt="star"/>
					<img src={starBig4} className="starBig510" alt="star"/>
				</div>
				
				<div className="AddLiquidity">
					<PixelBorder>
						<div className="AddLiquidityInner">
							<div className="AddLiquidity_title">
								<div className="titleImg">
									<BackArrowIcon action={() => {
										setIsNewPosition(false);
										setSelectedNewPos(null);
									}}/>
									<span className="AddLiquidity_title_add">
										Add liquidity
									</span>
								</div>
								<div className="titleImg__clear">
									<span 
										className="AddLiquidity_title_clear"
										onClick={clearAll}
									>
										Clear all
									</span>
									<GearIcon/>
								</div>
							</div>

							<br/>

							<div className="AddLiquidity_tokenSelectors">
								Select pair
								<br/>
								<div className="AddLiquidity_tokenSelectors_selectors">
									<div>
									<TokenSelector
										setCurrency={curr => changeToken(curr, true)}
										token={AToken}
										tabIndex={tabIndex}
										flatBottom
									/>
									</div>
									<div>
									<TokenSelector
										setCurrency={curr => changeToken(curr, false)}
										token={BToken}
										tabIndex={tabIndex}
										flatBottom
									/>
									</div>
								</div>
							</div>

							<br/>
	
							<div className="feeBlock">
								<BlockPixelBorder
									bottomRightBorderColor="black"
									leftTopBorderColor="white"
									bottomBorderHeight="1px"
									rightBorderWidth="2px"
									topBorderHeight="1px"
									leftBorderWidth="2px"
								>
									{!isFeeEditing?
										<div className="AddLiquidity_feeTier-active">
											<div>
												<div>
													{currentFee ? `${currentFee}% fee tier` : 'Fee tier'}
												</div>
												{/* <div>97% select</div> */}
											</div>

											<div className="editFeeButton_wrapper">
												<Button
													small={true}
													light={true}
													action={() => setIsFeeEditing(!isFeeEditing)}
												>
													Edit
												</Button>
											</div>
										</div>
									:
										<div className="AddLiquidity_feeTier">
											<div className="AddLiquidity_feeTier_title">
												<div>Fee tier</div>
												<div className="hideButton_wrapper">
													<Button
														small={true}
														light={true}
														action={() => setIsFeeEditing(false)}
													>
														Hide
													</Button>
												</div>
											</div>
											<div className="AddLiquidity_feeTier_subtitle">The % you will earn in fees.</div>
											<br/>
											<div className="AddLiquidity_feeTier_fees">
												{allFeePools?.map(pool => 
													<div className="fee" key={pool.pool?.id}>
														<div 
															className="AddLiquidity_feeButton" 
															onClick={() => 
																handleFeeClick(pool.fee)
															}
														>
															{pool.fee}%
														</div>
														<div className="AddLiquidity_feeTier_fees_subtitle">
															{pool.pool?.id ? feeDesctiption[pool.fee as any] : 'Not created'}
														</div>
													</div>
												)}
											</div>
										</div>
									}
								</BlockPixelBorder>
							</div>

							{AToken && BToken && currentFee && !currentPool &&
								<div className="noPool">
									<div className="noPool__text">
											<BlockPixelBorder>
												<div className="text__content">
													This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool.
												</div>
											</BlockPixelBorder>
									</div>
									{/* <div>
										Current price:
									</div> */}

									<div className="noPool__price">
											<BlockPixelBorder>
												<div className="price__content">
													<label className="price__title">
														Set starting price
													</label>
													<Input
														type="number"
														value={startPoolPrice}
														setValue={setStartPoolPrice}
													/>
												</div>
											</BlockPixelBorder>
									</div>
									{startPoolPrice && +startPoolPrice > 0 && 
										<div className="LiquidityPageV2_initializePoolButton_wrapper">
											<Button
												className='LiquidityPageV2_grid_rightColumn_addButton'
												action={createPool}
												tabIndex={tabIndex}
											>
												Initialize pool
										</Button>
										</div>
									}
								</div>
							}


							<br/>
							<div className="AddLiquidity_priceRangeSetters">
								{currentPool &&
									<div className="AddLiquidity_priceRangeSetters_title">
										<div>Set price range</div>
										<div className="currency">
											<div 
												className={`tokBtn ${isQuoteToken1 ? 'tokBtn_active' : ''}`}
												onClick={() => changeQuote(true)}
											>
												{AToken?.address === currentPool?.token1 ? AToken?.name : BToken?.name}
											</div>
											<div 
												className={`tokBtn tok1Btn ${!isQuoteToken1 ? 'tokBtn_active' : ''}`}
												onClick={() => changeQuote(false)}
											>
												{AToken?.address === currentPool?.token0 ? AToken?.name : BToken?.name}
											</div>
										</div>
									</div>
								}
								<br/>
								<div className="AddLiquidity_priceRangeSetters_setters">
									<div className="PriceRangeSetter">
										<div className="PriceRangeSetter_row">
											<div>Low price</div>
											<div className="countIcons" onClick={() => setNextTick(true)}>
												<PlusIcon/>
											</div>
										</div>

										<input
											className='PriceRangeSetter_input'
											type='text'
											value={currentPool ? minPrice : 0}
											onInput={(e: any) => {
												setMinPrice(validateNum(e.target.value));
											}}
											onBlur={getNearestPrice}
										/>

										<div className="PriceRangeSetter_row">
												<div>
												{AToken && BToken && currentPool && `${isQuoteToken1 ? tok1?.name : tok0?.name} per ${isQuoteToken1 ? tok0?.name : tok1?.name}`}
												</div>
											<div className="countIcons" onClick={() => setPrevTick(true)}>
												<MinusIcon/>
											</div>
										</div>
									</div>
									<br/>
									<div className="PriceRangeSetter">
										<div className="PriceRangeSetter_row">
											<div>High price</div>
											<div className="countIcons" onClick={() => setNextTick(false)}>
												<PlusIcon/>
											</div>
										</div>

										<input
											className='PriceRangeSetter_input'
											type='text'
											value={currentPool ? maxPrice : 0}
											onInput={(e: any) => {
												setMaxPrice(validateNum(e.target.value));
											}}
											onBlur={getNearestPrice}
										/>

										<div className="PriceRangeSetter_row">
												<div>
													{AToken && BToken && `${isQuoteToken1 ? tok1?.name : tok0?.name} per ${isQuoteToken1 ? tok0?.name : tok1?.name}`}
												</div>
											<div className="countIcons" onClick={() => setPrevTick(false)}>
												<MinusIcon/>
											</div>
										</div>
									</div>
									{currentPool && 
										<div className="priceRangeSetters_buttons">
											<div 
												className="fullRange"
												onClick={() => changeRange(0.1)}
											>
												10%
											</div>
											<div 
												className="fullRange"
												onClick={() => changeRange(0.2)}
											>
												20%
											</div>
											<div 
												className="fullRange"
												onClick={() => changeRange(0.5)}
											>
												50%
											</div>
											<div 
												className="fullRange"
												onClick={() => changeRange(1)}
											>
												Full range 
											</div>
											{/* <div>[WETH | BNB]</div> */}
										</div>
									}
								</div>
							</div>

							{currentPrice &&
								<div className="AddLiquidity_currentPrice">
									<div>Current price:</div>
									<div className="value">{toFixed(currentPrice.price, 6)}</div>
									<div className="AddLiquidity_currentPrice_zoomRow">
										{isQuoteToken1 ? tok1?.name : tok0?.name} per {isQuoteToken1 ? tok0?.name : tok1?.name} 
										<div>

										</div>
									</div>
								</div>
							}

							<br/>
							<BlockPixelBorder
								topBorderHeight="5px"
								bottomBorderHeight="5px"
								leftTopBorderColor={lowerTick && upperTick ? "#323232" : "#37373780"}
								bottomRightBorderColor={lowerTick && upperTick ? "#ffff" : "#FFFFFF80"}
							>
								<div className="AddLiquidity_chart">
									{formattedData.length === 0 && <EmptyBoxIcon/>}
									<br/>
								
									{currentPrice ?
										<DexChart
											data={{ series: formattedData, current: +currentPrice.price }}
											dimensions={{ width: 400, height: 200 }}
											margins={{ top: 10, right: 2, bottom: 20, left: 0 }}
											styles={{
												area: {
													selection: 'white',
												},
												brush: {
													handle: {
														west: '#2076FF',
														east: 'white',
													},
												},
											}}
											interactive={Boolean(formattedData?.length)}
											brushLabels={brushLabelValue}
											brushDomain={brushDomain}
											onBrushDomainChange={onBrushDomainChangeEnded}
											zoomLevels={ZOOM_LEVELS[FeeAmount.LOW]}
										/>
										:
										<div className="loadingText">
											Your active liquidity positions will appear here.
										</div>
									}
								</div>
							</BlockPixelBorder>

							<br/>
							<div className="AddLiquidity_amountInputs">
							<div className="AddLiquidity_amountInputs_title">Deposit amounts</div>
							<BlockPixelBorder
								topBorderHeight="5px"
								bottomBorderHeight="5px"
								leftTopBorderColor={ATokenValue ? "#323232" : "#37373780"}
								bottomRightBorderColor={ATokenValue ? "#ffff" : "#FFFFFF80"}
							>
								<div className="AddLiquidity_amountInputs_input">
									{!isSingleDepo1 ?
										<Input
											value={ATokenValue}
											setValue={getAtokenLiq}
											type='number'
											placeholder='0.0'
											flatTop
											// digitsAfterDot={18}
										/>
										:
										<div className="LiquidityPageV2_grid_leftColumn_warning_singleDepo">
											The market price is outside your specified price range. Single-asset deposit only.
										</div>
									}
									<div className="rightBlock_tokBalance">
										{AToken &&
											<div className="token_wrapper">
												<BlockPixelBorder
													leftTopBorderColor="white"
													bottomRightBorderColor="black"
													bottomBorderHeight="1px"
													rightBorderWidth="3px"
												>
													<div className="token">
														<img src={getTokenIconURL(AToken.name) || ''} className='tokIcon' alt="tokIcon"/>{AToken.name}
													</div>
												</BlockPixelBorder>
											</div>
										}
										{ATokenBalance ?
											<div className="balance">
												Balance: {ATokenBalance && toFixed(ATokenBalance.valueStr, 3)}
											</div>
										: !AToken && 
											<div className="selectToken">
												Select a token
											</div>
										}
									</div>
								</div>
							</BlockPixelBorder>
							<BlockPixelBorder
								topBorderHeight="5px"
								bottomBorderHeight="5px"
								leftTopBorderColor={BTokenValue ? "#323232" : "#37373780"}
								bottomRightBorderColor={BTokenValue ? "#ffff" : "#FFFFFF80"}
							>
								<div className="AddLiquidity_amountInputs_input">
									{!isSingleDepo2 ?
										<Input
											value={BTokenValue}
											setValue={getBtokenLiq}
											type='number'
											placeholder='0.0'
											flatTop
											// digitsAfterDot={18}
										/>
										:
										<div className="LiquidityPageV2_grid_leftColumn_warning_singleDepo">
											The market price is outside your specified price range. Single-asset deposit only.
										</div>
									}
									<div className="rightBlock_tokBalance">
										{BToken &&
											<div className="token_wrapper">
												<BlockPixelBorder
													leftTopBorderColor="white"
													bottomRightBorderColor="black"
													bottomBorderHeight="1px"
													rightBorderWidth="3px"
												>
													<div className="token">
														<img src={getTokenIconURL(BToken.name) || ''} className='tokIcon' alt="tokIcon"/> {BToken.name} 
													</div>
												</BlockPixelBorder>
											</div>
										}
										{BTokenBalance ?
											<div className="balance">
												Balance: {BTokenBalance && toFixed(BTokenBalance.valueStr, 3)}
											</div>
										: !BToken && 
											<div className="selectToken">
												Select a token
											</div>
										}
									</div>
								</div>
							</BlockPixelBorder>
							</div>

							<br/>
							<div className="AddLiquidity_actionButton">
								<div className="LiquidityPageV2_addButton_wrapper">
									{!clientAddr ?
										<ConnectionBlock
											className='swapButton_wrapper_button'
											altText='Connect your wallet'
											tabIndex={tabIndex}
										/>
									:
										<Button
											className='LiquidityPageV2_grid_rightColumn_addButton'
											action={addLiquidity}
											blocked={(inputError && inputError?.length > 0) || invalidRange}
											tabIndex={tabIndex}
											>
											{inputError ?? 'Add liquidity'}
										</Button>
									}
								</div>
							</div>
						</div>

					</PixelBorder>
				</div>
			</div>
		</StarBackground>
	)
}

export default LiquidityPageV2;