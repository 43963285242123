import React, { useCallback, useEffect, useMemo, useState } from "react";
import { op } from "@chromia/ft4";

import { useTabIndex } from "../../../hooks/useTabIndex";
import { iAddLiqModal, iPoolPrice } from "../../../interfaces/interfaces";
import { useStoreDispatch, useStoreSelector } from "../../../store/store";
import { getConfirm, getTokenIconURL, logError, toFixed } from "../../../utils/helpers";
import { getChrPools, getGtxClient, updateChromiaBalances } from "../../../utils/Chromia/chromiaHelpers";

import './AddLiqModal.scss';

import Button from "../../UI/Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Input from "../../UI/Input/Input";
import { calcBurn, calcMint, calcSingleMint } from "../../../utils/Chromia/v3";
import { getPool } from "../../../utils/observers";
import { iChrPool } from "../../../interfaces/chromiaInterfaces";
import BigNumber from "bignumber.js";
import useTokenBalance from "../../../hooks/useTokenBalance";
import outOfRangeIcon from '../../../assets/images/OutOfRangeIcon.svg';
import inRangeIcon from '../../../assets/images/InRangeIcon.svg';
import backIcon from '../../../assets/images/back.svg';
import BlockPixelBorder from "../../UI/BlockPixelBorder/BlockPixelBorder";


const AddLiqModal: React.FC <iAddLiqModal> = ({name: modalName, modalId, sellToken, buyToken, position, setPositions, isFarming}) => {

	const { session, clientAddr, chrPools } = useStoreSelector(store => store.app);
	const { closeModal, setTransactionStatus } = useStoreDispatch();

	const [isClosing, setIsClosing] = useState(false);
	const tabIndex = useTabIndex({position: 'last', modalName: modalName});
	const { openNewModal } = useStoreDispatch();

  const [ATokenValue, setATokenValue] = useState('');
	const [BTokenValue, setBTokenValue] = useState('');
  const [currentPrice,setCurrentPrice] = useState<iPoolPrice>();
  const [liquidity, setLiquidity] = useState<string>();
  const [currentPool, setCurrentPool] = useState<iChrPool>();
	const [isQuoteToken1, setIsQuoteToken1] = useState<boolean>(true);

  const ATokenBalance = useTokenBalance(position.token1);
	const BTokenBalance = useTokenBalance(position.token0);

	const { isSingleDepo1, isSingleDepo2 } = useMemo(
		() => {
			const deposit0Disabled = Boolean( 
				currentPrice &&  currentPrice?.tick >= position.tick_upper,
			)
			const deposit1Disabled = Boolean(
				currentPrice &&  currentPrice?.tick <= position.tick_lower,
			)
			
			if (deposit0Disabled) {
					return { isSingleDepo1: true, isSingleDepo2: false }
			}
	
			if (deposit1Disabled) {
					return { isSingleDepo1: false, isSingleDepo2: true }
			}
			
			return { isSingleDepo1: false, isSingleDepo2: false }
		},
		[currentPrice, position],
	)

	const inputError: string | null = useMemo(() => {
		if (!clientAddr) {
			return 'Connect wallet';
		}

		if (!currentPool) {
			return 'Pool doesn`t exist';
		}

		if (
			ATokenBalance === undefined ||
			BTokenBalance === undefined 
		) {
			return 'Loading';
		}

		if (
			Number.isNaN(+ATokenValue) ||
			!Number.isFinite(+ATokenValue) ||
			(+ATokenValue <= 0 && !isSingleDepo1) ||
			Number.isNaN(+BTokenValue) ||
			!Number.isFinite(+BTokenValue) ||
			(+BTokenValue <= 0 && !isSingleDepo2)
		) {
			return 'Type amount';
		}

		const ATokenValueBN = new BigNumber(ATokenValue || 0);
		const BTokenValueBN = new BigNumber(BTokenValue || 0);

		if (ATokenValueBN.gt(ATokenBalance.valueStr)){
			return `Insufficient ${sellToken.name} balance`;
		}

		if (BTokenValueBN.gt(BTokenBalance.valueStr)){
			return `Insufficient ${buyToken.name} balance`;
		}
		if (!liquidity) {
			return 'Insufficient liquidity';
		}


		return null
	},
	[clientAddr, sellToken, ATokenBalance, ATokenValue, buyToken, BTokenBalance, BTokenValue, currentPool, isSingleDepo1, isSingleDepo2, liquidity]
	)

	const [receivedAmounts, setReceivedAmounts] = useState<{amount0: string, amount1: string}>();


	useEffect(() => {
		const tickInterval = setInterval(() => {
			getChrPools();
		}, 20000)
	
		return () => {
			clearInterval(tickInterval);
		}
	}, [])


  useEffect(() => {
    const posInTokens = calcBurn(
			+position.amount_locked_in_farm > 0 && isFarming ? position.amount_locked_in_farm : position.liquidity,
			position.tick_lower,
			position.selectedPool.sqrt_price,
			position.tick_upper
		);

    if (posInTokens.t0_out && posInTokens.t1_out) {
      setReceivedAmounts({
        amount0: posInTokens.t0_out.toFixed(),
        amount1: posInTokens.t1_out?.toFixed()
      })
    }
  }, [position])

  const getPoolData = useCallback(async () => {
    const pool = await getPool(position.token0, position.token1, +position.selectedPool.trading_fees);
  
    if (pool && pool.id) {
      setCurrentPool(pool);
  
      const price2 = (new BigNumber(pool.sqrt_price)).div((new BigNumber(2)).pow(96)).pow(2).toString();
      let currPrice = {price: price2.toString(), tick: pool.tick};
      setCurrentPrice(currPrice);

    }
  }, [position]) 

	useEffect(() => {
    getPoolData();
  }, [getPoolData, chrPools])

	useEffect(() => {
		if (isSingleDepo1) {
			getBtokenLiq(BTokenValue)
		}
		else if (isSingleDepo2) {
			getAtokenLiq(ATokenValue)
		}
		else {
			if (ATokenValue) {
				getAtokenLiq(ATokenValue)
			}
			else {
				getBtokenLiq(BTokenValue)
			} 
		}
	}, [currentPrice])

	const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])


	const addLiquidity = async () => {
		if (!session || !liquidity) {
			throw new Error();
		}

		try {
			if (+position.amount_locked_in_farm > 0 && position.farm && isFarming) {
				const now = Math.floor(Date.now() / 1000);
				let minTime = now + 60*60*24*10; //10 Days
				const balance = +position.farm?.balance - +position.farm?.pending_rewards;
	
				if (position.farm?.close_time !== 0) {
						minTime = Math.min(minTime, position.farm?.close_time);
				}
		
				if (now + +balance / +position.farm?.current_rate <= minTime) {
					openNewModal({
						name: 'info',
						message: 'Insufficient farm balance :('
					})
					return
				}

				await getConfirm({
					title: 'Confirm the action', 
					message: 'You need to confirm 2 transactions'
				});

				setTransactionStatus('progress');
				openNewModal({
					name: 'transaction'
				})

				const tx1 = session.call(op(
					'slurpyswap.Mint',
					position.pool,
					position.tick_lower, 
					position.tick_upper,
					liquidity
				))
				
				await tx1;
	
				const tx2 = session.call(op(
					'slurpyswap.AddFarmLiquidity',
					position.farm?.acc,
					position.tick_lower, 
					position.tick_upper,
					liquidity
				))
		
				await tx2;

				setTransactionStatus('succes');
			}
			else if (!isFarming) {
				const tx = session.call(op(
					'slurpyswap.Mint',
					position.pool,
					position.tick_lower, 
					position.tick_upper,
					liquidity
				))

				setTransactionStatus('progress');

				openNewModal({
					name: 'transaction'
				})
		
				await tx;

				setTransactionStatus('succes');
	
				if (process.env.REACT_APP_IS_REAL_TIME !== "true") {
					const curTime = await (await getGtxClient()).query("slurpyswap.GetTime", {});
					await (await getGtxClient()).sendTransaction({name: "slurpyswap.SetTime", args: [curTime + 1]})
				}
			}
		}
		catch(err: any) {
			if (!err.toString().includes('Unable to sign')) {  // User rejected the request
				setTransactionStatus('error');
				logError(err);
				throw new Error(err);
			}
			else {
				setTransactionStatus(null);
			}
		}

		updateChromiaBalances();
		getChrPools();	// updates balances
		closeLPTReturnModal();
		// openNewModal({
		// 	name: 'info',
		// 	message: `You successfully added liquidity: ${ATokenValue || 0} ${position.token0.name} and ${BTokenValue || 0} ${position.token1.name}`
		// })
		setPositions()
	}
	
	const closeLPTReturnModal = () => {
		setIsClosing(true);
		setTimeout(()=>{
			closeModal(modalName, modalId);
		}, 490);
	}

	const getAtokenLiq = useCallback(
		async (amount: string) => {
			if (!session || !sellToken || amount.length === 0 || !currentPool || !buyToken || !currentPrice || amount === undefined) return;


			if (isSingleDepo1 || isSingleDepo2) {
				let liq;

				if (currentPool.token0 === sellToken.address) {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, '0', BTokenValue,  position.tick_lower, position.tick_upper).toString();
					}
					else {
						liq = calcSingleMint(currentPool, amount, '0',  position.tick_lower, position.tick_upper).toString();
					}
				}
				else {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, amount, '0',  position.tick_lower, position.tick_upper).toString();
					}
					else {
						liq = calcSingleMint(currentPool, '0', amount,  position.tick_lower, position.tick_upper).toString();
					}
				}
				setLiquidity(liq)
			}
			
			setATokenValue(amount);
			
			if (amount.endsWith('.')) {
				amount = amount.slice(0, -1)
			}

			if ((currentPrice && (position.tick_lower > currentPrice?.tick || position.tick_upper < currentPrice?.tick)) || +amount === 0) 
				return
			
			try {
				const mint = calcMint(currentPool, sellToken?.address, amount,  position.tick_lower, position.tick_upper)
				
				setLiquidity(mint.liquidity);
				setBTokenValue(toFixed(mint.amount, 18));
			}
			catch (err: any) {
				logError(err);
				throw new Error(err);
			}	

		}, 
		[sellToken, buyToken, currentPool, currentPrice, position, session, isQuoteToken1],
	)

	const getBtokenLiq = useCallback(
		async (amount: string) => {
			if (!session || !sellToken || amount.length === 0 || !currentPool || !buyToken || !currentPrice || amount === undefined) return;
			

			setBTokenValue(amount);
		
			if (amount.endsWith('.')) {
				amount = amount.slice(0, -1)
			}

			if (isSingleDepo1 || isSingleDepo2) {
				let liq;
				if (currentPool.token0 === sellToken.address) {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, '0', amount, position.tick_lower, position.tick_upper).toString();
					}
					else {
						liq = calcSingleMint(currentPool, amount, '0', position.tick_lower, position.tick_upper).toString();
					}
				}
				else {
					if (isSingleDepo1) {
						liq = calcSingleMint(currentPool, amount, '0', position.tick_lower, position.tick_upper).toString();
					}
					else {
						liq = calcSingleMint(currentPool, '0', amount, position.tick_lower, position.tick_upper).toString();
					}
				}

				setLiquidity(liq);
			}

			try {
				const mint = calcMint(currentPool, buyToken?.address, amount,  position.tick_lower, position.tick_upper);
				
				setLiquidity(mint.liquidity);
				setATokenValue(mint.amount);
			}
			catch (err: any) {
				logError(err);
				throw new Error(err);
			}
	},
		[sellToken, buyToken, currentPool, currentPrice, position, session, isQuoteToken1],
	)

	const changeQuote = (quoteTok1: boolean) => {
    if (quoteTok1) {
			setIsQuoteToken1(true);
		}
		else {
			setIsQuoteToken1(false);
		}
	}


	return (
		<ModalWrapper
			className='AddLiqModal'
			isModalClosing={isClosing}
			closeListenerSettings={{name: modalName, modalId, callback: closeLPTReturnModal}}
		>
			<div className="AddLiqModal_header">
				<img src={backIcon} className="backIcon" alt="back" onClick={closeLPTReturnModal}/>
				<div className='title'>
					Add liquidity
				</div>
			</div>

			<div className='modalContent'>
				<div className="positionInfo">
					<div className="pair">
						<img src={getTokenIconURL(position.token1.name) || ''} className='tokIcon' alt="tok1Icon"/>
						<img src={getTokenIconURL(position.token0.name) || ''} className='tokIcon tokIcon2' alt="tok0Icon"/>
						<div className="tokensNames">
							{position.token1.name}/{position.token0.name}
						</div>
					</div>
					<div className={`status ${position.status === 'In range' ? 'inRange' : 'outOfRange'}`}>
						{position.status} <img src={position.status === 'In range' ? inRangeIcon : outOfRangeIcon} alt="statusIcon"/>
					</div>
				</div>

				<div className="liqBlock block">
					<BlockPixelBorder
						topBorderHeight="5px"
						bottomBorderHeight="5px"
						leftBorderWidth="3px"
						rightBorderWidth="3px"
					>
						<div className="liqBlockInner">
							<div className="valuesInTok">
								<div className="name">
									<img src={getTokenIconURL(position.token1.name) || ''} className='tokIcon' alt="tok1Icon"/> {position.token1.name} 
								</div>
								<div className="values">
									<div className="value">
										{receivedAmounts && +receivedAmounts.amount1> 0 
											? new BigNumber(receivedAmounts.amount1).gt(new BigNumber(0.00001))
												? toFixed(receivedAmounts.amount1, 4)
												: '<0.00001'
											: '0'
										}
									</div>
								</div>
							</div>

							<div className="valuesInTok">
								<div className="name">
									<img src={getTokenIconURL(position.token0.name) || ''} className='tokIcon' alt="tok0Icon"/> {position.token0.name} 
								</div>
								<div className="values">
									<div className="value">
										{receivedAmounts && +receivedAmounts.amount0 > 0 
											? new BigNumber(receivedAmounts.amount0).gt(new BigNumber(0.00001))
												? toFixed(receivedAmounts.amount0, 4)
												: '<0.00001'
											: '0'
										}
									</div>
								</div>
							</div>
							<div className="valuesInTok">
								<div className="name">
									Fee tier 
								</div>
								<div className="values">
									{position.selectedPool.trading_fees}%
								</div>
							</div>
						</div>
					</BlockPixelBorder>
        </div>
				<div className="rangeBlock_header">
					<div className="range_title">
						Selected range
					</div>
					<div className="currency">
						<div 
							className={`tokBtn ${isQuoteToken1 ? 'tokBtn_active' : ''}`}
							onClick={() => changeQuote(true)}
						>
							{position.token1.name}
						</div>
						<div 
							className={`tokBtn tok1Btn ${!isQuoteToken1 ? 'tokBtn_active' : ''}`}
							onClick={() => changeQuote(false)}
						>
							{position.token0.name}
						</div>
          </div>
				</div>

				<div className="pricesBlock">
					<div className="price block">
						<BlockPixelBorder
							topBorderHeight="5px"
							bottomBorderHeight="5px"
							leftBorderWidth="2.5px"
							rightBorderWidth="2.5px"
						>
							<div className="priceBlockInner">
								<div className="title">
									Min price
								</div>
								<div className="value">
									{isQuoteToken1 
										? toFixed(position.minPrice.toFixed(), 5)
										: toFixed(new BigNumber(1).div(position.maxPrice).toFixed(), 5)
									} 
								</div>
								<div>
									{position.token1.name} per {position.token0.name}
								</div>
								<div className="warning">
									Your position will be 100% {isQuoteToken1 ? position.token1.name : position.token0.name} at this price
								</div>
								<div className="priceInner">
								</div>
							</div>
						</BlockPixelBorder>
					</div>
				
					<div className="price block">
						<BlockPixelBorder
							topBorderHeight="5px"
							bottomBorderHeight="5px"
							leftBorderWidth="2.5px"
							rightBorderWidth="2.5px"
						>
							<div className="priceBlockInner">
								<div className="title">
									Max price
								</div>
								<div className="value">
								{position.tick_upper < 887200 
									? isQuoteToken1 ? toFixed(position.maxPrice.toFixed(), 5) : toFixed(new BigNumber(1).div(position.minPrice).toFixed(), 5)
									: '∞'
								}
								</div>
								<div className="pair">
									{position.token1.name} per {position.token0.name}
								</div>
								<div className="warning">
									Your position will be 100% {isQuoteToken1 ? position.token1.name : position.token0.name} at this price
								</div>
							</div>
						</BlockPixelBorder>
					</div>
				</div>

				<div className="currentPriceBlock block">
					<BlockPixelBorder
						topBorderHeight="5px"
						bottomBorderHeight="5px"
						leftBorderWidth="2.5px"
						rightBorderWidth="2.5px"
					>
						<div className="currentPriceBlockInner">
							<div className="title">
								Current price
							</div>
							<div className="value">
								{isQuoteToken1 ? toFixed(currentPrice?.price || '0', 5) : toFixed(new BigNumber(1).div(currentPrice?.price || '0').toFixed(), 5)}
							</div>
							<div className="pair">
								{position.token1.name} per {position.token0.name}
							</div>
						</div>
					</BlockPixelBorder>
				</div>

				<div>
					Add more liquidity
				</div>

				<div
					className='modalContent_InputArea'
				>
					{!isSingleDepo1 &&
						<BlockPixelBorder
							topBorderHeight="2px"
							bottomBorderHeight="2px"
						>
							<div className="tokenAmtInputs">
								<div className="amountInputs">
									<Input
										className='modalContent_InputArea_input'
										type='number'
										value={ATokenValue}
										setValue={getAtokenLiq}
									/>
									<div className="rightBlock_tokBalance">
											{sellToken &&
												<div className="token_wrapper">
													<BlockPixelBorder
														leftTopBorderColor="white"
														bottomRightBorderColor="black"
														bottomBorderHeight="1px"
														rightBorderWidth="3px"
													>
														<div className="token">
															<img src={getTokenIconURL(sellToken.name) || ''} className='tokIcon' alt="tokIcon"/>{sellToken.name}
														</div>
													</BlockPixelBorder>
												</div>
											}
											{!isMobile &&
												ATokenBalance ?
													<div className="balance">
														Balance: {ATokenBalance && toFixed(ATokenBalance.valueStr, 3)}
													</div>
												: !isMobile && !sellToken && 
													<div className="selectToken">
														Select a token
													</div>
											}
										</div>
								</div>
								{isMobile &&
										ATokenBalance ?
											<div className="balance">
												Balance: {ATokenBalance && toFixed(ATokenBalance.valueStr, 3)}
											</div>
										: isMobile && !sellToken && 
											<div className="selectToken">
												Select a token
											</div>
								}
							</div>
						</BlockPixelBorder> 
					}

					{!isSingleDepo2 && 
						<BlockPixelBorder
							topBorderHeight="2px"
							bottomBorderHeight="2px"
						>
							<div className="tokenAmtInputs">
								<div className="amountInputs">
									<Input
										className='modalContent_InputArea_input'
										type='number'
										value={BTokenValue}
										setValue={getBtokenLiq}
									/>
									<div className="rightBlock_tokBalance">
										{buyToken &&
											<div className="token_wrapper">
												<BlockPixelBorder
													leftTopBorderColor="white"
													bottomRightBorderColor="black"
													bottomBorderHeight="1px"
													rightBorderWidth="3px"
												>
													<div className="token">
														<img src={getTokenIconURL(buyToken.name) || ''} className='tokIcon' alt="tokIcon"/> {buyToken.name} 
													</div>
												</BlockPixelBorder>
											</div>
										}
										{!isMobile && 
											BTokenBalance ?
												<div className="balance">
													Balance: {BTokenBalance && toFixed(BTokenBalance.valueStr, 3)}
												</div>
											: !isMobile && !buyToken && 
												<div className="selectToken">
													Select a token
												</div>
										}
									</div>
								</div>
								{isMobile && 
										BTokenBalance ?
											<div className="balance">
												Balance: {BTokenBalance && toFixed(BTokenBalance.valueStr, 3)}
											</div>
										: isMobile && !buyToken && 
											<div className="selectToken">
												Select a token 
											</div>
								}
							</div>
						</BlockPixelBorder>
					}

					{(isSingleDepo1 || isSingleDepo2) &&
						<div className="warning_singleDepo">
							The market price is outside your specified price range. Single-asset deposit only.
						</div>
					}

				</div>
			</div>

			<div className='modalFooter'>
				<div className='addLiqButton_wrapper'>
					<Button
						action={addLiquidity}
						blocked={(inputError && inputError?.length > 0) || false}
						tabIndex={tabIndex}
						light
					>
						{inputError ?? 'Add liquidity'}
					</Button>
				</div>
			</div>
		</ModalWrapper>
	)
}

export default AddLiqModal;