import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import pageArrow from '../../assets/images/pageArrow.svg';
import MobileMenu from "../MobileMenu/MobileMenu";

import './MenuPages.scss';

const pages = ['swap', 'liquidity', 'liquidity-pdm', 'pools-info', 'my-liquidity', 'pool', 'farming'] as const;
type tPages = typeof pages[number];
interface iProps {
	className?: string,
	tabIndex?: number | boolean,
	closeMenuAction?: (...args: any) => any,
}


const MenuPages: React.FC <iProps> = ({className, tabIndex, closeMenuAction}) => {

	const [currentPage, setCurrentPage] = useState<tPages>();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
	const location = useLocation();

	useEffect(()=>{     // page observer
		for (let key of pages){
			if (location.pathname === '/'+key){
				setCurrentPage(key as tPages);
				return;
			}
		}
		setCurrentPage(undefined);
	}, [location])

	const isMobile = useMemo(() => {
		return window.innerWidth < 769
	}, [])


	if (isMobileMenuOpen) return <MobileMenu closeMenuAction={setIsMobileMenuOpen}/>
	else if (isMobile) return (
		<div
			className={
				'MenuPages__mobile' +
				(className? ` ${className}` : '')
			}
			onClick={() => {
				setIsMobileMenuOpen(!isMobileMenuOpen)
			}}
		>
			<div className="line"></div>
			<div className="line"></div>
			<div className="line"></div>
		</div>
	)

	return (
		<div
			className={
				'MenuPages' +
				(className? ` ${className}` : '')
			}
		>
			<div className="menuItem">
				{currentPage === 'swap' && <img src={pageArrow} alt="pageArrow" className="pageArrow"/>}
				<Link
					className={currentPage === 'swap'? 'MenuPages_activePage' : 'MenuPages_inactivePage'}
					to='/swap'
					onClick={closeMenuAction}
					tabIndex={(tabIndex && currentPage !== 'swap')? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				>
					Swap
				</Link>
			</div>
			<div className="menuItem">
				{currentPage === 'farming' && <img src={pageArrow} alt="pageArrow" className="pageArrow"/>}
				<Link
					className={currentPage === 'farming'? 'MenuPages_activePage' : 'MenuPages_inactivePage'}
					to='/farming'
					onClick={closeMenuAction}
					tabIndex={(tabIndex && currentPage !== 'farming')? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				>
					Farming
				</Link>
			</div>
			<div className="menuItem">
				{currentPage === 'pool' && <img src={pageArrow} alt="pageArrow" className="pageArrow"/>}
				<Link
					className={currentPage === 'pool'? 'MenuPages_activePage' : 'MenuPages_inactivePage'}
					to='/pool'
					onClick={closeMenuAction}
					tabIndex={(tabIndex && currentPage !== 'pool')? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				>
					Pool
				</Link>
			</div>
			{/* <div>
				<Link
					className={currentPage === 'farms'? 'MenuPages_activePage' : 'MenuPages_inactivePage'}
					to='/farms'
					onClick={closeMenuAction}
					tabIndex={(tabIndex && currentPage !== 'farms')? (typeof tabIndex === 'boolean'? 0 : tabIndex) : -1}
				>
					FARMS
				</Link>
			</div> */}
		</div>
	)
}

export default MenuPages;