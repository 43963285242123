import { FC } from "react";


const EmptyBoxIcon: FC = () => {

	return (
		<svg width="73" height="51" viewBox="0 0 73 51" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="5.43066" width="61.5538" height="2.55" fill="#4C4C4C"/>
			<rect y="10.2" width="5.43122" height="2.55" fill="#4C4C4C"/>
			<rect x="7.24121" y="10.2" width="34.3977" height="2.55" fill="#4C4C4C"/>
			<rect x="57.9331" y="10.2" width="14.4832" height="2.55" fill="#4C4C4C"/>
			<rect y="7.65002" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="41.6392" y="7.65002" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="70.6055" y="7.65002" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="56.1226" y="7.65002" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="1.81006" y="5.09998" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="43.4492" y="5.09998" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="68.7949" y="5.09998" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="54.3125" y="5.09998" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="3.62061" y="2.54999" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="45.2598" y="2.54999" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="66.9849" y="2.54999" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="52.502" y="2.54999" width="1.81041" height="2.55" fill="#4C4C4C"/>
			<rect x="5.43066" y="48.45" width="61.5538" height="2.55" fill="#4C4C4C"/>
			<rect x="5.43066" y="51" width="40.8" height="1.81041" transform="rotate(-90 5.43066 51)" fill="#4C4C4C"/>
			<rect x="48.8809" y="48.45" width="48.45" height="1.81041" transform="rotate(-90 48.8809 48.45)" fill="#4C4C4C"/>
			<rect x="65.1743" y="48.45" width="38.25" height="1.81041" transform="rotate(-90 65.1743 48.45)" fill="#4C4C4C"/>
		</svg>
	)
}

export default EmptyBoxIcon;