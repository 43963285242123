import React, { useEffect, useMemo, useState } from "react";

import './WalletAddress.scss';
import { useStoreDispatch, useStoreSelector } from "../../store/store";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import useTokenBalance from "../../hooks/useTokenBalance";
import { disconnectWallet, toFixed } from "../../utils/helpers";
import walletIcon from '../../assets/images/walletIcon.svg';
import { useWeb3Modal } from '@web3modal/wagmi/react'

const WalletAddress: React.FC = () => {
  const { address } = useAccount();
  const { tokens } = useStoreSelector(store => store.app);
  const token = tokens.find((token) => token.name.toLocaleLowerCase().includes('usdt'))
  const btcTokenBalance = useTokenBalance(token);

	const { error } = useConnect();
	const { disconnect } = useDisconnect();
	const { open } = useWeb3Modal();

	
	const { clientAddr } = useStoreSelector(store => store.app);
	const { setClientAddr, openNewModal } = useStoreDispatch();
	const [isHovered, setIsHovered] = useState(false);


	useEffect(() => {
		if (!error) {
			return;
		}
		
		if (error.message === "Connector not found") {
			window.open("https://metamask.io/download", "_blank");
		}
	}, [error]);
	
	useEffect(() => {
		if (address && !clientAddr){
			setClientAddr(address);
		}
		if (!address && clientAddr){
			disconnectWallet();
		}
		// eslint-disable-next-line
	}, [address, clientAddr])

	useEffect(()=>{
		if (!clientAddr){
			setIsHovered(false);
		}
	}, [clientAddr])

	const isMobile = useMemo(() => {
		return window.innerWidth < 769
}, [])

	if (!address) return <div
		onClick={() => open()}
		className="connectButton"
	>
		<img src={walletIcon} alt="walletIcon" className="walletIcon"/>
		Connect
	</div>

	return (
		<div className="wallet">
			<div 
				className="walletBlock"     	
				onMouseOver={() => setIsHovered(true)}
				onMouseOut={() => setIsHovered(false)}
				onFocus={() => setIsHovered(true)}
				onBlur={() => setIsHovered(false)}
			>
				<div className="balance">
					{btcTokenBalance && `${toFixed(btcTokenBalance?.valueStr, 4)} USDT`}
				</div>
				{address ?
					<div
						className="walletAddress"
						onClick={() => disconnect()}
					>
						<img src={walletIcon} alt="walletIcon" className="walletIcon"/>
						{isHovered 
							? 'Disconnect' 
							: isMobile 
								? address.slice(0,3) + '...' + address.slice(-2)
							 	: address.slice(0,6) + '...' + address.slice(-4)
						}
					</div>
				:
					<div
						onClick={() => openNewModal({name: 'connectors'})}
						className="connectButton"
					>
						<img src={walletIcon} alt="walletIcon" className="walletIcon"/>
							Connect
					</div> 
				}
			</div>
			<div className="chain">
				Testnet
			</div>
		</div>
	)
}

export default WalletAddress;