import { createClient } from "postchain-client";

import { BigNumber } from "../../entities/BigNumber";
import { Token } from "../../entities/Token";
import { TypedGtxClient } from "../../interfaces/chromiaInterfaces";
import { tTokenFeatures } from "../../interfaces/interfaces";
import { eAppActionTypes } from "../../store/app/appTypes";
import { store } from "../../store/store"

export const getGtxClient = async (): Promise<TypedGtxClient> => {
	const { gtxClient } = store.getState().app;
	if (gtxClient) return gtxClient;

	// const rid = await (await fetch(`${chromiaBlockchainUrl}brid/iid_0`)).text();

	const directoryNodeUrlPool = [
		"https://node0.questnet.chromia.dev:7740",
		"https://node1.questnet.chromia.dev:7740",
		"https://node2.questnet.chromia.dev:7740",
		"https://node3.questnet.chromia.dev:7740"
		// "https://node7.devnet1.chromia.dev:7740",
	];

	const newGtxClient = (await createClient({
		directoryNodeUrlPool,
		blockchainRid: process.env.REACT_APP_CHROMIA_BLOCKCHAIN_RID,
	})) as TypedGtxClient;
	// const newGtxClient = (await createClient({
	// 	nodeUrlPool: chromiaBlockchainUrl,
	// 	blockchainRid: chromiaBlockchainRID,
	// })) as TypedGtxClient;
	
	store.dispatch({
		type: eAppActionTypes.SET_GTX_CLIENT,
		payload: newGtxClient
	})

	return newGtxClient;
}

export function getChromiaToken(address: string, decimals: number, name: string, symbol?: string, features?: tTokenFeatures[]): Token {
    const { tokens } = store.getState().app;

	const foundToken = tokens.find(token => token.address.toLocaleLowerCase() === address.toLocaleLowerCase());
	if (foundToken) return foundToken;
	// const configToken = getConfigToken({type: 'token', address: params.address});
	const newToken = new Token(1, address, decimals, name, symbol, features);
	store.dispatch({
		type: eAppActionTypes.ADD_TOKEN,
		payload: newToken
	})
	return newToken;
}

export const updateChromiaBalances = async () => {
	const { chrClientAddr, tokens } = store.getState().app;
	if (!chrClientAddr) {
		throw new Error('missing chrClientAddr')
	}
	/* tokens.forEach(token => {
		getBalance({token: token}, {update: true});
		getAllowance({token: token, type: 'token'}, {update: true});
	}); */
	const res = await (await getGtxClient()).query("ft4.get_asset_balances", {
		account_id: chrClientAddr,
		page_size: 10,
		page_cursor: null
	})

	tokens.forEach( token => {
		let found_balance = res.data.find(balance => balance.asset.id.toString("hex").toLocaleLowerCase() === token.address.toLocaleLowerCase())
		let BN = new BigNumber(
			found_balance?.amount.toString() || 0,
			{
				decimals: token.decimals,
				currentDecimals: found_balance?.asset.decimals
			}
		)
		store.dispatch({
			type: eAppActionTypes.SET_TOKEN_BALANCE,
			payload: {
				address: token.address,
				balance: BN
			}
		})
	})
}

export const getChrPools = async () => {
	const gtxClient = await getGtxClient();
	const pools = await gtxClient.query("slurpyswap.GetPools", { page_size: 100, page_cursor: null })

	store.dispatch({
		type: eAppActionTypes.SET_CHR_POOLS,
		payload: pools.data.map( pool => { 
			return {
				...pool,
				trading_fees: pool.trading_fees,
				id: pool.id.toString('hex'),
				token0: pool.token0.toString('hex'),
				token1: pool.token1.toString('hex')
			}
		})
	})

	pools.data.forEach( async pool => {
		const bals = await gtxClient.query("slurpyswap.GetPoolBalances", { pool_id: pool.id });

		store.dispatch({
			type: eAppActionTypes.SET_CHR_POOL_BALANCE,
			payload: {
				address: pool.id.toString('hex'),
				balance: {
					token0Amount: new BigNumber(bals.t0_amt_dec),
					token1Amount: new BigNumber(bals.t1_amt_dec),
					totalAmount: new BigNumber(0)
				}
			}
			
		})
	})
}
